import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'

import { AssetTypeListingItemDto } from '@lib/pgs'
import { fieldsToInitialise, ReferenceDataRuleFormData } from '@lib/pgs-types'
import { ButtonLink } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  GridEnrichedColDef,
} from '@tk/frontend/primitives/datagrid'
import { NameDateCell } from '@tk/frontend/primitives/datagrid/cells'
import { setColumnDefaults } from '@tk/frontend/primitives/datagrid/columns'

const fieldNameToLabel = (fieldName: string) =>
  fieldName
    .replace(/Mode$/, '')
    .replace(/^[a-z]/, (val) => val.toUpperCase())
    .replace(/([a-z])([A-Z0-9])/g, '$1 $2')

export const useColumns = () => {
  const cols = useMemo<GridEnrichedColDef<ReferenceDataRuleFormData>[]>(() => {
    const columns: GridEnrichedColDef<ReferenceDataRuleFormData>[] = [
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
      },
      {
        type: 'string',
        field: 'clearingTypeMode',
        headerName: 'Required Fields',
        flex: 3,
        valueGetter: (val, row) => {
          const requiredFields = fieldsToInitialise.reduce((acc, field) => {
            if (row[field] === 'REQUIRED') {
              acc.push(fieldNameToLabel(field))
            }
            return acc
          }, [] as string[])

          return requiredFields.sort((a, b) => a.localeCompare(b)).join(', ')
        },
      },
      {
        type: 'number',
        field: 'assetTypes',
        headerName: 'Related Asset Types',
        width: 130,
        valueGetter: (val: AssetTypeListingItemDto[]) => val?.length ?? 0,
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell: ({ row }) => {
          const { createdBy, createdDate } = row
          return <NameDateCell name={createdBy} date={createdDate} />
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell: ({ row }) => {
          const { modifiedBy, modifiedDate } = row
          return <NameDateCell name={modifiedBy} date={modifiedDate} />
        },
      },
      {
        type: 'actions',
        field: 'actions',
        headerName: '',
        width: 85,
        align: 'right',
        sortable: false,
        getActions: ({ row }) => [
          <ActionButtonGroup key="actions">
            <ButtonLink
              variant="text"
              startIcon={<Icons.Edit />}
              to="/reference-data/rule/edit/$id"
              params={{ id: row.id }}
              color="primary"
            >
              Edit
            </ButtonLink>
          </ActionButtonGroup>,
        ],
      },
    ]
    return columns.map((col) => {
      col.filterable ??= true
      col.sortable ??= true
      setColumnDefaults(col)
      return col
    })
  }, [])

  return cols
}
