import React from 'react'

import * as Icons from '@mui/icons-material'
import { Toolbar } from '@mui/material'

import { AppHeader, When } from '@tk/frontend/primitives'
import { Layout, NavButton, NavSection } from '@tk/frontend/primitives/layout'

import { BloombergSidebar } from './Bloomberg/Sidebar'
import { ReferenceDataSidebar } from './crud-simple/Sidebar'
import { ExitAssumedRole } from './Roles'

export const AuthedLayout = () => {
  return <Layout decoration={<ExitAssumedRole />} sidebar={<SideBar />} />
}

const SideBar = React.memo(SideBar_)
function SideBar_() {
  return (
    <>
      <Toolbar>
        <AppHeader />
      </Toolbar>

      <When flag="unimplemented">
        <NavButton label="Home" path="/" icon={<Icons.HomeRounded />} />
      </When>

      <NavSection title="Record Management">
        <When can="records.read">
          <NavButton
            label="Records"
            path="/record-management/records"
            icon={<Icons.TableRowsRounded />}
          />
        </When>

        <When can="commercial-packages.read">
          <NavButton
            label="Commercial Packages"
            path="/record-management/commercial-packages"
            icon={<Icons.RequestQuote />}
          />
        </When>

        <When can="reference-data-rules.read">
          <NavButton
            label="Reference Data Rules"
            path="/reference-data/rule"
            icon={<Icons.Rule />}
          />
        </When>
      </NavSection>

      <NavSection title="Fields">
        <NavButton
          label="Field Groups"
          path="/fids/groups"
          icon={<Icons.AccountTreeRounded />}
        />

        <NavButton
          label="FIDs"
          path="/fids/list"
          icon={<Icons.LabelRounded />}
        />
      </NavSection>

      <BloombergSidebar />
      <ReferenceDataSidebar />

      <When can="record-projects.read">
        <NavSection title="Multibrand Reference Data">
          <NavButton
            label="Market Groups & Instruments"
            path="/multibrand/stubs"
            icon={<Icons.AccountTreeRounded />}
          />
          <NavButton
            label="Root Record Name Structures"
            path="/multibrand/root-record-name-structure"
            icon={<Icons.LabelRounded />}
          />
          <NavButton
            label="Attributes"
            path="/multibrand/attribute"
            icon={<Icons.LabelRounded />}
          />
        </NavSection>
      </When>

      <When flag="entitlements" can="entitlements.read">
        <NavSection title="Entitlements">
          <NavButton
            label="Actor"
            path="/entitlements/actor"
            icon={<Icons.LabelRounded />}
          />
          <NavButton
            label="Distribution"
            path="/entitlements/distribution"
            icon={<Icons.LabelRounded />}
          />
          <NavButton
            label="Ruleset"
            path="/entitlements/ruleset"
            icon={<Icons.LabelRounded />}
          />
        </NavSection>
      </When>

      <When can="super">
        <NavSection title="Admin">
          <NavButton
            label="Roles"
            path="/admin/roles"
            icon={<Icons.Shield />}
          />
          <NavButton
            label="User Roles"
            path="/admin/user-roles"
            icon={<Icons.AddModerator />}
          />
          <NavButton
            label="Api Users"
            path="/admin/api-users"
            icon={<Icons.Shield />}
          />
        </NavSection>
      </When>
    </>
  )
}
