import { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Add } from '@mui/icons-material'
import {
  Button,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import { useEntitlement } from '@tk/frontend/primitives'
import { getFormComponents } from '@tk/frontend/primitives/forms'
import {
  Option,
  SelectFieldProps,
} from '@tk/frontend/primitives/forms/SelectField'
import { MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS } from '@tk/frontend/primitives/MultibrandAttributeType'

import { RrnStructureRequestDto, StatusSelection } from './types'
import { useRrnAttributeChangeHandler } from './useRrnAttributeChangeHandler'
import { useStubOptions } from './useStubOptions'

type FormData = RrnStructureRequestDto & { status: StatusSelection }

const Form = getFormComponents<FormData>()

const statusOptions: Option<StatusSelection>[] = [
  { label: 'Active', value: 'active' },
  { label: 'Retired', value: 'retired' },
]

export function RrnStructureForm() {
  const form = useFormContext<FormData>()
  const marketInstruments = useFieldArray({
    control: form.control,
    name: 'marketInstruments',
    keyName: 'formId',
  })
  const name = form.watch('name')
  const canEdit = useEntitlement('reference-data.manage')

  const stubOptions = useStubOptions()

  const addMarketInstrument = useCallback(() => {
    marketInstruments.append({
      id: -1,
    })
  }, [marketInstruments])

  return (
    <Form.SectionBodyGrid>
      <Form.FormConfigProvider editable={canEdit}>
        <Form.Section title="Details" gridColumns={8}>
          <input {...form.register('name')} hidden />
          <div>
            <FormLabel>Name</FormLabel>
            <Typography sx={{ height: '1rem' }} id="rrn-structure-name">
              {name}
            </Typography>
          </div>

          <Form.Field name="description" label="Description" />
          <Form.Field
            name="status"
            label="Status"
            input={
              <Form.SelectField
                rules={Form.rules.required}
                options={statusOptions}
              />
            }
          />
        </Form.Section>

        <Form.Section title="Structure (Required)" gridColumns={12}>
          <Form.Row>
            <Form.Field
              name="attribute1"
              input={<AttributeSelector required />}
            />
            <Form.Field
              name="attribute2"
              disabledSuffixElement={<></>}
              disabled={!form.watch('attribute1')}
              input={<AttributeSelector />}
            />
            <Form.Field
              name="attribute3"
              disabledSuffixElement={<></>}
              disabled={!form.watch('attribute2')}
              input={<AttributeSelector />}
            />
            <Form.Field
              name="attribute4"
              disabledSuffixElement={<></>}
              disabled={!form.watch('attribute3')}
              input={<AttributeSelector />}
            />
            <Form.Field
              name="attribute5"
              disabledSuffixElement={<></>}
              disabled={!form.watch('attribute4')}
              input={<AttributeSelector />}
            />
            <Form.Field
              name="attribute6"
              disabledSuffixElement={<></>}
              disabled={!form.watch('attribute5')}
              input={<AttributeSelector />}
            />
            <Form.Field
              name="attribute7"
              disabledSuffixElement={<></>}
              disabled={!form.watch('attribute6')}
              input={<AttributeSelector />}
            />
            <Form.Field
              name="attribute8"
              disabledSuffixElement={<></>}
              disabled={!form.watch('attribute7')}
              input={<AttributeSelector />}
            />
          </Form.Row>
        </Form.Section>

        <Form.Section title="Stubs" gridColumns={12}>
          <Table size="small">
            <TableBody>
              {marketInstruments.fields.map(({ formId }, index) => (
                <TableRow key={formId}>
                  <TableCell>
                    <Form.Field
                      name={`marketInstruments.${index}.id`}
                      input={<Form.SelectField options={stubOptions} />}
                    />
                  </TableCell>
                  <TableCell sx={{ width: '4rem' }}>
                    <Button
                      variant="text"
                      color="warning"
                      onClick={() => marketInstruments.remove(index)}
                      disabled={!canEdit}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            startIcon={<Add />}
            onClick={addMarketInstrument}
            disabled={!canEdit}
          >
            Add
          </Button>
        </Form.Section>
      </Form.FormConfigProvider>
    </Form.SectionBodyGrid>
  )
}

interface AttributeSelectorProps extends Partial<SelectFieldProps<FormData>> {
  required?: boolean
}

function AttributeSelector({
  required,
  ...props
}: Readonly<AttributeSelectorProps>) {
  const onValueChange = useRrnAttributeChangeHandler()

  return (
    <Form.SelectField
      {...props}
      options={MULTIBRAND_ATTRIBUTE_TYPE_OPTIONS}
      rules={required ? Form.rules.required : undefined}
      onValueChange={(value) => onValueChange(props.name, value)}
    />
  )
}
