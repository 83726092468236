import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'

const searchParams = z.object({
  'grid-filters': z.string().optional(),
  'filter': z.string().optional(),
  'searchField': z
    .union([z.literal('filter'), z.literal('filterDescription')])
    .optional(),
})

export const Route = createFileRoute('/extracta/create/')({
  validateSearch: searchParams.parse,
})
