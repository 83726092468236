import {
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  HourglassBottomOutlined,
  InfoOutlined,
} from '@mui/icons-material'
import { ChipProps } from '@mui/material'
import { DateTime } from 'luxon'

import { Inputs, Outputs } from '@tk/frontend/api'
import { getFormComponents } from '@tk/frontend/primitives/forms'
import { theme } from '@tk/frontend/theme'

export type CreateExtractaMutationInputs = Inputs['extracta']['create']
export type CreateExtractaFormValues = Omit<
  CreateExtractaMutationInputs,
  'startDate' | 'endDate' | 'snapTime'
> & {
  dateRange: [DateTime | null, DateTime | null] | null
  snapTime?: DateTime | null
  startTime?: DateTime | null
  endTime?: DateTime | null
  excludedDays?: Array<number>
}

export const Form = getFormComponents<CreateExtractaFormValues>()

export type Fids = Outputs['extracta']['getExtractableRecordsAndFids']['fids']

export type ReportStatus = Outputs['extracta']['getReportById']['status']
export const InfoByReportStatus: Record<
  ReportStatus,
  {
    label: string
    colour: string
    muiColor: ChipProps['color']
    Icon: typeof CheckCircleOutlineRounded
  }
> = {
  // Happy Path
  SUBMITTED: {
    label: 'Submitted',
    colour: theme.palette.grey['500'],
    muiColor: 'secondary',
    Icon: InfoOutlined,
  },
  IN_PROGRESS: {
    label: 'In Progress',
    colour: theme.palette.primary.main,
    muiColor: 'primary',
    Icon: HourglassBottomOutlined,
  },
  MERGING_FILES: {
    label: 'Merging Files',
    colour: theme.palette.primary.main,
    muiColor: 'primary',
    Icon: HourglassBottomOutlined,
  },
  QUERY_FINISHED: {
    label: 'Processing',
    colour: theme.palette.success.main,
    muiColor: 'success',
    Icon: HourglassBottomOutlined,
  },
  FINISHED: {
    label: 'Success',
    colour: theme.palette.success.main,
    muiColor: 'success',
    Icon: CheckCircleOutlineRounded,
  },

  // Errors
  BAD_REQUEST: {
    label: 'Report Invalid',
    colour: theme.palette.error.main,
    muiColor: 'error',
    Icon: ErrorOutlineRounded,
  },
  WORKER_ERROR: {
    label: 'System Error',
    colour: theme.palette.error.main,
    muiColor: 'error',
    Icon: ErrorOutlineRounded,
  },
  WORKER_SHUT_DOWN: {
    label: 'System Error',
    colour: theme.palette.error.main,
    muiColor: 'error',
    Icon: ErrorOutlineRounded,
  },
  TERMINATED: {
    label: 'System Error',
    colour: theme.palette.error.main,
    muiColor: 'error',
    Icon: ErrorOutlineRounded,
  },
  MARKED_FOR_TERMINATION: {
    label: 'System Error',
    colour: theme.palette.error.main,
    muiColor: 'error',
    Icon: ErrorOutlineRounded,
  },
  ERROR: {
    label: 'System Error',
    colour: theme.palette.error.main,
    muiColor: 'error',
    Icon: ErrorOutlineRounded,
  },
}
