import React, { useCallback } from 'react'

import { Box, InputAdornment, Paper, Stack, TextField } from '@mui/material'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import * as immer from 'immer'

import { trpc, useUtils } from '@tk/frontend/api'
import {
  defaultProps,
  useEditingProps,
  useFilterModel,
  useSortModel,
} from '@tk/frontend/primitives/datagrid'
import {
  TreeDataGridStyles,
  useTreeGroupingProps,
} from '@tk/frontend/primitives/datagrid/tree'
import { PageContentLayout } from '@tk/frontend/primitives/layout'
import { useSimpleImportProvider } from '@tk/frontend/primitives/simple-import'

import { RecordMappingRetiredFilter } from './RecordMappingFilter'
import { RecordMappingTableFilterRow } from './RecordMappingFilterRow'
import { RecordMappingsImportExportMenu } from './RecordMappingsImportExportMenu'
import { TreeItem } from './types'
import { useColumns } from './useColumns'
import {
  SortBy,
  useRecordMappingQueryParams,
} from './useRecordMappingQueryParams'
import { useRecordMappingsList } from './useRecordMappingsList'
import { useRecordMappingUpsert } from './useRecordMappingUpsert'

const ARRAY: any[] = []
export function RecordMappingsCrud() {
  const params = useRecordMappingQueryParams()

  const listQuery = useRecordMappingsList()
  const rows = listQuery.data?.content ?? ARRAY
  const sort = useSortModel<SortBy>('name', 'asc')

  const { rowModes, setEditMode, setViewMode, columns } = useColumns()

  const gridApiRef = useGridApiRef()

  const filterModel = useFilterModel()
  const utils = useUtils()

  const simpleImportProvider = useSimpleImportProvider({
    route: trpc.bloomberg.mapping.import,
    utils: utils.bloomberg.mapping.import,
    previewUriTemplate: '/bloomberg/records-mappings/import/$taskId',
  })

  const groupingProps = useTreeGroupingProps<TreeItem>(gridApiRef, {
    groupingColOpts: {
      width: 100,
    },
    getRowId: useCallback((row) => {
      return `${row._type}/${row.id}/${row.recordId}`
    }, []),
    getTreeDataPath: useCallback((row) => {
      if (row._type === 'record') {
        return [String(row.recordId)]
      } else {
        return [String(row.recordId), String(row.id)]
      }
    }, []),
  })

  const recordMappingUpsertor = useRecordMappingUpsert()

  const editingProps = useEditingProps<TreeItem>({
    rowModes,
    setEditMode,
    setViewMode,
    fieldToFocus: 'ticker',
    async onUpdateNeeded(row, old) {
      if (row._type === 'record' || old._type === 'record') {
        // Editing of Record items should be impossible
        return old
      }

      const recordMapping = immer.produce(old.recordMapping, (draft) => {
        if (!draft.bloombergMappings) {
          return
        }

        const bloombergMappingIndex = draft.bloombergMappings.findIndex(
          (mapping) => mapping.id === row.id
        )

        draft.bloombergMappings[bloombergMappingIndex] = { ...row }
      })

      await recordMappingUpsertor.mutateAsync(recordMapping)

      return row
    },
  })

  return (
    <PageContentLayout
      title="Bloomberg: Record Mappings"
      childrenContainer={<React.Fragment />}
      controls={
        <simpleImportProvider.Provider {...simpleImportProvider.providerProps}>
          <RecordMappingsImportExportMenu />
        </simpleImportProvider.Provider>
      }
      maxContentWidth="100%"
    >
      <Paper sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center">
          <Box display="flex" flex="1 1 auto" flexDirection="row">
            <TextField
              label="Search (Record Name)"
              placeholder="You may use the % wildcard to use partial search terms, for instance: AUD%"
              variant="filled"
              onChange={(e) => params.setFilter(e.target.value)}
              value={params.filter ?? ''}
              fullWidth
              inputProps={{
                role: 'search',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RecordMappingRetiredFilter />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
          </Box>
        </Stack>
      </Paper>

      <RecordMappingTableFilterRow gridApiRef={gridApiRef} />

      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flex: '1 1 0',
          overflow: 'hidden',
        }}
      >
        <Stack flex="1 1 0" minHeight={0} minWidth={0}>
          <TreeDataGridStyles>
            <DataGridPremium
              {...defaultProps}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
                density: 'compact',
              }}
              //
              loading={
                listQuery.isFetching &&
                listQuery.data?.page !== params.pagination.page
              }
              rows={rows}
              columns={columns}
              //
              pagination
              paginationMode="server"
              paginationModel={params.pagination}
              onPaginationModelChange={params.onPaginationChange}
              rowCount={listQuery.data?.totalElements ?? 0}
              pageSizeOptions={params.sizeOptions}
              //
              sortingMode="server"
              sortModel={sort.model}
              onSortModelChange={sort.setModel}
              //
              filterMode="server"
              filterModel={filterModel.model}
              onFilterModelChange={filterModel.setModel}
              slotProps={filterModel.slotProps}
              //
              apiRef={gridApiRef}
              //
              {...editingProps}
              {...groupingProps}
            />
          </TreeDataGridStyles>
        </Stack>
      </Paper>
    </PageContentLayout>
  )
}
