import { Box, Typography } from '@mui/material'

import { getFormComponents } from '@tk/frontend/primitives'
import { STRIKE_OPTIONS } from '@tk/frontend/primitives/presentation-utils'

import { RECORD_SCOPE_OPTIONS } from './constants'
import { ReferenceDataField } from './ReferenceDataField'
import { ReferenceDataFormRow } from './ReferenceDataFormRow'
import { RecordEditDto } from './types'
import { useRecordReferenceLists } from './useRecordReferenceLists'

const Form = getFormComponents<RecordEditDto>()

export function RecordEditFieldsLinked() {
  const [lists] = RecordEditFieldsLinked.useRecordReferenceLists()

  return (
    <>
      <Form.Section title="Brand Details">
        <Form.Field
          label="Scope (Saleability) (Required)"
          name="scope"
          info={
            <Form.FieldInfo
              title="Controls how this record can be sold/distributed"
              options={RECORD_SCOPE_OPTIONS}
            />
          }
          input={
            <Form.SelectField
              rules={Form.rules.required}
              options={RECORD_SCOPE_OPTIONS}
            />
          }
        />

        <ReferenceDataField
          name="company"
          label="Company / Brand"
          options={lists.company}
        />
      </Form.Section>

      <Form.Section title="Asset Details">
        <ReferenceDataField
          name="clearingType"
          label="Clearing Type"
          options={lists.clearingType}
        />

        <ReferenceDataFormRow fields={['currency1', 'currency2']}>
          <ReferenceDataField
            name="currency1"
            label="Currency 1"
            options={lists.currency}
            rules={{ deps: 'currency2.id' }}
          />
          <ReferenceDataField
            name="currency2"
            label="Currency 2"
            options={lists.currency}
            rules={{
              validate: (value, formValue) =>
                !formValue.currency1?.id && value
                  ? 'Currency 2 cannot be filled if Currency 1 is empty '
                  : true,
            }}
          />
        </ReferenceDataFormRow>

        <ReferenceDataFormRow fields={['period1', 'period2']}>
          <ReferenceDataField
            name="period1"
            label="Period 1"
            options={lists.period}
            rules={{
              deps: ['period2.id', 'period3.id', 'period4.id'],
            }}
          />
          <ReferenceDataField
            name="period2"
            label="Period 2"
            options={lists.period}
            rules={{
              deps: ['period3.id', 'period4.id'],
              validate: (value, formValue) =>
                !formValue.period1?.id && value
                  ? 'Period 2 cannot be filled if Period 1 is empty '
                  : true,
            }}
          />
        </ReferenceDataFormRow>

        <ReferenceDataFormRow fields={['period3', 'period4']}>
          <ReferenceDataField
            label="Period 3"
            name="period3"
            options={lists.period}
            rules={{
              deps: 'period4.id',
              validate: (value, formValue) =>
                (!formValue.period1?.id ||
                  !formValue.period2?.id ||
                  !formValue.period3?.id) &&
                value
                  ? 'Period 4 cannot be filled if Period 1 or Period 2 or Period 3 is empty'
                  : true,
            }}
          />
          <ReferenceDataField
            label="Period 4"
            name="period4"
            options={lists.period}
            rules={{
              validate: (value, formValue) =>
                (!formValue.period1?.id ||
                  !formValue.period2?.id ||
                  !formValue.period3?.id) &&
                value
                  ? 'Period 4 cannot be filled if Period 1 or Period 2 or Period 3 is empty'
                  : true,
            }}
          />
        </ReferenceDataFormRow>

        <ReferenceDataField
          name="priceType"
          label="Price Type"
          options={lists.priceType}
        />

        <ReferenceDataField
          name="pricingFrequency"
          label="Pricing Frequency"
          options={lists.pricingFrequency}
        />

        <ReferenceDataField
          name="location"
          label="Location"
          options={lists.location}
        />
      </Form.Section>

      <Form.Section title="Leg Details">
        <ReferenceDataFormRow
          fields={['dayCount1', 'dayCount2']}
          displayEmpty={
            <Box display="flex" justifyContent="center">
              <Typography fontWeight="bold">No Leg Details Required</Typography>
            </Box>
          }
        >
          <ReferenceDataField
            name="dayCount1"
            label="Day Count 1"
            options={lists.dayCount}
            rules={{
              deps: 'dayCount2.id',
            }}
          />
          <ReferenceDataField
            name="dayCount2"
            label="Day Count 2"
            options={lists.dayCount}
            rules={{
              validate: (value, formValue) =>
                !formValue.dayCount1?.id && value
                  ? 'Day Count 2 cannot be filled if Day Count 1 is empty '
                  : true,
            }}
          />
        </ReferenceDataFormRow>

        <ReferenceDataFormRow
          fields={['paymentFrequency1', 'paymentFrequency2']}
        >
          <ReferenceDataField
            name="paymentFrequency1"
            label="Payment Frequency 1"
            options={lists.frequency}
            rules={{
              deps: 'paymentFrequency2.id',
            }}
          />

          <ReferenceDataField
            name="paymentFrequency2"
            label="Payment Frequency 2"
            options={lists.frequency}
            rules={{
              validate: (value, formValue) =>
                !formValue.paymentFrequency1?.id && value
                  ? 'Payment Frequency 2 cannot be filled if Payment Frequency 1 is empty'
                  : true,
            }}
          />
        </ReferenceDataFormRow>

        <ReferenceDataFormRow fields={['resetFrequency1', 'resetFrequency2']}>
          <ReferenceDataField
            name="resetFrequency1"
            label="Reset Frequency 1"
            options={lists.frequency}
            rules={{
              deps: 'resetFrequency2.id',
            }}
          />
          <ReferenceDataField
            name="resetFrequency2"
            label="Reset Frequency 2"
            options={lists.frequency}
            rules={{
              validate: (value, formValue) =>
                !formValue.resetFrequency1?.id && value
                  ? 'Reset Frequency 2 cannot be filled if Reset Frequency 1 is empty'
                  : true,
            }}
          />
        </ReferenceDataFormRow>

        <ReferenceDataFormRow fields={['settlementIndex1', 'settlementIndex2']}>
          <ReferenceDataField
            name="settlementIndex1"
            label="Settlement Index 1"
            options={lists.settlementIndex}
            rules={{
              deps: 'settlementIndex2.id',
            }}
          />
          <ReferenceDataField
            name="settlementIndex2"
            label="Settlement Index 2"
            options={lists.settlementIndex}
            rules={{
              validate: (value, formValue) =>
                !formValue.settlementIndex1?.id && value
                  ? 'Settlement Index 2 cannot be filled if Settlement Index 1 is empty'
                  : true,
            }}
          />
        </ReferenceDataFormRow>
      </Form.Section>

      <Form.Section title="Vol Specific Details">
        <ReferenceDataField
          name="settlementMethod"
          label="Settlement Method"
          options={lists.settlementMethod}
        />

        <ReferenceDataField
          name="standardDiscounting"
          label="Standard Discounting"
          options={lists.standardDiscounting}
        />

        <Form.Row>
          <Form.Field
            label="Strike Unit"
            name="strikeUnit"
            input={
              <Form.SelectField
                options={STRIKE_OPTIONS}
                rules={{
                  deps: 'strikeValue',
                }}
              />
            }
          />
          <Form.Field
            label="Strike"
            name="strikeValue"
            input={
              <Form.TextField
                type="number"
                rules={{
                  validate(val, other) {
                    const { strikeUnit } = other

                    console.log('VALIDATING', { val, strikeUnit })

                    if (
                      strikeUnit === 'BASIS_POINTS' ||
                      strikeUnit === 'PERCENT'
                    ) {
                      return isNumber(val)
                        ? true
                        : 'Strike is Required and it should be number.'
                    }

                    if (strikeUnit === 'ATM') {
                      return val
                        ? 'ATM does not expect a value in Strike'
                        : true
                    }

                    if (!strikeUnit) {
                      return val
                        ? 'Stike Unit is None, No value expected in Strike'
                        : true
                    }

                    return true
                  },
                }}
              />
            }
          />
        </Form.Row>
      </Form.Section>
    </>
  )
}

RecordEditFieldsLinked.useRecordReferenceLists = useRecordReferenceLists

function isNumber(val: any) {
  return !isNaN(parseInt(val))
}
