import { useFormContext as useRHFormContext } from 'react-hook-form'

import { DateTime } from 'luxon'

import { RecordDto, UnlinkRecordRequestDto } from '@lib/pgs'
import { RECORD_STATUS_OPTIONS } from '@tk/frontend/app/Records/common/crud/constants'
import { getFormComponents } from '@tk/frontend/primitives'
import { TwoLineTextCell } from '@tk/frontend/primitives/datagrid/cells'
import { DatePickerField } from '@tk/frontend/primitives/forms/DateTimePicker'
import { FieldInfo } from '@tk/frontend/primitives/forms/FieldInfo'

export type RecordToBeUnlinkedProps = {
  record: RecordDto
}

const Form = getFormComponents<UnlinkRecordRequestDto>()

export function RecordToBeUnlinked({ record }: RecordToBeUnlinkedProps) {
  const { watch } = useRHFormContext<UnlinkRecordRequestDto>()

  const validFromDate = watch('recordToUnlink.validFromDate')
  const validToDate = watch('recordToUnlink.validToDate')

  return (
    <Form.Section title="Record To Be Unlinked">
      <TwoLineTextCell
        label="Record Name"
        line1={record.name}
        line2={record.description}
        lineOneBold={true}
      />
      <Form.Field
        label="Collection Status (Required)"
        name="recordToUnlink.status"
        info={
          <Form.FieldInfo
            title="Controls whether we process and store data internally"
            options={RECORD_STATUS_OPTIONS}
          />
        }
        input={
          <Form.SelectField
            rules={Form.rules.required}
            options={RECORD_STATUS_OPTIONS}
          />
        }
      />
      <Form.Row>
        <Form.Field
          label="Valid From Date (UTC)"
          name="recordToUnlink.validFromDate"
          info={
            <FieldInfo
              title="Valid From and Valid To Dates:"
              details={
                <p>
                  Valid to and valid from dates set the period which this record
                  is expected to have data for. Useful when linking lineage to
                  specify where each record sits in the history of the
                  instrument.
                </p>
              }
            />
          }
          input={
            <DatePickerField
              name="recordToUnlink.validFromDate"
              maxDate={validToDate && DateTime.fromJSDate(validToDate)}
              rules={{
                validate: (value) => {
                  const toDate = validToDate && DateTime.fromJSDate(validToDate)
                  const fromDate = value && DateTime.fromJSDate(value)
                  if (toDate && fromDate && fromDate >= toDate) {
                    return 'Should be earlier than Valid To Date'
                  }
                  return true
                },
              }}
            />
          }
        />

        <Form.Field
          label="Valid To Date (UTC)"
          name="recordToUnlink.validToDate"
          input={
            <DatePickerField
              name="recordToUnlink.validToDate"
              minDate={validFromDate && DateTime.fromJSDate(validFromDate)}
              rules={{
                validate: (value) => {
                  const fromDate =
                    validFromDate && DateTime.fromJSDate(validFromDate)
                  const toDate = value && DateTime.fromJSDate(value)
                  if (fromDate && toDate && toDate <= fromDate) {
                    return 'Should be later than Valid From Date'
                  }
                  return true
                },
              }}
            />
          }
        />
      </Form.Row>
    </Form.Section>
  )
}
