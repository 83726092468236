import { ReactElement } from 'react'

import { ErrorRounded, Grid3x3Rounded } from '@mui/icons-material'
import { Box, Tooltip } from '@mui/material'

import { ExtractaReportDto } from '@lib/extracta'

import { InfoText } from './util-components'

export function StatusTooltip({
  children,
  report,
  when,
}: {
  children: ReactElement
  report: ExtractaReportDto
  when?: 'statusMessage' | 'always'
}) {
  const shouldDisplay = when === 'statusMessage' ? !!report.statusMessage : true

  return (
    <Tooltip
      enterDelay={1000}
      leaveDelay={0}
      title={
        shouldDisplay && (
          <Box sx={{ padding: '0.5rem' }}>
            {report.statusMessage && (
              <InfoText icon={<ErrorRounded width="8px" height="8px" />}>
                "{report.statusMessage}"
              </InfoText>
            )}

            <InfoText icon={<Grid3x3Rounded width="8px" height="8px" />}>
              Report ID <strong>#{report.id}</strong>
            </InfoText>

            <InfoText icon={<Grid3x3Rounded width="8px" height="8px" />}>
              Query ID <strong>{report.queryId?.toUpperCase()}</strong>
            </InfoText>
          </Box>
        )
      }
    >
      {children}
    </Tooltip>
  )
}
