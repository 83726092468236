import * as Icons from '@mui/icons-material'

import { ButtonLink } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'

import { InstrumentListItem } from './types'

export type Props = {
  data: InstrumentListItem
}

export function InstrumentActions({ data }: Props) {
  return (
    <ActionButtonGroup>
      <ButtonLink
        startIcon={<Icons.Edit />}
        to="/multibrand/stubs/instrument/edit/$id"
        params={{ id: data.id }}
        variant="text"
        color="secondary"
      >
        Edit
      </ButtonLink>
    </ActionButtonGroup>
  )
}
