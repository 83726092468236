import { ReactNode } from 'react'
import {
  FieldError,
  FieldValues,
  get,
  Path,
  useFormState,
} from 'react-hook-form'

import { FormLabel, Stack } from '@mui/material'

import { InfoPopover } from '@tk/frontend/primitives/InfoPopover'

export type FieldLabelProps<FormValues extends FieldValues = FieldValues> = {
  name?: Path<FormValues>
  label?: string
  info?: ReactNode
}
export function FieldLabel<FormValues extends FieldValues = FieldValues>({
  label,
  info,
  name,
}: Readonly<FieldLabelProps<FormValues>>) {
  const { errors } = useFormState<FormValues>({
    name,
  })
  const error = get(errors, name, null) as FieldError | null

  if (!label) {
    return
  }

  return (
    <InfoPopover
      content={info}
      contentContainer={<Stack direction="row" margin="1rem" />}
      iconSize="small"
      colour="dark"
    >
      <FormLabel error={!!error} data-name={name}>
        {label}
      </FormLabel>
    </InfoPopover>
  )
}
