import { forwardRef } from 'react'

import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import {
  createLink,
  LinkComponent,
  useLinkProps,
  useNavigate,
} from '@tanstack/react-router'

const MUILinkComponent = forwardRef<
  HTMLAnchorElement,
  Omit<MuiLinkProps, 'href'>
>((props, ref) => {
  return <MuiLink ref={ref} {...props} />
})

const CustomLinkComponent = createLink(MUILinkComponent)

export const AppLink: LinkComponent<typeof MUILinkComponent> = ({
  children,
  ...props
}) => {
  const { href } = useLinkProps({
    to: props.to,
    params: props.params,
    search: props.search,
  })
  const navigate = useNavigate()

  return (
    <CustomLinkComponent
      {...props}
      onClick={(e) => {
        // This is necessary because when used inside a table cell, the table cell hijacks the click handler
        e.preventDefault()
        e.stopPropagation()

        if (props.target) {
          window.open(href, props.target)
        } else {
          navigate({
            to: props.to,
            params: props.params,
            search: props.search,
          })
        }
      }}
    >
      {children}
    </CustomLinkComponent>
  )
}
