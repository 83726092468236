import React from 'react'
import { Controller, useFormContext as useRHFormContext } from 'react-hook-form'

import { Checkbox, FormControlLabel } from '@mui/material'

import { RecordDto, UnlinkRecordRequestDto } from '@lib/pgs'
import { getFormComponents } from '@tk/frontend/primitives'

export type GetMarketGroupsProps = {
  record: RecordDto
  type: 'recordToUnlink' | 'linkedRecords'
}

const Form = getFormComponents<UnlinkRecordRequestDto>()

export function GetMarketGroups({ record, type }: GetMarketGroupsProps) {
  const { control } = useRHFormContext<UnlinkRecordRequestDto>()
  const marketGroups = record.marketGroups

  // Field name is chosen based on type
  const fieldName =
    type === 'recordToUnlink'
      ? 'recordToUnlinkMarketGroups'
      : 'linkedRecordsMarketGroups'

  return (
    <Form.Section
      title={
        type === 'recordToUnlink'
          ? 'Record To Be Unlinked Market Groups'
          : 'Linked Records Market Groups'
      }
    >
      {marketGroups && marketGroups.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
          {marketGroups.map((mg) => (
            <Controller
              key={mg.id}
              name={fieldName}
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => {
                // Determine if this market group is selected.
                const checked = Array.isArray(value) && value.includes(mg.id)
                const handleCheckboxChange = (
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  if (e.target.checked) {
                    onChange([...(value || []), mg.id])
                  } else {
                    onChange((value || []).filter((id: number) => id !== mg.id))
                  }
                }
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        sx={{ marginY: 0, paddingY: 0 }}
                        onChange={handleCheckboxChange}
                        size="small"
                      />
                    }
                    label={mg.description}
                  />
                )
              }}
            />
          ))}
        </div>
      ) : (
        <p>No market groups assigned.</p>
      )}
    </Form.Section>
  )
}
