import type {
  clearingTypeMode,
  ReferenceDataRuleListingItemDto,
} from '@lib/pgs'

import { Inputs } from '@tk/frontend/api'

export type RecordEditDto = Inputs['records']['update']

export type RecordRenameDto = Inputs['records']['rename']

type RemoveModeSuffix<T extends string> = T extends `${infer Base}Mode`
  ? Base
  : T

type ReferenceDataField<T extends object> = {
  [K in keyof T & string]: T[K] extends clearingTypeMode
    ? RemoveModeSuffix<K>
    : never
}[keyof T & string]
type FieldModeConfig<T extends object> = {
  [K in keyof T]: T[K] extends clearingTypeMode ? K : never
}[keyof T & string]

export type ReferenceDataRuleConfigField = NonNullable<
  FieldModeConfig<ReferenceDataRuleListingItemDto>
>
export type RecordReferenceDataField = NonNullable<
  ReferenceDataField<ReferenceDataRuleListingItemDto>
>

export const referenceDataConfigFields: ReferenceDataRuleConfigField[] = [
  'clearingTypeMode',
  'companyMode',
  'currency1Mode',
  'currency2Mode',
  'dayCount1Mode',
  'dayCount2Mode',
  'locationMode',
  'paymentFrequency1Mode',
  'paymentFrequency2Mode',
  'period1Mode',
  'period2Mode',
  'period3Mode',
  'period4Mode',
  'priceTypeMode',
  'pricingFrequencyMode',
  'resetFrequency1Mode',
  'resetFrequency2Mode',
  'settlementIndex1Mode',
  'settlementIndex2Mode',
  'settlementMethodMode',
  'standardDiscountingMode',
] as const
