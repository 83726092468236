import { getFormComponents } from '@tk/frontend/primitives'

import { RecordEditDto } from './types'
import { useRecordReferenceLists } from './useRecordReferenceLists'
import { useReferenceDataRuleRequiredFields } from './useReferenceDataRule'

const Form = getFormComponents<RecordEditDto>()

export function RecordAssetTypeSelect() {
  const [lists] = RecordAssetTypeSelect.useRecordReferenceLists()
  const requiredReferenceDataFields =
    RecordAssetTypeSelect.useReferenceDataRuleRequiredFields()
  const hasRequiredReferenceDataFields = requiredReferenceDataFields.size > 0

  return (
    <Form.Field
      label="Asset Type"
      name="assetType.id"
      info={
        !hasRequiredReferenceDataFields && (
          <Form.FieldInfo title="This asset type has no reference data rules associated" />
        )
      }
      input={<Form.SelectField group options={lists.assetType} />}
    />
  )
}

RecordAssetTypeSelect.useRecordReferenceLists = useRecordReferenceLists
RecordAssetTypeSelect.useReferenceDataRuleRequiredFields =
  useReferenceDataRuleRequiredFields
