import React from 'react'

import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import NiceModal, { useModal } from '@parameta/nice-modal-react'
import { useParams } from '@tanstack/react-router'

import { UnlinkRecordRequestDto, UnlinkResponseDto } from '@lib/pgs'
import { trpc } from '@tk/frontend/api'
import {
  ButtonLink,
  getFormComponents,
  useEntitlement,
} from '@tk/frontend/primitives'

import { GetMarketGroups } from './GetMarketGroups'
import { LinkedRecordsForm } from './LinkedRecordsForm'
import { RecordToBeUnlinked } from './RecordToBeUnlinked'
import { UnlinkResponseModal } from './UnlinkResponseModal'

export type LinkedDataSectionProps = {
  recordId: number
}

const Form = getFormComponents<UnlinkRecordRequestDto>()

export function UnlinkRecordsPage({ recordId }: LinkedDataSectionProps) {
  const params = useParams({ from: '/record-management/records/unlink/$id' })
  const id = params.id

  const { data: record } = trpc.records.get.useQuery({ id })

  const form = Form.useForm({
    defaultValues: {
      recordToUnlink: {
        id: id,
        status: undefined,
        validFromDate: undefined,
        validToDate: undefined,
      },
      recordToUnlinkMarketGroups: undefined,
      linkedRecordsMarketGroups: undefined,
      linkedRecordsToUpdate: [],
    },
  })

  const { handleSubmit, reset } = form

  const editable = useEntitlement('records.manage')
  const linkMutation = trpc.records.unlinkRecords.useMutation()
  const modal = useModal(UnlinkResponseModal)
  const utils = trpc.useContext()

  const onSubmit = async (data: UnlinkRecordRequestDto) => {
    try {
      const convertToNumberArray = (value: any) => {
        if (!value || (Array.isArray(value) && value.length === 0)) {
          return undefined
        }
        return Array.isArray(value)
          ? value.map(Number).filter((v) => v !== 0)
          : [Number(value)].filter((v) => v !== 0)
      }

      const convertedData: UnlinkRecordRequestDto = {
        ...data,
        recordToUnlinkMarketGroups: convertToNumberArray(
          data.recordToUnlinkMarketGroups
        ),
        linkedRecordsMarketGroups: convertToNumberArray(
          data.linkedRecordsMarketGroups
        ),
      }

      const response: UnlinkResponseDto = await linkMutation.mutateAsync(
        convertedData
      )
      const recordId = id
      modal.show({ response, recordId })

      utils.records.invalidate()
    } catch (error) {
      console.error('Error unlinking records:', error)
    }
  }

  React.useEffect(() => {
    if (record) {
      reset({
        recordToUnlink: {
          id: record.id,
          status: record.status,
          validFromDate: record.validFromDate
            ? new Date(record.validFromDate)
            : undefined,
          validToDate: record.validToDate
            ? new Date(record.validToDate)
            : undefined,
        },
        linkedRecordsToUpdate:
          record.linkedRecords?.map((lr) => ({
            id: lr.id,
            status: lr.status,
            validFromDate: lr.validFromDate
              ? new Date(lr.validFromDate)
              : undefined,
            validToDate: lr.validToDate ? new Date(lr.validToDate) : undefined,
          })) || [],
      })
    }
  }, [record, reset])

  return (
    <Form.Provider {...form}>
      <Form.FormConfigProvider editable={editable}>
        <NiceModal.Provider>
          <Form.FormStack onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitleRow title="Unlink Records Form">
              <ButtonLink
                to="/record-management/records/edit/$id"
                params={{ id: id }}
                variant="contained"
                color="primary"
                startIcon={<Icons.Close />}
              >
                Close
              </ButtonLink>
              <Button
                startIcon={<Icons.Save />}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Form.SectionTitleRow>
            <Form.SectionBodyGrid>
              <input {...form.register('recordToUnlink.id')} hidden />
              {record && <RecordToBeUnlinked record={record} />}
              {record && <LinkedRecordsForm record={record} />}
              {record && (
                <GetMarketGroups record={record} type="recordToUnlink" />
              )}
              {record && (
                <GetMarketGroups record={record} type="linkedRecords" />
              )}
            </Form.SectionBodyGrid>
          </Form.FormStack>
        </NiceModal.Provider>
      </Form.FormConfigProvider>
    </Form.Provider>
  )
}
