import { UseControllerProps } from 'react-hook-form'

import { getFormComponents } from '@tk/frontend/primitives'
import { Option } from '@tk/frontend/primitives/forms/SelectField'

import { RecordEditDto, RecordReferenceDataField } from './types'
import { useReferenceDataRuleRequiredFields } from './useReferenceDataRule'

const Form = getFormComponents<RecordEditDto>()

interface ReferenceDataFieldProps {
  name: RecordReferenceDataField
  label: string
  rules?: UseControllerProps<RecordEditDto>['rules']
  options: Option[]
}

export function ReferenceDataField({
  name,
  label,
  rules,
  options,
}: Readonly<ReferenceDataFieldProps>) {
  const requiredReferenceDataFields =
    ReferenceDataField.useReferenceDataRuleRequiredFields()
  const hasRequiredFields = requiredReferenceDataFields.size > 0

  if (hasRequiredFields && !requiredReferenceDataFields.has(name)) {
    return null
  }

  return (
    <Form.Field
      label={label + (hasRequiredFields ? ' (Required)' : '')}
      name={`${name}.id`}
      input={
        <Form.SelectField
          options={options}
          rules={{
            ...(hasRequiredFields && Form.rules.required),
            ...rules,
          }}
        />
      }
    />
  )
}

ReferenceDataField.useReferenceDataRuleRequiredFields =
  useReferenceDataRuleRequiredFields
