import React from 'react'

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { DateTime } from 'luxon'

import {
  LinkedRecordsDto,
  MarketGroupDto,
  RecordDto,
  RecordListingItemDto,
} from '@lib/pgs'
import { AppLink } from '@tk/frontend/primitives'
import {
  NameDateCell,
  PairedDateCell,
  TwoLineTextCell,
} from '@tk/frontend/primitives/datagrid/cells'

export type LinkedRecordsPopoverTableProps = {
  linkedRecords: LinkedRecordsDto[]
  record?: RecordListingItemDto | RecordDto
  secondRecord?: RecordListingItemDto | RecordDto
  marketGroups?: MarketGroupDto[]
}

export function LinkedRecordsPopoverTable({
  linkedRecords,
  record,
  secondRecord,
  marketGroups,
}: LinkedRecordsPopoverTableProps) {
  return (
    <Stack direction="column" padding="1rem">
      <Table size="small" style={{ width: '25rem' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: '0.75rem', minWidth: '170px' }}>
              Name
            </TableCell>
            <TableCell sx={{ padding: '0.75rem', minWidth: '120px' }}>
              Source
            </TableCell>
            <TableCell sx={{ padding: '0.75rem', minWidth: '100px' }}>
              Status
            </TableCell>
            <TableCell sx={{ padding: '0.75rem', minWidth: '150px' }}>
              Valid Dates
            </TableCell>
            <TableCell sx={{ padding: '0.75rem', minWidth: '170px' }}>
              Created Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!record && !secondRecord && linkedRecords.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={5}
                sx={{
                  padding: '0.75rem',
                  textAlign: 'center',
                  fontStyle: 'italic',
                  color: 'grey.600',
                }}
              >
                Collection Empty - Record moved to other collection
              </TableCell>
            </TableRow>
          )}

          {record && (
            <TableRow>
              <TableCell sx={{ padding: '0.75rem', minWidth: '170px' }}>
                <AppLink
                  to="/record-management/records/edit/$id"
                  params={{ id: record.id }}
                >
                  {record.name}
                </AppLink>
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <TwoLineTextCell
                  line1={record.backbone?.name}
                  line2={record.source?.name}
                />
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>{record.status}</TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <PairedDateCell
                  dateOne={
                    record.validFromDate
                      ? DateTime.fromJSDate(record.validFromDate).toISO()
                      : undefined
                  }
                  dateTwo={
                    record.validToDate
                      ? DateTime.fromJSDate(record.validToDate).toISO()
                      : undefined
                  }
                />
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <NameDateCell
                  name={record.createdBy}
                  date={DateTime.fromJSDate(record.createdDate).toISO()}
                />
              </TableCell>
            </TableRow>
          )}

          {secondRecord && (
            <TableRow>
              <TableCell sx={{ padding: '0.75rem' }}>
                <AppLink
                  to="/record-management/records/edit/$id"
                  params={{ id: secondRecord.id }}
                >
                  {secondRecord.name}
                </AppLink>
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <TwoLineTextCell
                  line1={secondRecord.backbone?.name}
                  line2={secondRecord.source?.name}
                />
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                {secondRecord.status}
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <PairedDateCell
                  dateOne={
                    secondRecord.validFromDate
                      ? DateTime.fromJSDate(secondRecord.validFromDate).toISO()
                      : undefined
                  }
                  dateTwo={
                    secondRecord.validToDate
                      ? DateTime.fromJSDate(secondRecord.validToDate).toISO()
                      : undefined
                  }
                />
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <NameDateCell
                  name={secondRecord.createdBy}
                  date={
                    secondRecord.createdDate
                      ? DateTime.fromJSDate(secondRecord.createdDate).toISO()
                      : undefined
                  }
                />
              </TableCell>
            </TableRow>
          )}

          {linkedRecords.map((record) => (
            <TableRow key={record.id}>
              <TableCell sx={{ padding: '0.75rem' }}>
                <AppLink
                  to="/record-management/records/edit/$id"
                  params={{ id: record.id }}
                >
                  {record.name}
                </AppLink>
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <TwoLineTextCell
                  line1={record.backbone?.name}
                  line2={record.source?.name}
                />
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>{record.status}</TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <PairedDateCell
                  dateOne={
                    record.validFromDate
                      ? DateTime.fromJSDate(record.validFromDate).toISO()
                      : undefined
                  }
                  dateTwo={
                    record.validToDate
                      ? DateTime.fromJSDate(record.validToDate).toISO()
                      : undefined
                  }
                />
              </TableCell>
              <TableCell sx={{ padding: '0.75rem' }}>
                <NameDateCell
                  name={record.createdBy}
                  date={record.createdDate}
                />
              </TableCell>
            </TableRow>
          ))}

          {marketGroups && (
            <TableRow>
              <TableCell sx={{ padding: '0.75rem' }} colSpan={5}>
                <Typography>
                  <strong>Market Groups: </strong>
                  {marketGroups.map((mg) => mg.description).join(', ')}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Stack>
  )
}
