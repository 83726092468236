import { ReactNode } from 'react'

import { getFormComponents } from '@tk/frontend/primitives'

import { RecordEditDto, RecordReferenceDataField } from './types'
import { useReferenceDataRuleRequiredFields } from './useReferenceDataRule'

const Form = getFormComponents<RecordEditDto>()

interface ReferenceDataFormRowProps {
  fields: RecordReferenceDataField[]
  children: ReactNode
  displayEmpty?: ReactNode
}

export function ReferenceDataFormRow({
  fields,
  children,
  displayEmpty = null,
}: Readonly<ReferenceDataFormRowProps>) {
  const requiredReferenceDataFields =
    ReferenceDataFormRow.useReferenceDataRuleRequiredFields()
  const hasRequiredFields = requiredReferenceDataFields.size > 0

  if (
    hasRequiredFields &&
    !fields.some((field) => requiredReferenceDataFields.has(field))
  ) {
    return displayEmpty
  }

  return <Form.Row>{children}</Form.Row>
}
ReferenceDataFormRow.useReferenceDataRuleRequiredFields =
  useReferenceDataRuleRequiredFields
