import React from 'react'

import * as Icons from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import NiceModal, { useModal } from '@parameta/nice-modal-react'

import { UnlinkResponseDto } from '@lib/pgs'
import { LinkedRecordsPopoverTable } from '@tk/frontend/app/Records/LinkedRecordsPopoverTable'
import { ButtonLink } from '@tk/frontend/primitives'

export const UnlinkResponseModal = NiceModal.create(
  ({
    response,
    recordId,
  }: {
    response: UnlinkResponseDto
    recordId: number
  }) => {
    const modal = useModal()
    const { recordToUnlinkMarketEntity, linkedRecordsMarketEntity } = response

    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1300,
        }}
        onClick={() => modal.remove()}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            width: '60rem',
            maxHeight: '90%',
            overflow: 'auto',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            <strong>Unlink Summary</strong>
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Unlinked Record</strong>
              </Typography>
              <LinkedRecordsPopoverTable
                linkedRecords={recordToUnlinkMarketEntity?.records || []}
                marketGroups={recordToUnlinkMarketEntity?.marketGroups}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Remaining Linked Collection</strong>
              </Typography>
              <LinkedRecordsPopoverTable
                linkedRecords={linkedRecordsMarketEntity?.records || []}
                marketGroups={linkedRecordsMarketEntity?.marketGroups}
              />
            </Box>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonLink
              to="/record-management/records/edit/$id"
              params={{ id: recordId }}
              variant="contained"
              color="primary"
              startIcon={<Icons.Close />}
              onClick={() => modal.remove()}
            >
              Close
            </ButtonLink>
          </Box>
        </Box>
      </Box>
    )
  }
)
