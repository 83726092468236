import { useCallback } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, List, ListItem, Typography } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { useSnackbar } from 'notistack'

import { trpc } from '@tk/frontend/api'
import { useRecordsList } from '@tk/frontend/app/Records/useRecordsList'
import { useRecordsQueryParams } from '@tk/frontend/app/Records/useRecordsQueryParams'
import { When } from '@tk/frontend/primitives'
import { CustomSnackbar } from '@tk/frontend/primitives/CustomSnackbar'
import { MenuButton, MenuItem } from '@tk/frontend/primitives/menu'
import { MenuItemLink } from '@tk/frontend/primitives/MenuItemLink'

import { SalesSampleModal } from './SalesSampleModal'

const MAX_RECORDS = 200_000

export const ExtractMenu = () => {
  const [records] = useRecordsList()

  const numberOfRecords = records.totalElements

  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const recordsParams = useSearch({ from: '/record-management/records/' })

  const params = useRecordsQueryParams()

  const renderValidationError = useCallback(
    (errors: string[]) =>
      snackbar.enqueueSnackbar(<ExtractValidationError errors={errors} />, {
        autoHideDuration: 5000,
        preventDuplicate: true,
        content: (key, message) => (
          <CustomSnackbar key={key} message={message} variant="error" />
        ),
      }),
    [snackbar]
  )

  const {
    mutate: runCreationValidation,
    isPending: isExtractValidationPending,
  } = trpc.extracta.runCreationValidation.useMutation({
    retry: false,
    onSuccess() {
      navigate({
        to: '/extracta/create',
        search: {
          'grid-filters': recordsParams?.['grid-filters'] ?? '',
          'filter': recordsParams?.filter ?? '',
          'searchField': params?.searchField ?? 'filter',
        },
      })
    },
    onError(error) {
      if (error.data?.errorType === 'validation-error') {
        const { errors } = error.data

        renderValidationError(errors)
      }
    },
  })

  const salesSampleModal = useModal(SalesSampleModal)

  const {
    mutate: runSalesSampleCreationValidation,
    isPending: isSalesSampleValidationPending,
  } = trpc.extracta.runSalesSampleValidation.useMutation({
    retry: false,
    async onSuccess() {
      salesSampleModal.show()
    },
    onError(error) {
      if (error.data?.errorType === 'validation-error') {
        const { errors } = error.data

        renderValidationError(errors)
      }
    },
  })

  const handleSalesSampleCreateClick = useCallback(async () => {
    if (numberOfRecords <= MAX_RECORDS) {
      runSalesSampleCreationValidation({
        recordParams: params.query,
      })
    } else {
      renderValidationError([
        `Extracts may only be generated for less then ${MAX_RECORDS} records.`,
      ])
    }
  }, [
    numberOfRecords,
    params.query,
    runSalesSampleCreationValidation,
    renderValidationError,
  ])

  const handleExtractCreateClick = useCallback(async () => {
    if (numberOfRecords <= MAX_RECORDS) {
      runCreationValidation({
        recordParams: params.query,
      })
    } else {
      renderValidationError([
        `Extracts may only be generated for less then ${MAX_RECORDS} records.`,
      ])
    }
  }, [
    numberOfRecords,
    params.query,
    runCreationValidation,
    renderValidationError,
  ])

  return (
    <MenuButton label="Extract" icon={<Icons.AssignmentReturnedRounded />}>
      <When can="data-extract.read">
        <MenuItemLink to="/extracta/reports" startIcon={<Icons.ListRounded />}>
          Reports List
        </MenuItemLink>
      </When>

      <When can="data-extract.manage">
        <MenuItem
          startIcon={<Icons.AddRounded />}
          disabled={
            isExtractValidationPending ||
            numberOfRecords === undefined ||
            numberOfRecords === 0
          }
          onClick={async (e) => {
            e.preventDefault()
            e.stopPropagation()

            await handleExtractCreateClick()
          }}
        >
          {isExtractValidationPending ? 'Working...' : 'Create report'}
        </MenuItem>
      </When>

      <When can="data-extract-sales-snap.manage">
        <MenuItem
          startIcon={<Icons.CameraAltRounded />}
          disabled={numberOfRecords === undefined || numberOfRecords === 0}
          onClick={async (e) => {
            e.preventDefault()
            e.stopPropagation()

            await handleSalesSampleCreateClick()
          }}
        >
          {isSalesSampleValidationPending
            ? 'Working...'
            : 'Export Sales Sample'}
        </MenuItem>
      </When>
    </MenuButton>
  )
}

type ExtractValidationErrorProps = {
  errors: string[]
}

const ExtractValidationError = ({ errors }: ExtractValidationErrorProps) => {
  return (
    <Box>
      <Typography sx={{ fontWeight: '500', fontSize: (t) => t.typography.h6 }}>
        Validation Errors.
      </Typography>
      <List sx={{ listStyleType: 'disc', pl: 4, pb: 0 }}>
        {errors.map((error) => (
          <ListItem
            key={error}
            sx={{
              display: 'list-item',
              m: 0,
              p: 0,
              fontSize: '',
            }}
          >
            <Typography>{error}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
