import { useMemo, useState } from 'react'

import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { GridRowId } from '@mui/x-data-grid-premium'
import { create, useModal } from '@parameta/nice-modal-react'

import { isEdfMarkerFid, isInternalFidAcronym } from '@lib/utils'
import { trpc } from '@tk/frontend/api'
import { useRecordsQueryParams } from '@tk/frontend/app/Records/useRecordsQueryParams'

import { SalesSampleModalRenderer, useExportSalesSample } from './SalesSample'
import { SalesSampleFidOrderingGrid } from './SalesSampleFidOrderingGrid'
import { Fids } from './types'

type Model = {}

export const SalesSampleModal = create<Model>((model) => {
  const parentModal = useModal()
  const modal = useModal(SalesSampleModalRenderer)
  const params = useRecordsQueryParams()

  const sampleExport = useExportSalesSample()

  const [fidModel, setFidModel] = useState<GridRowId[]>([])
  const extractaData = trpc.extracta.getSalesSampleFids.useQuery({
    recordParams: params.query,
  })

  const uniqueFids = useMemo(() => {
    const fids =
      extractaData.data?.fids
        .filter((f) => !isInternalFidAcronym(f.acronym))
        .filter((f) => !isEdfMarkerFid(f.fidId))
        .filter((f) => f.scope === 'EXTERNAL')
        .filter((f) => f.available)
        .reduce((acc, current) => {
          if (!acc.some((f) => f.fidId === current.fidId)) {
            acc.push(current)
          }
          return acc
        }, [] as Fids) ?? []
    setFidModel(fids.map((f) => f.rowId))
    return fids
  }, [extractaData.data])

  return (
    <Dialog open={parentModal.visible} onClose={parentModal.hide}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <>Sales sample Field Ordering </>
          <Button
            variant="contained"
            color="primary"
            disabled={extractaData.isPending}
            onClick={async () => {
              modal.hide()
              const orderedFids = fidModel
                .map((m) => uniqueFids.find((f) => f.rowId === m))
                .filter(
                  (fid): fid is NonNullable<typeof fid> => fid !== undefined
                )

              const res = await sampleExport.startExport(orderedFids)

              modal.show({
                type: 'export',
                reportId: res?.id ?? 0,
              })
            }}
          >
            Start Extract
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <SalesSampleFidOrderingGrid
          loading={extractaData.isPending}
          fids={uniqueFids ?? []}
          model={fidModel}
          setModel={setFidModel}
        />
      </DialogContent>
    </Dialog>
  )
})
