import {
  getDefaultGridFilterModel,
  GridFilterModel,
  GridLogicOperator,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-premium'

import { useQueryParam } from '@tk/frontend/primitives/useQueryParam'

const DefaultGridFilterModel = getDefaultGridFilterModel()

const decode = (value: string) => {
  if (!value) {
    return
  }

  try {
    return JSON.parse(window.atob(value)) as GridFilterModel
  } catch {
    return
  }
}

const encode = (value: GridFilterModel | undefined) => {
  if (!value || value.items.length === 0) {
    value = DefaultGridFilterModel
  }

  return window.btoa(JSON.stringify(value))
}

export function useFilterModel(name = 'grid-filters') {
  const [model, setModel] = useQueryParam(name, {
    // FIXME: this produces a tidier URI than JsonParam, BUT it's also not good.
    // We should have a dedicated QueryParams setup which does a fundamental
    //  transformation of GridFilterModel to a nice-ish URL.
    decode,
    encode,
    defaultValue: DefaultGridFilterModel,
  })

  return {
    model,
    setModel,
    slotProps,
  }
}

const slotProps: GridSlotsComponentsProps = {
  filterPanel: {
    columnsSort: 'asc',
    logicOperators: [GridLogicOperator.And],
  },
}
