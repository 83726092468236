import { useCallback } from 'react'

import { RecordRequestDto } from '@lib/pgs'

import { useReferenceDataRuleHiddenFields } from './useReferenceDataRule'

interface UseRecordPayloadArgs {
  assetTypeId: number
  type: 'ITEM' | 'CHAIN' | 'CHAIN_ITEM' | undefined
}

export const useSanitiseRecordPayload = (args: UseRecordPayloadArgs) => {
  const hiddenFields = useReferenceDataRuleHiddenFields(args)

  return useCallback(
    (
      values: RecordRequestDto & {
        id: number
      }
    ) => {
      // Remove hidden fields from the payload as they can't be set
      for (const field of hiddenFields) {
        delete values[field]
      }
    },
    [hiddenFields]
  )
}
