import React, { ReactElement, ReactNode } from 'react'
import { FieldValues, Path } from 'react-hook-form'

import * as Icons from '@mui/icons-material'
import { Box, TextFieldProps } from '@mui/material'

import { FieldLabel } from './FieldLabel'
import { useFormContext } from './FormConfigContext'
import { Cell, RowStack } from './Row'
import { TextField } from './TextField'

export type FieldProps<FormValues extends FieldValues = FieldValues> = {
  name: Path<FormValues>
  label?: string
  info?: ReactNode
  disabled?: boolean
  input?: ReactElement<TextFieldProps>
  disabledSuffixElement?: ReactElement
}
export function Field<FormValues extends FieldValues = FieldValues>({
  label,
  info,
  name,
  input = <TextField<FormValues> />,
  disabledSuffixElement = (
    <Cell>
      <Icons.Lock color="secondary" />
    </Cell>
  ),
  disabled = false,
}: Readonly<FieldProps<FormValues>>) {
  const { editable } = useFormContext()

  const isDisabled = disabled || !editable

  return (
    <Box width="100%">
      <FieldLabel label={label} info={info} name={name} />

      <RowStack>
        {React.cloneElement(input, {
          name: name,
          disabled: isDisabled,
          variant: 'outlined',
        })}

        {isDisabled && disabledSuffixElement}
      </RowStack>
    </Box>
  )
}
