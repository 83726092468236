import React, { ReactElement, useState } from 'react'

import { Button, DialogActions, DialogTitle, Popover } from '@mui/material'

export type ConfirmPopoverProps = {
  children: ReactElement<{ onClick: (e: React.MouseEvent<any>) => void }>
  prompt: string | ReactElement
  onOk: () => Promise<void> | void
  onCancel?: () => Promise<void> | void
}

export function ConfirmPopover({
  children,
  prompt,
  onOk,
  onCancel = () => {
    //
  },
  ...props
}: ConfirmPopoverProps) {
  const [open, setOpen] = useState<false | { left: number; top: number }>(false)

  const child = React.Children.only(children)

  return (
    <>
      {React.cloneElement(child, {
        ...props,
        onClick: (e: React.MouseEvent<any>) => {
          setOpen({
            left: e.clientX,
            top: e.clientY,
          })
        },
      })}

      <Popover
        open={!!open}
        anchorReference="anchorPosition"
        anchorPosition={open || undefined}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => {
          setOpen(false)
          onCancel()
        }}
      >
        <DialogTitle component="div">{prompt}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false)
              onOk()
            }}
          >
            OK
          </Button>

          <Button
            onClick={() => {
              setOpen(false)
              onCancel()
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Popover>
    </>
  )
}
