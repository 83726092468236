import { useMemo } from 'react'

import { Box, Stack, Typography } from '@mui/material'

import { Outputs, trpc } from '@tk/frontend/api'
import { transformFiltersToTags } from '@tk/frontend/app/CommercialPackages/Package/dataTransforms'
import {
  FilterKey,
  FILTERS_CONFIG,
} from '@tk/frontend/app/Records/filters-config'
import { BasicFilterTag } from '@tk/frontend/app/Records/FilterTags'
import { theme } from '@tk/frontend/theme'

type ExistingCommercialPackageFiltersProps = {
  packageId: number
}

export function ExistingCommercialPackageFilters({
  packageId,
}: ExistingCommercialPackageFiltersProps) {
  const [data] = trpc.commercialPackages.getPackage.useSuspenseQuery({
    id: packageId,
  })

  if (data.mode === 'LEGACY') {
    return null
  }

  return (
    <Box sx={{ marginTop: '0px!important', paddingY: '0.75rem' }}>
      <ExistingFilterSection filterType="ADDITIVE" data={data.filters} />
      <ExistingFilterSection filterType="SUBTRACTIVE" data={data.filters} />
    </Box>
  )
}

type ExistingFilterSectionProps = {
  filterType: 'ADDITIVE' | 'SUBTRACTIVE'
  data: Outputs['commercialPackages']['getPackage']['filters']
}

function ExistingFilterSection({
  filterType,
  data,
}: ExistingFilterSectionProps) {
  const filters = useMemo(
    () =>
      transformFiltersToTags(
        filterType,
        data?.filter((filter) => filter.type === filterType)
      ),
    [filterType, data]
  )

  return (
    <>
      <Typography sx={{ opacity: 0.6 }} marginY="0.75rem">
        {filterType === 'ADDITIVE' ? 'Include' : 'Exclude'}
      </Typography>

      <Stack direction="row" width="100%">
        <Stack direction="column" width="100%" spacing={4}>
          {filters?.length === 0 && (
            <Typography
              sx={{ textAlign: 'center', paddingBottom: '2rem' }}
              color="GrayText"
              fontSize="13px"
            >
              No {filterType.toLowerCase()} filters added.
            </Typography>
          )}

          {filters?.map((filter, i) => {
            return (
              <Stack
                key={`filter-${filterType}-${i}`}
                direction="row"
                alignItems="flex-start"
                spacing={2}
                width="100%"
                padding="0.5rem 0.75rem"
                justifyContent="space-between"
                border="1px solid"
                borderRadius="0.25rem"
                borderColor={theme.palette.grey[400]}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                    flex: 1,
                  }}
                >
                  {filter.map((item) => {
                    if (item.field === 'filter' && item.value !== '%') {
                      return (
                        <BasicFilterTag
                          key={item.key}
                          fieldLabel="Name"
                          valueLabel={String(item.value)}
                        />
                      )
                    }

                    if (
                      item.field === 'filterDescription' &&
                      item.value &&
                      item.value !== '%'
                    ) {
                      return (
                        <BasicFilterTag
                          key={item.key}
                          fieldLabel="Description"
                          valueLabel={String(item.value)}
                        />
                      )
                    }

                    const filterConfig = FILTERS_CONFIG[item.field as FilterKey]
                    if (!filterConfig) {
                      return null
                    }

                    if (filterConfig.type === 'basic') {
                      return (
                        <BasicFilterTag
                          key={item.key}
                          fieldLabel={filterConfig.getFieldLabel()}
                          valueLabel={filterConfig.getValueLabel(item.value)}
                        />
                      )
                    }

                    const FilterTagComponent = filterConfig.FilterTag
                    return (
                      <FilterTagComponent
                        key={item.key}
                        value={String(item.value)}
                      />
                    )
                  })}
                </Box>
              </Stack>
            )
          })}
        </Stack>
      </Stack>
    </>
  )
}
