import { useLayoutEffect, useRef } from 'react'
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { Box, TextFieldProps as MuiTextFieldProps } from '@mui/material'
import {
  DesktopDateTimePicker,
  LocalizationProvider,
  PickersTimezone,
} from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { DateTime } from 'luxon'

interface DatePickerFieldProps<FormValues extends FieldValues = FieldValues> {
  name?: Path<FormValues>
  label?: string
  placeholder?: string
  fullWidth?: boolean
  autoFocus?: boolean
  width?: string
  variant?: MuiTextFieldProps['variant']
  align?: 'left' | 'right'
  disabled?: boolean
  rules?: UseControllerProps<FormValues>['rules']
  maxDate?: DateTime | null
  minDate?: DateTime | null
  defaultValue?: DateTime | null
  timezone?: PickersTimezone
  style?: React.CSSProperties
}

export function DatePickerField<FormValues extends FieldValues = FieldValues>({
  name,
  label,
  placeholder,
  fullWidth,
  autoFocus,
  width = '100%',
  variant = 'standard',
  align = 'left',
  disabled,
  rules,
  maxDate,
  minDate,
  timezone = 'utc',
  style: customStyle,
  defaultValue,
}: Readonly<DatePickerFieldProps<FormValues>>) {
  const { field, fieldState } = useController<FormValues>({
    name: name ?? ('' as any),
    rules: rules,
  })

  const errorMessage = fieldState.error?.message

  const ref = useRef<any>(undefined)
  const focus = useRef(autoFocus)
  useLayoutEffect(() => {
    if (focus.current) {
      ref.current?.focus?.()
    }
  }, [])

  const handleDateChange = (date: DateTime | null) => {
    const jsDate = date?.toJSDate() || null
    field.onChange(jsDate)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box display="flex" alignItems="center">
        <DesktopDateTimePicker
          defaultValue={defaultValue}
          label={label}
          value={
            field.value
              ? DateTime.fromJSDate(field.value)
              : defaultValue ?? null
          }
          format="yyyy-MM-dd HH:mm:ss"
          onChange={handleDateChange}
          maxDateTime={maxDate || undefined}
          minDateTime={minDate || undefined}
          ampm={false}
          timezone={timezone}
          disabled={disabled}
          slotProps={{
            actionBar: {
              actions: ['clear', 'accept'],
            },
            textField: {
              error: !!errorMessage,
              helperText: errorMessage,
              name,
            },
          }}
          sx={{
            '& .MuiFormControl-root, & .MuiInputBase-formControl, & .MuiInputBase-input':
              {
                height: '80%',
              },
            'width': fullWidth ? '100%' : width,
            ...customStyle,
          }}
        />
      </Box>
    </LocalizationProvider>
  )
}
