import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, Divider, Stack } from '@mui/material'

import { RecordDto, UnlinkRecordRequestDto } from '@lib/pgs'
import { RECORD_STATUS_OPTIONS } from '@tk/frontend/app/Records/common/crud/constants'
import { getFormComponents } from '@tk/frontend/primitives'
import { TwoLineTextCell } from '@tk/frontend/primitives/datagrid/cells'
import { DatePickerField } from '@tk/frontend/primitives/forms/DateTimePicker'

export type LinkedRecordsFormProps = {
  record: RecordDto
}

const Form = getFormComponents<UnlinkRecordRequestDto>()

export function LinkedRecordsForm({ record }: LinkedRecordsFormProps) {
  const { register } = useFormContext<UnlinkRecordRequestDto>()

  return (
    <Form.Section title="Linked Records">
      <Box sx={{ maxHeight: 400, overflowY: 'auto', p: 2 }}>
        {record.linkedRecords && record.linkedRecords.length > 0 ? (
          <Stack spacing={3}>
            {record.linkedRecords.map((linkedRecord, index) => (
              <React.Fragment key={linkedRecord.id}>
                <Stack spacing={2}>
                  <TwoLineTextCell
                    label="Record Name"
                    line1={linkedRecord.name}
                    line2={linkedRecord.description}
                    lineOneBold={true}
                  />

                  <input
                    type="hidden"
                    {...register(`linkedRecordsToUpdate.${index}.id`)}
                  />

                  <Form.Field
                    label="Collection Status (Required)"
                    name={`linkedRecordsToUpdate.${index}.status`}
                    info={
                      <Form.FieldInfo
                        title="Controls whether we process and store data internally"
                        options={RECORD_STATUS_OPTIONS}
                      />
                    }
                    input={
                      <Form.SelectField
                        rules={Form.rules.required}
                        options={RECORD_STATUS_OPTIONS}
                      />
                    }
                  />

                  <Form.Row>
                    <Form.Field
                      label="Valid From Date (UTC)"
                      name={`linkedRecordsToUpdate.${index}.validFromDate`}
                      input={
                        <DatePickerField
                          name={`linkedRecordsToUpdate.${index}.validFromDate`}
                        />
                      }
                    />

                    <Form.Field
                      label="Valid To Date (UTC)"
                      name={`linkedRecordsToUpdate.${index}.validToDate`}
                      input={
                        <DatePickerField
                          name={`linkedRecordsToUpdate.${index}.validToDate`}
                        />
                      }
                    />
                  </Form.Row>
                </Stack>

                {index < (record.linkedRecords?.length ?? 0) - 1 && (
                  <Divider sx={{ my: 2 }} />
                )}
              </React.Fragment>
            ))}
          </Stack>
        ) : (
          <div>No linked records found.</div>
        )}
      </Box>
    </Form.Section>
  )
}
