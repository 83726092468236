import { MultibrandAttributeType } from '@lib/pgs-types'
import { Inputs, Outputs } from '@tk/frontend/api'

export type RrnStructureRequestDto =
  Inputs['multibrand']['rrnStructure']['create']

export type RrnStructureDto = Outputs['multibrand']['rrnStructure']['get']

export type RrnStructureListDto =
  Outputs['multibrand']['rrnStructure']['list'][0]

export type SelectedStubs = NonNullable<
  RrnStructureRequestDto['marketInstruments']
>

export type StatusSelection = 'retired' | 'active'

export type RrnStructureFormData = RrnStructureRequestDto & {
  status: StatusSelection
}

export type FieldArrayStubs = (SelectedStubs[0] & { formId: string })[]

export type MultibrandAttributeType =
  (typeof MultibrandAttributeType)['_output']

type AttributeFields<T extends object> = {
  [K in keyof T]: NonNullable<T[K]> extends MultibrandAttributeType ? K : never
}[keyof T]

export const attributeFields: AttributeFields<RrnStructureFormData>[] = [
  'attribute1',
  'attribute2',
  'attribute3',
  'attribute4',
  'attribute5',
  'attribute6',
  'attribute7',
  'attribute8',
] as const

export type AttributeField = NonNullable<(typeof attributeFields)[number]>
