import React, { useState } from 'react'

import { ClickAwayListener, Popover, Typography } from '@mui/material'

import {
  LinkedRecordsDto,
  MarketGroupDto,
  RecordDto,
  RecordListingItemDto,
} from '@lib/pgs'

import { LinkedRecordsPopoverTable } from './LinkedRecordsPopoverTable'

export type LinkedRecordsPopoverProps = {
  linkedRecords: LinkedRecordsDto[]
  linkText?: string
  record?: RecordListingItemDto | RecordDto
  secondRecord?: RecordListingItemDto | RecordDto
  marketGroups?: MarketGroupDto[]
}

export function LinkedRecordsPopover({
  linkedRecords,
  linkText,
  record,
  secondRecord,
  marketGroups,
}: LinkedRecordsPopoverProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const isOpen = Boolean(anchorEl)
  const totalCount =
    linkedRecords.length + (record ? 1 : 0) + (secondRecord ? 1 : 0)
  const calculatedLinkText =
    linkText ??
    (totalCount === 1 ? 'Single record' : `${totalCount} linked records`)

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <LinkedRecordsPopoverTable
            linkedRecords={linkedRecords}
            record={record}
            secondRecord={secondRecord}
            marketGroups={marketGroups}
          />
        </Popover>

        <Typography
          variant="body2"
          color="primary"
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={handleOpen}
        >
          {calculatedLinkText}
        </Typography>
      </div>
    </ClickAwayListener>
  )
}
