import * as Icons from '@mui/icons-material'
import { Button } from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'

import { ButtonLink } from '@tk/frontend/primitives'
import { ActionButtonGroup } from '@tk/frontend/primitives/datagrid'

import { MutationModal } from './MutationModal'
import { MarketGroup } from './types'

export type Props = {
  data: MarketGroup
}

export function MarketGroupActions({ data }: Props) {
  const modal = useModal(MutationModal)

  return (
    <ActionButtonGroup>
      <ButtonLink
        color="primary"
        startIcon={<Icons.Add />}
        variant="text"
        to="/multibrand/stubs/instrument/create"
        search={{ 'market-group': data.id }}
      >
        Add
      </ButtonLink>
      <Button
        startIcon={<Icons.Edit />}
        onClick={() => {
          modal.show({
            data: data,
          })
        }}
        color="primary"
      >
        Edit
      </Button>
    </ActionButtonGroup>
  )
}
