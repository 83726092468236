import { useFormContext } from 'react-hook-form'

import { FormHelperText } from '@mui/material'

import { AppLink, useEntitlement } from '@tk/frontend/primitives'

import { RecordEditDto } from './types'
import { useReferenceDataRuleConfig } from './useReferenceDataRule'

export function RecordAssetTypeSelectHelperText() {
  const { watch } = useFormContext<RecordEditDto>()
  const rule = useReferenceDataRuleConfig(watch('assetType.id'))
  const canViewReferenceDataRule = useEntitlement('reference-data-rules.read')

  if (!canViewReferenceDataRule || !rule?.id) {
    return null
  }

  return (
    <FormHelperText>
      <b>Note:</b> Fields in this form will be displayed based on the validation
      rule which has been associated with this asset type. If this appears to be
      incorrect, please check the rule configuration{' '}
      <AppLink
        to="/reference-data/rule/edit/$id"
        params={{ id: rule.id }}
        target="_blank"
      >
        here
      </AppLink>
      .
    </FormHelperText>
  )
}
