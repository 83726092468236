import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { keepPreviousData } from '@tanstack/react-query'

import { trpc } from '@tk/frontend/api'

import {
  RecordEditDto,
  RecordReferenceDataField,
  referenceDataConfigFields,
} from './types'

interface UseReferenceDataRuleHiddenFieldsArgs {
  assetTypeId: number
  type: 'ITEM' | 'CHAIN' | 'CHAIN_ITEM' | undefined
}

export const useReferenceDataRuleConfig = (assetTypeId: number) => {
  const { data } = trpc.reference.assetType.get.useQuery(
    { id: assetTypeId },
    {
      enabled: typeof assetTypeId === 'number',
      select: ({ referenceDataRule }) => referenceDataRule,
      placeholderData: keepPreviousData,
    }
  )

  return assetTypeId ? data : undefined
}

export const useReferenceDataRuleHiddenFields = ({
  assetTypeId,
  type,
}: UseReferenceDataRuleHiddenFieldsArgs): RecordReferenceDataField[] => {
  const config = useReferenceDataRuleConfig(assetTypeId)

  return useMemo(() => {
    if (!config || type === 'CHAIN') {
      return []
    }

    return referenceDataConfigFields
      .filter((field) => config[field] === 'HIDDEN')
      .map((field) => field.replace('Mode', '')) as RecordReferenceDataField[]
  }, [config, type])
}

export const useReferenceDataRuleRequiredFields =
  (): Set<RecordReferenceDataField> => {
    const { watch } = useFormContext<RecordEditDto>()

    const assetTypeId = watch('assetType.id')
    const type = watch('type')

    const config = useReferenceDataRuleConfig(assetTypeId)

    return useMemo(() => {
      if (!config || type === 'CHAIN') {
        return new Set()
      }

      return new Set(
        referenceDataConfigFields
          .filter((field) => config[field] === 'REQUIRED')
          .map((field) => field.replace('Mode', ''))
      ) as Set<RecordReferenceDataField>
    }, [config, type])
  }
