/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SignOutImport } from './routes/sign-out'
import { Route as ReferenceDataRouteImport } from './routes/reference-data/route'
import { Route as RecordManagementRouteImport } from './routes/record-management/route'
import { Route as RecordDesignRouteImport } from './routes/record-design/route'
import { Route as MultibrandRouteImport } from './routes/multibrand/route'
import { Route as EntitlementsRouteImport } from './routes/entitlements/route'
import { Route as BloombergRouteImport } from './routes/bloomberg/route'
import { Route as AdminRouteImport } from './routes/admin/route'
import { Route as RouteImport } from './routes/route'
import { Route as ReferenceDataSettlementIndexImport } from './routes/reference-data/settlement-index'
import { Route as ReferenceDataSubscriptionsImport } from './routes/reference-data/subscriptions'
import { Route as ReferenceDataStandardDiscountingsImport } from './routes/reference-data/standard-discountings'
import { Route as ReferenceDataSourcesImport } from './routes/reference-data/sources'
import { Route as ReferenceDataSettlementMethodsImport } from './routes/reference-data/settlement-methods'
import { Route as ReferenceDataPricingFrequenciesImport } from './routes/reference-data/pricing-frequencies'
import { Route as ReferenceDataPriceTypesImport } from './routes/reference-data/price-types'
import { Route as ReferenceDataPeriodsImport } from './routes/reference-data/periods'
import { Route as ReferenceDataLocationsImport } from './routes/reference-data/locations'
import { Route as ReferenceDataFrequenciesImport } from './routes/reference-data/frequencies'
import { Route as ReferenceDataDayCountsImport } from './routes/reference-data/day-counts'
import { Route as ReferenceDataCurrenciesImport } from './routes/reference-data/currencies'
import { Route as ReferenceDataCompaniesImport } from './routes/reference-data/companies'
import { Route as ReferenceDataClearingTypesImport } from './routes/reference-data/clearing-types'
import { Route as LoginCallbackImport } from './routes/login.callback'
import { Route as FidsListImport } from './routes/fids/list'
import { Route as BloombergRulesetsImport } from './routes/bloomberg/rulesets'
import { Route as BloombergProcessesImport } from './routes/bloomberg/processes'
import { Route as BloombergPricingSourcesImport } from './routes/bloomberg/pricing-sources'
import { Route as BloombergEntitlementsImport } from './routes/bloomberg/entitlements'
import { Route as ReferenceDataRuleRouteImport } from './routes/reference-data/rule/route'
import { Route as RecordManagementCommercialPackagesRouteImport } from './routes/record-management/commercial-packages/route'
import { Route as ExtractaReportsRouteImport } from './routes/extracta/reports/route'
import { Route as ExtractaCreateRouteImport } from './routes/extracta/create/route'
import { Route as ReferenceDataRuleIndexImport } from './routes/reference-data/rule/index'
import { Route as ReferenceDataAssetTypesIndexImport } from './routes/reference-data/asset-types/index'
import { Route as RecordManagementRecordsIndexImport } from './routes/record-management/records/index'
import { Route as RecordManagementCommercialPackagesIndexImport } from './routes/record-management/commercial-packages/index'
import { Route as MultibrandStubsIndexImport } from './routes/multibrand/stubs/index'
import { Route as MultibrandRootRecordNameStructureIndexImport } from './routes/multibrand/root-record-name-structure/index'
import { Route as MultibrandAttributeIndexImport } from './routes/multibrand/attribute/index'
import { Route as FidsGroupsIndexImport } from './routes/fids/groups/index'
import { Route as ExtractaCreateIndexImport } from './routes/extracta/create/index'
import { Route as BloombergTickersIndexImport } from './routes/bloomberg/tickers/index'
import { Route as BloombergRecordsMappingsIndexImport } from './routes/bloomberg/records-mappings/index'
import { Route as ReferenceDataRuleCreateImport } from './routes/reference-data/rule/create'
import { Route as RecordManagementRecordsExportsImport } from './routes/record-management/records/exports'
import { Route as RecordManagementRecordsCreateImport } from './routes/record-management/records/create'
import { Route as RecordDesignProjectIdImport } from './routes/record-design/project/$id'
import { Route as MultibrandRootRecordNameStructureCreateImport } from './routes/multibrand/root-record-name-structure/create'
import { Route as ExtractaReportsIdImport } from './routes/extracta/reports/$id'
import { Route as RecordManagementRecordsRelinkRouteImport } from './routes/record-management/records/relink/route'
import { Route as RecordManagementRecordsImportsRouteImport } from './routes/record-management/records/imports/route'
import { Route as BloombergTickersImportRouteImport } from './routes/bloomberg/tickers/import/route'
import { Route as BloombergRecordsMappingsImportRouteImport } from './routes/bloomberg/records-mappings/import/route'
import { Route as RecordManagementRecordsRelinkIndexImport } from './routes/record-management/records/relink/index'
import { Route as RecordManagementRecordsImportsIndexImport } from './routes/record-management/records/imports/index'
import { Route as ReferenceDataRuleEditIdImport } from './routes/reference-data/rule/edit.$id'
import { Route as ReferenceDataAssetTypesEditIdImport } from './routes/reference-data/asset-types/edit.$id'
import { Route as ReferenceDataAssetTypesCreateIdImport } from './routes/reference-data/asset-types/create.$id'
import { Route as RecordManagementRecordsUnlinkIdImport } from './routes/record-management/records/unlink.$id'
import { Route as RecordManagementRecordsRelinkIdImport } from './routes/record-management/records/relink/$id'
import { Route as RecordManagementRecordsImportsIdImport } from './routes/record-management/records/imports/$id'
import { Route as RecordManagementRecordsEditIdImport } from './routes/record-management/records/edit.$id'
import { Route as MultibrandStubsInstrumentCreateImport } from './routes/multibrand/stubs/instrument/create'
import { Route as MultibrandRootRecordNameStructureEditIdImport } from './routes/multibrand/root-record-name-structure/edit.$id'
import { Route as FidsGroupsEditIdImport } from './routes/fids/groups/edit.$id'
import { Route as ExtractaReportsIdCloneImport } from './routes/extracta/reports/$id_.clone'
import { Route as EntitlementsRulesetEditIdImport } from './routes/entitlements/ruleset/edit.$id'
import { Route as BloombergTickersImportTaskIdImport } from './routes/bloomberg/tickers/import/$taskId'
import { Route as BloombergRecordsMappingsImportTaskIdImport } from './routes/bloomberg/records-mappings/import/$taskId'
import { Route as MultibrandStubsInstrumentEditIdImport } from './routes/multibrand/stubs/instrument/edit.$id'

// Create Virtual Routes

const RecordDesignStartLazyImport = createFileRoute('/record-design/start')()
const EntitlementsDistributionLazyImport = createFileRoute(
  '/entitlements/distribution',
)()
const EntitlementsActorLazyImport = createFileRoute('/entitlements/actor')()
const AdminUserRolesLazyImport = createFileRoute('/admin/user-roles')()
const AdminRolesLazyImport = createFileRoute('/admin/roles')()
const AdminApiUsersLazyImport = createFileRoute('/admin/api-users')()
const ExtractaReportsIndexLazyImport = createFileRoute('/extracta/reports/')()
const EntitlementsRulesetIndexLazyImport = createFileRoute(
  '/entitlements/ruleset/',
)()
const RecordDesignStartSlugLazyImport = createFileRoute(
  '/record-design/start/$slug',
)()
const FidsGroupsCreateLazyImport = createFileRoute('/fids/groups/create')()
const EntitlementsRulesetCreateLazyImport = createFileRoute(
  '/entitlements/ruleset/create',
)()

// Create/Update Routes

const SignOutRoute = SignOutImport.update({
  id: '/sign-out',
  path: '/sign-out',
  getParentRoute: () => rootRoute,
} as any)

const ReferenceDataRouteRoute = ReferenceDataRouteImport.update({
  id: '/reference-data',
  path: '/reference-data',
  getParentRoute: () => rootRoute,
} as any)

const RecordManagementRouteRoute = RecordManagementRouteImport.update({
  id: '/record-management',
  path: '/record-management',
  getParentRoute: () => rootRoute,
} as any)

const RecordDesignRouteRoute = RecordDesignRouteImport.update({
  id: '/record-design',
  path: '/record-design',
  getParentRoute: () => rootRoute,
} as any)

const MultibrandRouteRoute = MultibrandRouteImport.update({
  id: '/multibrand',
  path: '/multibrand',
  getParentRoute: () => rootRoute,
} as any)

const EntitlementsRouteRoute = EntitlementsRouteImport.update({
  id: '/entitlements',
  path: '/entitlements',
  getParentRoute: () => rootRoute,
} as any)

const BloombergRouteRoute = BloombergRouteImport.update({
  id: '/bloomberg',
  path: '/bloomberg',
  getParentRoute: () => rootRoute,
} as any)

const AdminRouteRoute = AdminRouteImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any)

const RouteRoute = RouteImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ReferenceDataSettlementIndexRoute =
  ReferenceDataSettlementIndexImport.update({
    id: '/settlement-index',
    path: '/settlement-index',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any)

const RecordDesignStartLazyRoute = RecordDesignStartLazyImport.update({
  id: '/start',
  path: '/start',
  getParentRoute: () => RecordDesignRouteRoute,
} as any).lazy(() =>
  import('./routes/record-design/start.lazy').then((d) => d.Route),
)

const EntitlementsDistributionLazyRoute =
  EntitlementsDistributionLazyImport.update({
    id: '/distribution',
    path: '/distribution',
    getParentRoute: () => EntitlementsRouteRoute,
  } as any).lazy(() =>
    import('./routes/entitlements/distribution.lazy').then((d) => d.Route),
  )

const EntitlementsActorLazyRoute = EntitlementsActorLazyImport.update({
  id: '/actor',
  path: '/actor',
  getParentRoute: () => EntitlementsRouteRoute,
} as any).lazy(() =>
  import('./routes/entitlements/actor.lazy').then((d) => d.Route),
)

const AdminUserRolesLazyRoute = AdminUserRolesLazyImport.update({
  id: '/user-roles',
  path: '/user-roles',
  getParentRoute: () => AdminRouteRoute,
} as any).lazy(() =>
  import('./routes/admin/user-roles.lazy').then((d) => d.Route),
)

const AdminRolesLazyRoute = AdminRolesLazyImport.update({
  id: '/roles',
  path: '/roles',
  getParentRoute: () => AdminRouteRoute,
} as any).lazy(() => import('./routes/admin/roles.lazy').then((d) => d.Route))

const AdminApiUsersLazyRoute = AdminApiUsersLazyImport.update({
  id: '/api-users',
  path: '/api-users',
  getParentRoute: () => AdminRouteRoute,
} as any).lazy(() =>
  import('./routes/admin/api-users.lazy').then((d) => d.Route),
)

const ReferenceDataSubscriptionsRoute = ReferenceDataSubscriptionsImport.update(
  {
    id: '/subscriptions',
    path: '/subscriptions',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any,
)

const ReferenceDataStandardDiscountingsRoute =
  ReferenceDataStandardDiscountingsImport.update({
    id: '/standard-discountings',
    path: '/standard-discountings',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any)

const ReferenceDataSourcesRoute = ReferenceDataSourcesImport.update({
  id: '/sources',
  path: '/sources',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const ReferenceDataSettlementMethodsRoute =
  ReferenceDataSettlementMethodsImport.update({
    id: '/settlement-methods',
    path: '/settlement-methods',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any)

const ReferenceDataPricingFrequenciesRoute =
  ReferenceDataPricingFrequenciesImport.update({
    id: '/pricing-frequencies',
    path: '/pricing-frequencies',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any)

const ReferenceDataPriceTypesRoute = ReferenceDataPriceTypesImport.update({
  id: '/price-types',
  path: '/price-types',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const ReferenceDataPeriodsRoute = ReferenceDataPeriodsImport.update({
  id: '/periods',
  path: '/periods',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const ReferenceDataLocationsRoute = ReferenceDataLocationsImport.update({
  id: '/locations',
  path: '/locations',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const ReferenceDataFrequenciesRoute = ReferenceDataFrequenciesImport.update({
  id: '/frequencies',
  path: '/frequencies',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const ReferenceDataDayCountsRoute = ReferenceDataDayCountsImport.update({
  id: '/day-counts',
  path: '/day-counts',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const ReferenceDataCurrenciesRoute = ReferenceDataCurrenciesImport.update({
  id: '/currencies',
  path: '/currencies',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const ReferenceDataCompaniesRoute = ReferenceDataCompaniesImport.update({
  id: '/companies',
  path: '/companies',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const ReferenceDataClearingTypesRoute = ReferenceDataClearingTypesImport.update(
  {
    id: '/clearing-types',
    path: '/clearing-types',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any,
)

const LoginCallbackRoute = LoginCallbackImport.update({
  id: '/login/callback',
  path: '/login/callback',
  getParentRoute: () => rootRoute,
} as any)

const FidsListRoute = FidsListImport.update({
  id: '/fids/list',
  path: '/fids/list',
  getParentRoute: () => rootRoute,
} as any)

const BloombergRulesetsRoute = BloombergRulesetsImport.update({
  id: '/rulesets',
  path: '/rulesets',
  getParentRoute: () => BloombergRouteRoute,
} as any)

const BloombergProcessesRoute = BloombergProcessesImport.update({
  id: '/processes',
  path: '/processes',
  getParentRoute: () => BloombergRouteRoute,
} as any)

const BloombergPricingSourcesRoute = BloombergPricingSourcesImport.update({
  id: '/pricing-sources',
  path: '/pricing-sources',
  getParentRoute: () => BloombergRouteRoute,
} as any)

const BloombergEntitlementsRoute = BloombergEntitlementsImport.update({
  id: '/entitlements',
  path: '/entitlements',
  getParentRoute: () => BloombergRouteRoute,
} as any)

const ReferenceDataRuleRouteRoute = ReferenceDataRuleRouteImport.update({
  id: '/rule',
  path: '/rule',
  getParentRoute: () => ReferenceDataRouteRoute,
} as any)

const RecordManagementCommercialPackagesRouteRoute =
  RecordManagementCommercialPackagesRouteImport.update({
    id: '/commercial-packages',
    path: '/commercial-packages',
    getParentRoute: () => RecordManagementRouteRoute,
  } as any)

const ExtractaReportsRouteRoute = ExtractaReportsRouteImport.update({
  id: '/extracta/reports',
  path: '/extracta/reports',
  getParentRoute: () => rootRoute,
} as any)

const ExtractaCreateRouteRoute = ExtractaCreateRouteImport.update({
  id: '/extracta/create',
  path: '/extracta/create',
  getParentRoute: () => rootRoute,
} as any)

const ExtractaReportsIndexLazyRoute = ExtractaReportsIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ExtractaReportsRouteRoute,
} as any).lazy(() =>
  import('./routes/extracta/reports/index.lazy').then((d) => d.Route),
)

const EntitlementsRulesetIndexLazyRoute =
  EntitlementsRulesetIndexLazyImport.update({
    id: '/ruleset/',
    path: '/ruleset/',
    getParentRoute: () => EntitlementsRouteRoute,
  } as any).lazy(() =>
    import('./routes/entitlements/ruleset/index.lazy').then((d) => d.Route),
  )

const ReferenceDataRuleIndexRoute = ReferenceDataRuleIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ReferenceDataRuleRouteRoute,
} as any)

const ReferenceDataAssetTypesIndexRoute =
  ReferenceDataAssetTypesIndexImport.update({
    id: '/asset-types/',
    path: '/asset-types/',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any)

const RecordManagementRecordsIndexRoute =
  RecordManagementRecordsIndexImport.update({
    id: '/records/',
    path: '/records/',
    getParentRoute: () => RecordManagementRouteRoute,
  } as any)

const RecordManagementCommercialPackagesIndexRoute =
  RecordManagementCommercialPackagesIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => RecordManagementCommercialPackagesRouteRoute,
  } as any)

const MultibrandStubsIndexRoute = MultibrandStubsIndexImport.update({
  id: '/stubs/',
  path: '/stubs/',
  getParentRoute: () => MultibrandRouteRoute,
} as any)

const MultibrandRootRecordNameStructureIndexRoute =
  MultibrandRootRecordNameStructureIndexImport.update({
    id: '/root-record-name-structure/',
    path: '/root-record-name-structure/',
    getParentRoute: () => MultibrandRouteRoute,
  } as any)

const MultibrandAttributeIndexRoute = MultibrandAttributeIndexImport.update({
  id: '/attribute/',
  path: '/attribute/',
  getParentRoute: () => MultibrandRouteRoute,
} as any)

const FidsGroupsIndexRoute = FidsGroupsIndexImport.update({
  id: '/fids/groups/',
  path: '/fids/groups/',
  getParentRoute: () => rootRoute,
} as any)

const ExtractaCreateIndexRoute = ExtractaCreateIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ExtractaCreateRouteRoute,
} as any).lazy(() =>
  import('./routes/extracta/create/index.lazy').then((d) => d.Route),
)

const BloombergTickersIndexRoute = BloombergTickersIndexImport.update({
  id: '/tickers/',
  path: '/tickers/',
  getParentRoute: () => BloombergRouteRoute,
} as any)

const BloombergRecordsMappingsIndexRoute =
  BloombergRecordsMappingsIndexImport.update({
    id: '/records-mappings/',
    path: '/records-mappings/',
    getParentRoute: () => BloombergRouteRoute,
  } as any)

const RecordDesignStartSlugLazyRoute = RecordDesignStartSlugLazyImport.update({
  id: '/$slug',
  path: '/$slug',
  getParentRoute: () => RecordDesignStartLazyRoute,
} as any).lazy(() =>
  import('./routes/record-design/start.$slug.lazy').then((d) => d.Route),
)

const FidsGroupsCreateLazyRoute = FidsGroupsCreateLazyImport.update({
  id: '/fids/groups/create',
  path: '/fids/groups/create',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/fids/groups/create.lazy').then((d) => d.Route),
)

const EntitlementsRulesetCreateLazyRoute =
  EntitlementsRulesetCreateLazyImport.update({
    id: '/ruleset/create',
    path: '/ruleset/create',
    getParentRoute: () => EntitlementsRouteRoute,
  } as any).lazy(() =>
    import('./routes/entitlements/ruleset/create.lazy').then((d) => d.Route),
  )

const ReferenceDataRuleCreateRoute = ReferenceDataRuleCreateImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => ReferenceDataRuleRouteRoute,
} as any)

const RecordManagementRecordsExportsRoute =
  RecordManagementRecordsExportsImport.update({
    id: '/records/exports',
    path: '/records/exports',
    getParentRoute: () => RecordManagementRouteRoute,
  } as any)

const RecordManagementRecordsCreateRoute =
  RecordManagementRecordsCreateImport.update({
    id: '/records/create',
    path: '/records/create',
    getParentRoute: () => RecordManagementRouteRoute,
  } as any)

const RecordDesignProjectIdRoute = RecordDesignProjectIdImport.update({
  id: '/project/$id',
  path: '/project/$id',
  getParentRoute: () => RecordDesignRouteRoute,
} as any).lazy(() =>
  import('./routes/record-design/project/$id.lazy').then((d) => d.Route),
)

const MultibrandRootRecordNameStructureCreateRoute =
  MultibrandRootRecordNameStructureCreateImport.update({
    id: '/root-record-name-structure/create',
    path: '/root-record-name-structure/create',
    getParentRoute: () => MultibrandRouteRoute,
  } as any)

const ExtractaReportsIdRoute = ExtractaReportsIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => ExtractaReportsRouteRoute,
} as any)

const RecordManagementRecordsRelinkRouteRoute =
  RecordManagementRecordsRelinkRouteImport.update({
    id: '/records/relink',
    path: '/records/relink',
    getParentRoute: () => RecordManagementRouteRoute,
  } as any)

const RecordManagementRecordsImportsRouteRoute =
  RecordManagementRecordsImportsRouteImport.update({
    id: '/records/imports',
    path: '/records/imports',
    getParentRoute: () => RecordManagementRouteRoute,
  } as any)

const BloombergTickersImportRouteRoute =
  BloombergTickersImportRouteImport.update({
    id: '/tickers/import',
    path: '/tickers/import',
    getParentRoute: () => BloombergRouteRoute,
  } as any)

const BloombergRecordsMappingsImportRouteRoute =
  BloombergRecordsMappingsImportRouteImport.update({
    id: '/records-mappings/import',
    path: '/records-mappings/import',
    getParentRoute: () => BloombergRouteRoute,
  } as any)

const RecordManagementRecordsRelinkIndexRoute =
  RecordManagementRecordsRelinkIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => RecordManagementRecordsRelinkRouteRoute,
  } as any).lazy(() =>
    import('./routes/record-management/records/relink/index.lazy').then(
      (d) => d.Route,
    ),
  )

const RecordManagementRecordsImportsIndexRoute =
  RecordManagementRecordsImportsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => RecordManagementRecordsImportsRouteRoute,
  } as any).lazy(() =>
    import('./routes/record-management/records/imports/index.lazy').then(
      (d) => d.Route,
    ),
  )

const ReferenceDataRuleEditIdRoute = ReferenceDataRuleEditIdImport.update({
  id: '/edit/$id',
  path: '/edit/$id',
  getParentRoute: () => ReferenceDataRuleRouteRoute,
} as any)

const ReferenceDataAssetTypesEditIdRoute =
  ReferenceDataAssetTypesEditIdImport.update({
    id: '/asset-types/edit/$id',
    path: '/asset-types/edit/$id',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any)

const ReferenceDataAssetTypesCreateIdRoute =
  ReferenceDataAssetTypesCreateIdImport.update({
    id: '/asset-types/create/$id',
    path: '/asset-types/create/$id',
    getParentRoute: () => ReferenceDataRouteRoute,
  } as any)

const RecordManagementRecordsUnlinkIdRoute =
  RecordManagementRecordsUnlinkIdImport.update({
    id: '/records/unlink/$id',
    path: '/records/unlink/$id',
    getParentRoute: () => RecordManagementRouteRoute,
  } as any)

const RecordManagementRecordsRelinkIdRoute =
  RecordManagementRecordsRelinkIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => RecordManagementRecordsRelinkRouteRoute,
  } as any).lazy(() =>
    import('./routes/record-management/records/relink/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const RecordManagementRecordsImportsIdRoute =
  RecordManagementRecordsImportsIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => RecordManagementRecordsImportsRouteRoute,
  } as any).lazy(() =>
    import('./routes/record-management/records/imports/$id.lazy').then(
      (d) => d.Route,
    ),
  )

const RecordManagementRecordsEditIdRoute =
  RecordManagementRecordsEditIdImport.update({
    id: '/records/edit/$id',
    path: '/records/edit/$id',
    getParentRoute: () => RecordManagementRouteRoute,
  } as any)

const MultibrandStubsInstrumentCreateRoute =
  MultibrandStubsInstrumentCreateImport.update({
    id: '/stubs/instrument/create',
    path: '/stubs/instrument/create',
    getParentRoute: () => MultibrandRouteRoute,
  } as any)

const MultibrandRootRecordNameStructureEditIdRoute =
  MultibrandRootRecordNameStructureEditIdImport.update({
    id: '/root-record-name-structure/edit/$id',
    path: '/root-record-name-structure/edit/$id',
    getParentRoute: () => MultibrandRouteRoute,
  } as any)

const FidsGroupsEditIdRoute = FidsGroupsEditIdImport.update({
  id: '/fids/groups/edit/$id',
  path: '/fids/groups/edit/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/fids/groups/edit.$id.lazy').then((d) => d.Route),
)

const ExtractaReportsIdCloneRoute = ExtractaReportsIdCloneImport.update({
  id: '/$id_/clone',
  path: '/$id/clone',
  getParentRoute: () => ExtractaReportsRouteRoute,
} as any)

const EntitlementsRulesetEditIdRoute = EntitlementsRulesetEditIdImport.update({
  id: '/ruleset/edit/$id',
  path: '/ruleset/edit/$id',
  getParentRoute: () => EntitlementsRouteRoute,
} as any).lazy(() =>
  import('./routes/entitlements/ruleset/edit.$id.lazy').then((d) => d.Route),
)

const BloombergTickersImportTaskIdRoute =
  BloombergTickersImportTaskIdImport.update({
    id: '/$taskId',
    path: '/$taskId',
    getParentRoute: () => BloombergTickersImportRouteRoute,
  } as any)

const BloombergRecordsMappingsImportTaskIdRoute =
  BloombergRecordsMappingsImportTaskIdImport.update({
    id: '/$taskId',
    path: '/$taskId',
    getParentRoute: () => BloombergRecordsMappingsImportRouteRoute,
  } as any).lazy(() =>
    import('./routes/bloomberg/records-mappings/import/$taskId.lazy').then(
      (d) => d.Route,
    ),
  )

const MultibrandStubsInstrumentEditIdRoute =
  MultibrandStubsInstrumentEditIdImport.update({
    id: '/stubs/instrument/edit/$id',
    path: '/stubs/instrument/edit/$id',
    getParentRoute: () => MultibrandRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof RouteImport
      parentRoute: typeof rootRoute
    }
    '/admin': {
      id: '/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminRouteImport
      parentRoute: typeof rootRoute
    }
    '/bloomberg': {
      id: '/bloomberg'
      path: '/bloomberg'
      fullPath: '/bloomberg'
      preLoaderRoute: typeof BloombergRouteImport
      parentRoute: typeof rootRoute
    }
    '/entitlements': {
      id: '/entitlements'
      path: '/entitlements'
      fullPath: '/entitlements'
      preLoaderRoute: typeof EntitlementsRouteImport
      parentRoute: typeof rootRoute
    }
    '/multibrand': {
      id: '/multibrand'
      path: '/multibrand'
      fullPath: '/multibrand'
      preLoaderRoute: typeof MultibrandRouteImport
      parentRoute: typeof rootRoute
    }
    '/record-design': {
      id: '/record-design'
      path: '/record-design'
      fullPath: '/record-design'
      preLoaderRoute: typeof RecordDesignRouteImport
      parentRoute: typeof rootRoute
    }
    '/record-management': {
      id: '/record-management'
      path: '/record-management'
      fullPath: '/record-management'
      preLoaderRoute: typeof RecordManagementRouteImport
      parentRoute: typeof rootRoute
    }
    '/reference-data': {
      id: '/reference-data'
      path: '/reference-data'
      fullPath: '/reference-data'
      preLoaderRoute: typeof ReferenceDataRouteImport
      parentRoute: typeof rootRoute
    }
    '/sign-out': {
      id: '/sign-out'
      path: '/sign-out'
      fullPath: '/sign-out'
      preLoaderRoute: typeof SignOutImport
      parentRoute: typeof rootRoute
    }
    '/extracta/create': {
      id: '/extracta/create'
      path: '/extracta/create'
      fullPath: '/extracta/create'
      preLoaderRoute: typeof ExtractaCreateRouteImport
      parentRoute: typeof rootRoute
    }
    '/extracta/reports': {
      id: '/extracta/reports'
      path: '/extracta/reports'
      fullPath: '/extracta/reports'
      preLoaderRoute: typeof ExtractaReportsRouteImport
      parentRoute: typeof rootRoute
    }
    '/record-management/commercial-packages': {
      id: '/record-management/commercial-packages'
      path: '/commercial-packages'
      fullPath: '/record-management/commercial-packages'
      preLoaderRoute: typeof RecordManagementCommercialPackagesRouteImport
      parentRoute: typeof RecordManagementRouteImport
    }
    '/reference-data/rule': {
      id: '/reference-data/rule'
      path: '/rule'
      fullPath: '/reference-data/rule'
      preLoaderRoute: typeof ReferenceDataRuleRouteImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/bloomberg/entitlements': {
      id: '/bloomberg/entitlements'
      path: '/entitlements'
      fullPath: '/bloomberg/entitlements'
      preLoaderRoute: typeof BloombergEntitlementsImport
      parentRoute: typeof BloombergRouteImport
    }
    '/bloomberg/pricing-sources': {
      id: '/bloomberg/pricing-sources'
      path: '/pricing-sources'
      fullPath: '/bloomberg/pricing-sources'
      preLoaderRoute: typeof BloombergPricingSourcesImport
      parentRoute: typeof BloombergRouteImport
    }
    '/bloomberg/processes': {
      id: '/bloomberg/processes'
      path: '/processes'
      fullPath: '/bloomberg/processes'
      preLoaderRoute: typeof BloombergProcessesImport
      parentRoute: typeof BloombergRouteImport
    }
    '/bloomberg/rulesets': {
      id: '/bloomberg/rulesets'
      path: '/rulesets'
      fullPath: '/bloomberg/rulesets'
      preLoaderRoute: typeof BloombergRulesetsImport
      parentRoute: typeof BloombergRouteImport
    }
    '/fids/list': {
      id: '/fids/list'
      path: '/fids/list'
      fullPath: '/fids/list'
      preLoaderRoute: typeof FidsListImport
      parentRoute: typeof rootRoute
    }
    '/login/callback': {
      id: '/login/callback'
      path: '/login/callback'
      fullPath: '/login/callback'
      preLoaderRoute: typeof LoginCallbackImport
      parentRoute: typeof rootRoute
    }
    '/reference-data/clearing-types': {
      id: '/reference-data/clearing-types'
      path: '/clearing-types'
      fullPath: '/reference-data/clearing-types'
      preLoaderRoute: typeof ReferenceDataClearingTypesImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/companies': {
      id: '/reference-data/companies'
      path: '/companies'
      fullPath: '/reference-data/companies'
      preLoaderRoute: typeof ReferenceDataCompaniesImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/currencies': {
      id: '/reference-data/currencies'
      path: '/currencies'
      fullPath: '/reference-data/currencies'
      preLoaderRoute: typeof ReferenceDataCurrenciesImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/day-counts': {
      id: '/reference-data/day-counts'
      path: '/day-counts'
      fullPath: '/reference-data/day-counts'
      preLoaderRoute: typeof ReferenceDataDayCountsImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/frequencies': {
      id: '/reference-data/frequencies'
      path: '/frequencies'
      fullPath: '/reference-data/frequencies'
      preLoaderRoute: typeof ReferenceDataFrequenciesImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/locations': {
      id: '/reference-data/locations'
      path: '/locations'
      fullPath: '/reference-data/locations'
      preLoaderRoute: typeof ReferenceDataLocationsImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/periods': {
      id: '/reference-data/periods'
      path: '/periods'
      fullPath: '/reference-data/periods'
      preLoaderRoute: typeof ReferenceDataPeriodsImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/price-types': {
      id: '/reference-data/price-types'
      path: '/price-types'
      fullPath: '/reference-data/price-types'
      preLoaderRoute: typeof ReferenceDataPriceTypesImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/pricing-frequencies': {
      id: '/reference-data/pricing-frequencies'
      path: '/pricing-frequencies'
      fullPath: '/reference-data/pricing-frequencies'
      preLoaderRoute: typeof ReferenceDataPricingFrequenciesImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/settlement-index': {
      id: '/reference-data/settlement-index'
      path: '/settlement-index'
      fullPath: '/reference-data/settlement-index'
      preLoaderRoute: typeof ReferenceDataSettlementIndexImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/settlement-methods': {
      id: '/reference-data/settlement-methods'
      path: '/settlement-methods'
      fullPath: '/reference-data/settlement-methods'
      preLoaderRoute: typeof ReferenceDataSettlementMethodsImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/sources': {
      id: '/reference-data/sources'
      path: '/sources'
      fullPath: '/reference-data/sources'
      preLoaderRoute: typeof ReferenceDataSourcesImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/standard-discountings': {
      id: '/reference-data/standard-discountings'
      path: '/standard-discountings'
      fullPath: '/reference-data/standard-discountings'
      preLoaderRoute: typeof ReferenceDataStandardDiscountingsImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/subscriptions': {
      id: '/reference-data/subscriptions'
      path: '/subscriptions'
      fullPath: '/reference-data/subscriptions'
      preLoaderRoute: typeof ReferenceDataSubscriptionsImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/admin/api-users': {
      id: '/admin/api-users'
      path: '/api-users'
      fullPath: '/admin/api-users'
      preLoaderRoute: typeof AdminApiUsersLazyImport
      parentRoute: typeof AdminRouteImport
    }
    '/admin/roles': {
      id: '/admin/roles'
      path: '/roles'
      fullPath: '/admin/roles'
      preLoaderRoute: typeof AdminRolesLazyImport
      parentRoute: typeof AdminRouteImport
    }
    '/admin/user-roles': {
      id: '/admin/user-roles'
      path: '/user-roles'
      fullPath: '/admin/user-roles'
      preLoaderRoute: typeof AdminUserRolesLazyImport
      parentRoute: typeof AdminRouteImport
    }
    '/entitlements/actor': {
      id: '/entitlements/actor'
      path: '/actor'
      fullPath: '/entitlements/actor'
      preLoaderRoute: typeof EntitlementsActorLazyImport
      parentRoute: typeof EntitlementsRouteImport
    }
    '/entitlements/distribution': {
      id: '/entitlements/distribution'
      path: '/distribution'
      fullPath: '/entitlements/distribution'
      preLoaderRoute: typeof EntitlementsDistributionLazyImport
      parentRoute: typeof EntitlementsRouteImport
    }
    '/record-design/start': {
      id: '/record-design/start'
      path: '/start'
      fullPath: '/record-design/start'
      preLoaderRoute: typeof RecordDesignStartLazyImport
      parentRoute: typeof RecordDesignRouteImport
    }
    '/bloomberg/records-mappings/import': {
      id: '/bloomberg/records-mappings/import'
      path: '/records-mappings/import'
      fullPath: '/bloomberg/records-mappings/import'
      preLoaderRoute: typeof BloombergRecordsMappingsImportRouteImport
      parentRoute: typeof BloombergRouteImport
    }
    '/bloomberg/tickers/import': {
      id: '/bloomberg/tickers/import'
      path: '/tickers/import'
      fullPath: '/bloomberg/tickers/import'
      preLoaderRoute: typeof BloombergTickersImportRouteImport
      parentRoute: typeof BloombergRouteImport
    }
    '/record-management/records/imports': {
      id: '/record-management/records/imports'
      path: '/records/imports'
      fullPath: '/record-management/records/imports'
      preLoaderRoute: typeof RecordManagementRecordsImportsRouteImport
      parentRoute: typeof RecordManagementRouteImport
    }
    '/record-management/records/relink': {
      id: '/record-management/records/relink'
      path: '/records/relink'
      fullPath: '/record-management/records/relink'
      preLoaderRoute: typeof RecordManagementRecordsRelinkRouteImport
      parentRoute: typeof RecordManagementRouteImport
    }
    '/extracta/reports/$id': {
      id: '/extracta/reports/$id'
      path: '/$id'
      fullPath: '/extracta/reports/$id'
      preLoaderRoute: typeof ExtractaReportsIdImport
      parentRoute: typeof ExtractaReportsRouteImport
    }
    '/multibrand/root-record-name-structure/create': {
      id: '/multibrand/root-record-name-structure/create'
      path: '/root-record-name-structure/create'
      fullPath: '/multibrand/root-record-name-structure/create'
      preLoaderRoute: typeof MultibrandRootRecordNameStructureCreateImport
      parentRoute: typeof MultibrandRouteImport
    }
    '/record-design/project/$id': {
      id: '/record-design/project/$id'
      path: '/project/$id'
      fullPath: '/record-design/project/$id'
      preLoaderRoute: typeof RecordDesignProjectIdImport
      parentRoute: typeof RecordDesignRouteImport
    }
    '/record-management/records/create': {
      id: '/record-management/records/create'
      path: '/records/create'
      fullPath: '/record-management/records/create'
      preLoaderRoute: typeof RecordManagementRecordsCreateImport
      parentRoute: typeof RecordManagementRouteImport
    }
    '/record-management/records/exports': {
      id: '/record-management/records/exports'
      path: '/records/exports'
      fullPath: '/record-management/records/exports'
      preLoaderRoute: typeof RecordManagementRecordsExportsImport
      parentRoute: typeof RecordManagementRouteImport
    }
    '/reference-data/rule/create': {
      id: '/reference-data/rule/create'
      path: '/create'
      fullPath: '/reference-data/rule/create'
      preLoaderRoute: typeof ReferenceDataRuleCreateImport
      parentRoute: typeof ReferenceDataRuleRouteImport
    }
    '/entitlements/ruleset/create': {
      id: '/entitlements/ruleset/create'
      path: '/ruleset/create'
      fullPath: '/entitlements/ruleset/create'
      preLoaderRoute: typeof EntitlementsRulesetCreateLazyImport
      parentRoute: typeof EntitlementsRouteImport
    }
    '/fids/groups/create': {
      id: '/fids/groups/create'
      path: '/fids/groups/create'
      fullPath: '/fids/groups/create'
      preLoaderRoute: typeof FidsGroupsCreateLazyImport
      parentRoute: typeof rootRoute
    }
    '/record-design/start/$slug': {
      id: '/record-design/start/$slug'
      path: '/$slug'
      fullPath: '/record-design/start/$slug'
      preLoaderRoute: typeof RecordDesignStartSlugLazyImport
      parentRoute: typeof RecordDesignStartLazyImport
    }
    '/bloomberg/records-mappings/': {
      id: '/bloomberg/records-mappings/'
      path: '/records-mappings'
      fullPath: '/bloomberg/records-mappings'
      preLoaderRoute: typeof BloombergRecordsMappingsIndexImport
      parentRoute: typeof BloombergRouteImport
    }
    '/bloomberg/tickers/': {
      id: '/bloomberg/tickers/'
      path: '/tickers'
      fullPath: '/bloomberg/tickers'
      preLoaderRoute: typeof BloombergTickersIndexImport
      parentRoute: typeof BloombergRouteImport
    }
    '/extracta/create/': {
      id: '/extracta/create/'
      path: '/'
      fullPath: '/extracta/create/'
      preLoaderRoute: typeof ExtractaCreateIndexImport
      parentRoute: typeof ExtractaCreateRouteImport
    }
    '/fids/groups/': {
      id: '/fids/groups/'
      path: '/fids/groups'
      fullPath: '/fids/groups'
      preLoaderRoute: typeof FidsGroupsIndexImport
      parentRoute: typeof rootRoute
    }
    '/multibrand/attribute/': {
      id: '/multibrand/attribute/'
      path: '/attribute'
      fullPath: '/multibrand/attribute'
      preLoaderRoute: typeof MultibrandAttributeIndexImport
      parentRoute: typeof MultibrandRouteImport
    }
    '/multibrand/root-record-name-structure/': {
      id: '/multibrand/root-record-name-structure/'
      path: '/root-record-name-structure'
      fullPath: '/multibrand/root-record-name-structure'
      preLoaderRoute: typeof MultibrandRootRecordNameStructureIndexImport
      parentRoute: typeof MultibrandRouteImport
    }
    '/multibrand/stubs/': {
      id: '/multibrand/stubs/'
      path: '/stubs'
      fullPath: '/multibrand/stubs'
      preLoaderRoute: typeof MultibrandStubsIndexImport
      parentRoute: typeof MultibrandRouteImport
    }
    '/record-management/commercial-packages/': {
      id: '/record-management/commercial-packages/'
      path: '/'
      fullPath: '/record-management/commercial-packages/'
      preLoaderRoute: typeof RecordManagementCommercialPackagesIndexImport
      parentRoute: typeof RecordManagementCommercialPackagesRouteImport
    }
    '/record-management/records/': {
      id: '/record-management/records/'
      path: '/records'
      fullPath: '/record-management/records'
      preLoaderRoute: typeof RecordManagementRecordsIndexImport
      parentRoute: typeof RecordManagementRouteImport
    }
    '/reference-data/asset-types/': {
      id: '/reference-data/asset-types/'
      path: '/asset-types'
      fullPath: '/reference-data/asset-types'
      preLoaderRoute: typeof ReferenceDataAssetTypesIndexImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/rule/': {
      id: '/reference-data/rule/'
      path: '/'
      fullPath: '/reference-data/rule/'
      preLoaderRoute: typeof ReferenceDataRuleIndexImport
      parentRoute: typeof ReferenceDataRuleRouteImport
    }
    '/entitlements/ruleset/': {
      id: '/entitlements/ruleset/'
      path: '/ruleset'
      fullPath: '/entitlements/ruleset'
      preLoaderRoute: typeof EntitlementsRulesetIndexLazyImport
      parentRoute: typeof EntitlementsRouteImport
    }
    '/extracta/reports/': {
      id: '/extracta/reports/'
      path: '/'
      fullPath: '/extracta/reports/'
      preLoaderRoute: typeof ExtractaReportsIndexLazyImport
      parentRoute: typeof ExtractaReportsRouteImport
    }
    '/bloomberg/records-mappings/import/$taskId': {
      id: '/bloomberg/records-mappings/import/$taskId'
      path: '/$taskId'
      fullPath: '/bloomberg/records-mappings/import/$taskId'
      preLoaderRoute: typeof BloombergRecordsMappingsImportTaskIdImport
      parentRoute: typeof BloombergRecordsMappingsImportRouteImport
    }
    '/bloomberg/tickers/import/$taskId': {
      id: '/bloomberg/tickers/import/$taskId'
      path: '/$taskId'
      fullPath: '/bloomberg/tickers/import/$taskId'
      preLoaderRoute: typeof BloombergTickersImportTaskIdImport
      parentRoute: typeof BloombergTickersImportRouteImport
    }
    '/entitlements/ruleset/edit/$id': {
      id: '/entitlements/ruleset/edit/$id'
      path: '/ruleset/edit/$id'
      fullPath: '/entitlements/ruleset/edit/$id'
      preLoaderRoute: typeof EntitlementsRulesetEditIdImport
      parentRoute: typeof EntitlementsRouteImport
    }
    '/extracta/reports/$id_/clone': {
      id: '/extracta/reports/$id_/clone'
      path: '/$id/clone'
      fullPath: '/extracta/reports/$id/clone'
      preLoaderRoute: typeof ExtractaReportsIdCloneImport
      parentRoute: typeof ExtractaReportsRouteImport
    }
    '/fids/groups/edit/$id': {
      id: '/fids/groups/edit/$id'
      path: '/fids/groups/edit/$id'
      fullPath: '/fids/groups/edit/$id'
      preLoaderRoute: typeof FidsGroupsEditIdImport
      parentRoute: typeof rootRoute
    }
    '/multibrand/root-record-name-structure/edit/$id': {
      id: '/multibrand/root-record-name-structure/edit/$id'
      path: '/root-record-name-structure/edit/$id'
      fullPath: '/multibrand/root-record-name-structure/edit/$id'
      preLoaderRoute: typeof MultibrandRootRecordNameStructureEditIdImport
      parentRoute: typeof MultibrandRouteImport
    }
    '/multibrand/stubs/instrument/create': {
      id: '/multibrand/stubs/instrument/create'
      path: '/stubs/instrument/create'
      fullPath: '/multibrand/stubs/instrument/create'
      preLoaderRoute: typeof MultibrandStubsInstrumentCreateImport
      parentRoute: typeof MultibrandRouteImport
    }
    '/record-management/records/edit/$id': {
      id: '/record-management/records/edit/$id'
      path: '/records/edit/$id'
      fullPath: '/record-management/records/edit/$id'
      preLoaderRoute: typeof RecordManagementRecordsEditIdImport
      parentRoute: typeof RecordManagementRouteImport
    }
    '/record-management/records/imports/$id': {
      id: '/record-management/records/imports/$id'
      path: '/$id'
      fullPath: '/record-management/records/imports/$id'
      preLoaderRoute: typeof RecordManagementRecordsImportsIdImport
      parentRoute: typeof RecordManagementRecordsImportsRouteImport
    }
    '/record-management/records/relink/$id': {
      id: '/record-management/records/relink/$id'
      path: '/$id'
      fullPath: '/record-management/records/relink/$id'
      preLoaderRoute: typeof RecordManagementRecordsRelinkIdImport
      parentRoute: typeof RecordManagementRecordsRelinkRouteImport
    }
    '/record-management/records/unlink/$id': {
      id: '/record-management/records/unlink/$id'
      path: '/records/unlink/$id'
      fullPath: '/record-management/records/unlink/$id'
      preLoaderRoute: typeof RecordManagementRecordsUnlinkIdImport
      parentRoute: typeof RecordManagementRouteImport
    }
    '/reference-data/asset-types/create/$id': {
      id: '/reference-data/asset-types/create/$id'
      path: '/asset-types/create/$id'
      fullPath: '/reference-data/asset-types/create/$id'
      preLoaderRoute: typeof ReferenceDataAssetTypesCreateIdImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/asset-types/edit/$id': {
      id: '/reference-data/asset-types/edit/$id'
      path: '/asset-types/edit/$id'
      fullPath: '/reference-data/asset-types/edit/$id'
      preLoaderRoute: typeof ReferenceDataAssetTypesEditIdImport
      parentRoute: typeof ReferenceDataRouteImport
    }
    '/reference-data/rule/edit/$id': {
      id: '/reference-data/rule/edit/$id'
      path: '/edit/$id'
      fullPath: '/reference-data/rule/edit/$id'
      preLoaderRoute: typeof ReferenceDataRuleEditIdImport
      parentRoute: typeof ReferenceDataRuleRouteImport
    }
    '/record-management/records/imports/': {
      id: '/record-management/records/imports/'
      path: '/'
      fullPath: '/record-management/records/imports/'
      preLoaderRoute: typeof RecordManagementRecordsImportsIndexImport
      parentRoute: typeof RecordManagementRecordsImportsRouteImport
    }
    '/record-management/records/relink/': {
      id: '/record-management/records/relink/'
      path: '/'
      fullPath: '/record-management/records/relink/'
      preLoaderRoute: typeof RecordManagementRecordsRelinkIndexImport
      parentRoute: typeof RecordManagementRecordsRelinkRouteImport
    }
    '/multibrand/stubs/instrument/edit/$id': {
      id: '/multibrand/stubs/instrument/edit/$id'
      path: '/stubs/instrument/edit/$id'
      fullPath: '/multibrand/stubs/instrument/edit/$id'
      preLoaderRoute: typeof MultibrandStubsInstrumentEditIdImport
      parentRoute: typeof MultibrandRouteImport
    }
  }
}

// Create and export the route tree

interface AdminRouteRouteChildren {
  AdminApiUsersLazyRoute: typeof AdminApiUsersLazyRoute
  AdminRolesLazyRoute: typeof AdminRolesLazyRoute
  AdminUserRolesLazyRoute: typeof AdminUserRolesLazyRoute
}

const AdminRouteRouteChildren: AdminRouteRouteChildren = {
  AdminApiUsersLazyRoute: AdminApiUsersLazyRoute,
  AdminRolesLazyRoute: AdminRolesLazyRoute,
  AdminUserRolesLazyRoute: AdminUserRolesLazyRoute,
}

const AdminRouteRouteWithChildren = AdminRouteRoute._addFileChildren(
  AdminRouteRouteChildren,
)

interface BloombergRecordsMappingsImportRouteRouteChildren {
  BloombergRecordsMappingsImportTaskIdRoute: typeof BloombergRecordsMappingsImportTaskIdRoute
}

const BloombergRecordsMappingsImportRouteRouteChildren: BloombergRecordsMappingsImportRouteRouteChildren =
  {
    BloombergRecordsMappingsImportTaskIdRoute:
      BloombergRecordsMappingsImportTaskIdRoute,
  }

const BloombergRecordsMappingsImportRouteRouteWithChildren =
  BloombergRecordsMappingsImportRouteRoute._addFileChildren(
    BloombergRecordsMappingsImportRouteRouteChildren,
  )

interface BloombergTickersImportRouteRouteChildren {
  BloombergTickersImportTaskIdRoute: typeof BloombergTickersImportTaskIdRoute
}

const BloombergTickersImportRouteRouteChildren: BloombergTickersImportRouteRouteChildren =
  {
    BloombergTickersImportTaskIdRoute: BloombergTickersImportTaskIdRoute,
  }

const BloombergTickersImportRouteRouteWithChildren =
  BloombergTickersImportRouteRoute._addFileChildren(
    BloombergTickersImportRouteRouteChildren,
  )

interface BloombergRouteRouteChildren {
  BloombergEntitlementsRoute: typeof BloombergEntitlementsRoute
  BloombergPricingSourcesRoute: typeof BloombergPricingSourcesRoute
  BloombergProcessesRoute: typeof BloombergProcessesRoute
  BloombergRulesetsRoute: typeof BloombergRulesetsRoute
  BloombergRecordsMappingsImportRouteRoute: typeof BloombergRecordsMappingsImportRouteRouteWithChildren
  BloombergTickersImportRouteRoute: typeof BloombergTickersImportRouteRouteWithChildren
  BloombergRecordsMappingsIndexRoute: typeof BloombergRecordsMappingsIndexRoute
  BloombergTickersIndexRoute: typeof BloombergTickersIndexRoute
}

const BloombergRouteRouteChildren: BloombergRouteRouteChildren = {
  BloombergEntitlementsRoute: BloombergEntitlementsRoute,
  BloombergPricingSourcesRoute: BloombergPricingSourcesRoute,
  BloombergProcessesRoute: BloombergProcessesRoute,
  BloombergRulesetsRoute: BloombergRulesetsRoute,
  BloombergRecordsMappingsImportRouteRoute:
    BloombergRecordsMappingsImportRouteRouteWithChildren,
  BloombergTickersImportRouteRoute:
    BloombergTickersImportRouteRouteWithChildren,
  BloombergRecordsMappingsIndexRoute: BloombergRecordsMappingsIndexRoute,
  BloombergTickersIndexRoute: BloombergTickersIndexRoute,
}

const BloombergRouteRouteWithChildren = BloombergRouteRoute._addFileChildren(
  BloombergRouteRouteChildren,
)

interface EntitlementsRouteRouteChildren {
  EntitlementsActorLazyRoute: typeof EntitlementsActorLazyRoute
  EntitlementsDistributionLazyRoute: typeof EntitlementsDistributionLazyRoute
  EntitlementsRulesetCreateLazyRoute: typeof EntitlementsRulesetCreateLazyRoute
  EntitlementsRulesetIndexLazyRoute: typeof EntitlementsRulesetIndexLazyRoute
  EntitlementsRulesetEditIdRoute: typeof EntitlementsRulesetEditIdRoute
}

const EntitlementsRouteRouteChildren: EntitlementsRouteRouteChildren = {
  EntitlementsActorLazyRoute: EntitlementsActorLazyRoute,
  EntitlementsDistributionLazyRoute: EntitlementsDistributionLazyRoute,
  EntitlementsRulesetCreateLazyRoute: EntitlementsRulesetCreateLazyRoute,
  EntitlementsRulesetIndexLazyRoute: EntitlementsRulesetIndexLazyRoute,
  EntitlementsRulesetEditIdRoute: EntitlementsRulesetEditIdRoute,
}

const EntitlementsRouteRouteWithChildren =
  EntitlementsRouteRoute._addFileChildren(EntitlementsRouteRouteChildren)

interface MultibrandRouteRouteChildren {
  MultibrandRootRecordNameStructureCreateRoute: typeof MultibrandRootRecordNameStructureCreateRoute
  MultibrandAttributeIndexRoute: typeof MultibrandAttributeIndexRoute
  MultibrandRootRecordNameStructureIndexRoute: typeof MultibrandRootRecordNameStructureIndexRoute
  MultibrandStubsIndexRoute: typeof MultibrandStubsIndexRoute
  MultibrandRootRecordNameStructureEditIdRoute: typeof MultibrandRootRecordNameStructureEditIdRoute
  MultibrandStubsInstrumentCreateRoute: typeof MultibrandStubsInstrumentCreateRoute
  MultibrandStubsInstrumentEditIdRoute: typeof MultibrandStubsInstrumentEditIdRoute
}

const MultibrandRouteRouteChildren: MultibrandRouteRouteChildren = {
  MultibrandRootRecordNameStructureCreateRoute:
    MultibrandRootRecordNameStructureCreateRoute,
  MultibrandAttributeIndexRoute: MultibrandAttributeIndexRoute,
  MultibrandRootRecordNameStructureIndexRoute:
    MultibrandRootRecordNameStructureIndexRoute,
  MultibrandStubsIndexRoute: MultibrandStubsIndexRoute,
  MultibrandRootRecordNameStructureEditIdRoute:
    MultibrandRootRecordNameStructureEditIdRoute,
  MultibrandStubsInstrumentCreateRoute: MultibrandStubsInstrumentCreateRoute,
  MultibrandStubsInstrumentEditIdRoute: MultibrandStubsInstrumentEditIdRoute,
}

const MultibrandRouteRouteWithChildren = MultibrandRouteRoute._addFileChildren(
  MultibrandRouteRouteChildren,
)

interface RecordDesignStartLazyRouteChildren {
  RecordDesignStartSlugLazyRoute: typeof RecordDesignStartSlugLazyRoute
}

const RecordDesignStartLazyRouteChildren: RecordDesignStartLazyRouteChildren = {
  RecordDesignStartSlugLazyRoute: RecordDesignStartSlugLazyRoute,
}

const RecordDesignStartLazyRouteWithChildren =
  RecordDesignStartLazyRoute._addFileChildren(
    RecordDesignStartLazyRouteChildren,
  )

interface RecordDesignRouteRouteChildren {
  RecordDesignStartLazyRoute: typeof RecordDesignStartLazyRouteWithChildren
  RecordDesignProjectIdRoute: typeof RecordDesignProjectIdRoute
}

const RecordDesignRouteRouteChildren: RecordDesignRouteRouteChildren = {
  RecordDesignStartLazyRoute: RecordDesignStartLazyRouteWithChildren,
  RecordDesignProjectIdRoute: RecordDesignProjectIdRoute,
}

const RecordDesignRouteRouteWithChildren =
  RecordDesignRouteRoute._addFileChildren(RecordDesignRouteRouteChildren)

interface RecordManagementCommercialPackagesRouteRouteChildren {
  RecordManagementCommercialPackagesIndexRoute: typeof RecordManagementCommercialPackagesIndexRoute
}

const RecordManagementCommercialPackagesRouteRouteChildren: RecordManagementCommercialPackagesRouteRouteChildren =
  {
    RecordManagementCommercialPackagesIndexRoute:
      RecordManagementCommercialPackagesIndexRoute,
  }

const RecordManagementCommercialPackagesRouteRouteWithChildren =
  RecordManagementCommercialPackagesRouteRoute._addFileChildren(
    RecordManagementCommercialPackagesRouteRouteChildren,
  )

interface RecordManagementRecordsImportsRouteRouteChildren {
  RecordManagementRecordsImportsIdRoute: typeof RecordManagementRecordsImportsIdRoute
  RecordManagementRecordsImportsIndexRoute: typeof RecordManagementRecordsImportsIndexRoute
}

const RecordManagementRecordsImportsRouteRouteChildren: RecordManagementRecordsImportsRouteRouteChildren =
  {
    RecordManagementRecordsImportsIdRoute:
      RecordManagementRecordsImportsIdRoute,
    RecordManagementRecordsImportsIndexRoute:
      RecordManagementRecordsImportsIndexRoute,
  }

const RecordManagementRecordsImportsRouteRouteWithChildren =
  RecordManagementRecordsImportsRouteRoute._addFileChildren(
    RecordManagementRecordsImportsRouteRouteChildren,
  )

interface RecordManagementRecordsRelinkRouteRouteChildren {
  RecordManagementRecordsRelinkIdRoute: typeof RecordManagementRecordsRelinkIdRoute
  RecordManagementRecordsRelinkIndexRoute: typeof RecordManagementRecordsRelinkIndexRoute
}

const RecordManagementRecordsRelinkRouteRouteChildren: RecordManagementRecordsRelinkRouteRouteChildren =
  {
    RecordManagementRecordsRelinkIdRoute: RecordManagementRecordsRelinkIdRoute,
    RecordManagementRecordsRelinkIndexRoute:
      RecordManagementRecordsRelinkIndexRoute,
  }

const RecordManagementRecordsRelinkRouteRouteWithChildren =
  RecordManagementRecordsRelinkRouteRoute._addFileChildren(
    RecordManagementRecordsRelinkRouteRouteChildren,
  )

interface RecordManagementRouteRouteChildren {
  RecordManagementCommercialPackagesRouteRoute: typeof RecordManagementCommercialPackagesRouteRouteWithChildren
  RecordManagementRecordsImportsRouteRoute: typeof RecordManagementRecordsImportsRouteRouteWithChildren
  RecordManagementRecordsRelinkRouteRoute: typeof RecordManagementRecordsRelinkRouteRouteWithChildren
  RecordManagementRecordsCreateRoute: typeof RecordManagementRecordsCreateRoute
  RecordManagementRecordsExportsRoute: typeof RecordManagementRecordsExportsRoute
  RecordManagementRecordsIndexRoute: typeof RecordManagementRecordsIndexRoute
  RecordManagementRecordsEditIdRoute: typeof RecordManagementRecordsEditIdRoute
  RecordManagementRecordsUnlinkIdRoute: typeof RecordManagementRecordsUnlinkIdRoute
}

const RecordManagementRouteRouteChildren: RecordManagementRouteRouteChildren = {
  RecordManagementCommercialPackagesRouteRoute:
    RecordManagementCommercialPackagesRouteRouteWithChildren,
  RecordManagementRecordsImportsRouteRoute:
    RecordManagementRecordsImportsRouteRouteWithChildren,
  RecordManagementRecordsRelinkRouteRoute:
    RecordManagementRecordsRelinkRouteRouteWithChildren,
  RecordManagementRecordsCreateRoute: RecordManagementRecordsCreateRoute,
  RecordManagementRecordsExportsRoute: RecordManagementRecordsExportsRoute,
  RecordManagementRecordsIndexRoute: RecordManagementRecordsIndexRoute,
  RecordManagementRecordsEditIdRoute: RecordManagementRecordsEditIdRoute,
  RecordManagementRecordsUnlinkIdRoute: RecordManagementRecordsUnlinkIdRoute,
}

const RecordManagementRouteRouteWithChildren =
  RecordManagementRouteRoute._addFileChildren(
    RecordManagementRouteRouteChildren,
  )

interface ReferenceDataRuleRouteRouteChildren {
  ReferenceDataRuleCreateRoute: typeof ReferenceDataRuleCreateRoute
  ReferenceDataRuleIndexRoute: typeof ReferenceDataRuleIndexRoute
  ReferenceDataRuleEditIdRoute: typeof ReferenceDataRuleEditIdRoute
}

const ReferenceDataRuleRouteRouteChildren: ReferenceDataRuleRouteRouteChildren =
  {
    ReferenceDataRuleCreateRoute: ReferenceDataRuleCreateRoute,
    ReferenceDataRuleIndexRoute: ReferenceDataRuleIndexRoute,
    ReferenceDataRuleEditIdRoute: ReferenceDataRuleEditIdRoute,
  }

const ReferenceDataRuleRouteRouteWithChildren =
  ReferenceDataRuleRouteRoute._addFileChildren(
    ReferenceDataRuleRouteRouteChildren,
  )

interface ReferenceDataRouteRouteChildren {
  ReferenceDataRuleRouteRoute: typeof ReferenceDataRuleRouteRouteWithChildren
  ReferenceDataClearingTypesRoute: typeof ReferenceDataClearingTypesRoute
  ReferenceDataCompaniesRoute: typeof ReferenceDataCompaniesRoute
  ReferenceDataCurrenciesRoute: typeof ReferenceDataCurrenciesRoute
  ReferenceDataDayCountsRoute: typeof ReferenceDataDayCountsRoute
  ReferenceDataFrequenciesRoute: typeof ReferenceDataFrequenciesRoute
  ReferenceDataLocationsRoute: typeof ReferenceDataLocationsRoute
  ReferenceDataPeriodsRoute: typeof ReferenceDataPeriodsRoute
  ReferenceDataPriceTypesRoute: typeof ReferenceDataPriceTypesRoute
  ReferenceDataPricingFrequenciesRoute: typeof ReferenceDataPricingFrequenciesRoute
  ReferenceDataSettlementIndexRoute: typeof ReferenceDataSettlementIndexRoute
  ReferenceDataSettlementMethodsRoute: typeof ReferenceDataSettlementMethodsRoute
  ReferenceDataSourcesRoute: typeof ReferenceDataSourcesRoute
  ReferenceDataStandardDiscountingsRoute: typeof ReferenceDataStandardDiscountingsRoute
  ReferenceDataSubscriptionsRoute: typeof ReferenceDataSubscriptionsRoute
  ReferenceDataAssetTypesIndexRoute: typeof ReferenceDataAssetTypesIndexRoute
  ReferenceDataAssetTypesCreateIdRoute: typeof ReferenceDataAssetTypesCreateIdRoute
  ReferenceDataAssetTypesEditIdRoute: typeof ReferenceDataAssetTypesEditIdRoute
}

const ReferenceDataRouteRouteChildren: ReferenceDataRouteRouteChildren = {
  ReferenceDataRuleRouteRoute: ReferenceDataRuleRouteRouteWithChildren,
  ReferenceDataClearingTypesRoute: ReferenceDataClearingTypesRoute,
  ReferenceDataCompaniesRoute: ReferenceDataCompaniesRoute,
  ReferenceDataCurrenciesRoute: ReferenceDataCurrenciesRoute,
  ReferenceDataDayCountsRoute: ReferenceDataDayCountsRoute,
  ReferenceDataFrequenciesRoute: ReferenceDataFrequenciesRoute,
  ReferenceDataLocationsRoute: ReferenceDataLocationsRoute,
  ReferenceDataPeriodsRoute: ReferenceDataPeriodsRoute,
  ReferenceDataPriceTypesRoute: ReferenceDataPriceTypesRoute,
  ReferenceDataPricingFrequenciesRoute: ReferenceDataPricingFrequenciesRoute,
  ReferenceDataSettlementIndexRoute: ReferenceDataSettlementIndexRoute,
  ReferenceDataSettlementMethodsRoute: ReferenceDataSettlementMethodsRoute,
  ReferenceDataSourcesRoute: ReferenceDataSourcesRoute,
  ReferenceDataStandardDiscountingsRoute:
    ReferenceDataStandardDiscountingsRoute,
  ReferenceDataSubscriptionsRoute: ReferenceDataSubscriptionsRoute,
  ReferenceDataAssetTypesIndexRoute: ReferenceDataAssetTypesIndexRoute,
  ReferenceDataAssetTypesCreateIdRoute: ReferenceDataAssetTypesCreateIdRoute,
  ReferenceDataAssetTypesEditIdRoute: ReferenceDataAssetTypesEditIdRoute,
}

const ReferenceDataRouteRouteWithChildren =
  ReferenceDataRouteRoute._addFileChildren(ReferenceDataRouteRouteChildren)

interface ExtractaCreateRouteRouteChildren {
  ExtractaCreateIndexRoute: typeof ExtractaCreateIndexRoute
}

const ExtractaCreateRouteRouteChildren: ExtractaCreateRouteRouteChildren = {
  ExtractaCreateIndexRoute: ExtractaCreateIndexRoute,
}

const ExtractaCreateRouteRouteWithChildren =
  ExtractaCreateRouteRoute._addFileChildren(ExtractaCreateRouteRouteChildren)

interface ExtractaReportsRouteRouteChildren {
  ExtractaReportsIdRoute: typeof ExtractaReportsIdRoute
  ExtractaReportsIndexLazyRoute: typeof ExtractaReportsIndexLazyRoute
  ExtractaReportsIdCloneRoute: typeof ExtractaReportsIdCloneRoute
}

const ExtractaReportsRouteRouteChildren: ExtractaReportsRouteRouteChildren = {
  ExtractaReportsIdRoute: ExtractaReportsIdRoute,
  ExtractaReportsIndexLazyRoute: ExtractaReportsIndexLazyRoute,
  ExtractaReportsIdCloneRoute: ExtractaReportsIdCloneRoute,
}

const ExtractaReportsRouteRouteWithChildren =
  ExtractaReportsRouteRoute._addFileChildren(ExtractaReportsRouteRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof RouteRoute
  '/admin': typeof AdminRouteRouteWithChildren
  '/bloomberg': typeof BloombergRouteRouteWithChildren
  '/entitlements': typeof EntitlementsRouteRouteWithChildren
  '/multibrand': typeof MultibrandRouteRouteWithChildren
  '/record-design': typeof RecordDesignRouteRouteWithChildren
  '/record-management': typeof RecordManagementRouteRouteWithChildren
  '/reference-data': typeof ReferenceDataRouteRouteWithChildren
  '/sign-out': typeof SignOutRoute
  '/extracta/create': typeof ExtractaCreateRouteRouteWithChildren
  '/extracta/reports': typeof ExtractaReportsRouteRouteWithChildren
  '/record-management/commercial-packages': typeof RecordManagementCommercialPackagesRouteRouteWithChildren
  '/reference-data/rule': typeof ReferenceDataRuleRouteRouteWithChildren
  '/bloomberg/entitlements': typeof BloombergEntitlementsRoute
  '/bloomberg/pricing-sources': typeof BloombergPricingSourcesRoute
  '/bloomberg/processes': typeof BloombergProcessesRoute
  '/bloomberg/rulesets': typeof BloombergRulesetsRoute
  '/fids/list': typeof FidsListRoute
  '/login/callback': typeof LoginCallbackRoute
  '/reference-data/clearing-types': typeof ReferenceDataClearingTypesRoute
  '/reference-data/companies': typeof ReferenceDataCompaniesRoute
  '/reference-data/currencies': typeof ReferenceDataCurrenciesRoute
  '/reference-data/day-counts': typeof ReferenceDataDayCountsRoute
  '/reference-data/frequencies': typeof ReferenceDataFrequenciesRoute
  '/reference-data/locations': typeof ReferenceDataLocationsRoute
  '/reference-data/periods': typeof ReferenceDataPeriodsRoute
  '/reference-data/price-types': typeof ReferenceDataPriceTypesRoute
  '/reference-data/pricing-frequencies': typeof ReferenceDataPricingFrequenciesRoute
  '/reference-data/settlement-index': typeof ReferenceDataSettlementIndexRoute
  '/reference-data/settlement-methods': typeof ReferenceDataSettlementMethodsRoute
  '/reference-data/sources': typeof ReferenceDataSourcesRoute
  '/reference-data/standard-discountings': typeof ReferenceDataStandardDiscountingsRoute
  '/reference-data/subscriptions': typeof ReferenceDataSubscriptionsRoute
  '/admin/api-users': typeof AdminApiUsersLazyRoute
  '/admin/roles': typeof AdminRolesLazyRoute
  '/admin/user-roles': typeof AdminUserRolesLazyRoute
  '/entitlements/actor': typeof EntitlementsActorLazyRoute
  '/entitlements/distribution': typeof EntitlementsDistributionLazyRoute
  '/record-design/start': typeof RecordDesignStartLazyRouteWithChildren
  '/bloomberg/records-mappings/import': typeof BloombergRecordsMappingsImportRouteRouteWithChildren
  '/bloomberg/tickers/import': typeof BloombergTickersImportRouteRouteWithChildren
  '/record-management/records/imports': typeof RecordManagementRecordsImportsRouteRouteWithChildren
  '/record-management/records/relink': typeof RecordManagementRecordsRelinkRouteRouteWithChildren
  '/extracta/reports/$id': typeof ExtractaReportsIdRoute
  '/multibrand/root-record-name-structure/create': typeof MultibrandRootRecordNameStructureCreateRoute
  '/record-design/project/$id': typeof RecordDesignProjectIdRoute
  '/record-management/records/create': typeof RecordManagementRecordsCreateRoute
  '/record-management/records/exports': typeof RecordManagementRecordsExportsRoute
  '/reference-data/rule/create': typeof ReferenceDataRuleCreateRoute
  '/entitlements/ruleset/create': typeof EntitlementsRulesetCreateLazyRoute
  '/fids/groups/create': typeof FidsGroupsCreateLazyRoute
  '/record-design/start/$slug': typeof RecordDesignStartSlugLazyRoute
  '/bloomberg/records-mappings': typeof BloombergRecordsMappingsIndexRoute
  '/bloomberg/tickers': typeof BloombergTickersIndexRoute
  '/extracta/create/': typeof ExtractaCreateIndexRoute
  '/fids/groups': typeof FidsGroupsIndexRoute
  '/multibrand/attribute': typeof MultibrandAttributeIndexRoute
  '/multibrand/root-record-name-structure': typeof MultibrandRootRecordNameStructureIndexRoute
  '/multibrand/stubs': typeof MultibrandStubsIndexRoute
  '/record-management/commercial-packages/': typeof RecordManagementCommercialPackagesIndexRoute
  '/record-management/records': typeof RecordManagementRecordsIndexRoute
  '/reference-data/asset-types': typeof ReferenceDataAssetTypesIndexRoute
  '/reference-data/rule/': typeof ReferenceDataRuleIndexRoute
  '/entitlements/ruleset': typeof EntitlementsRulesetIndexLazyRoute
  '/extracta/reports/': typeof ExtractaReportsIndexLazyRoute
  '/bloomberg/records-mappings/import/$taskId': typeof BloombergRecordsMappingsImportTaskIdRoute
  '/bloomberg/tickers/import/$taskId': typeof BloombergTickersImportTaskIdRoute
  '/entitlements/ruleset/edit/$id': typeof EntitlementsRulesetEditIdRoute
  '/extracta/reports/$id/clone': typeof ExtractaReportsIdCloneRoute
  '/fids/groups/edit/$id': typeof FidsGroupsEditIdRoute
  '/multibrand/root-record-name-structure/edit/$id': typeof MultibrandRootRecordNameStructureEditIdRoute
  '/multibrand/stubs/instrument/create': typeof MultibrandStubsInstrumentCreateRoute
  '/record-management/records/edit/$id': typeof RecordManagementRecordsEditIdRoute
  '/record-management/records/imports/$id': typeof RecordManagementRecordsImportsIdRoute
  '/record-management/records/relink/$id': typeof RecordManagementRecordsRelinkIdRoute
  '/record-management/records/unlink/$id': typeof RecordManagementRecordsUnlinkIdRoute
  '/reference-data/asset-types/create/$id': typeof ReferenceDataAssetTypesCreateIdRoute
  '/reference-data/asset-types/edit/$id': typeof ReferenceDataAssetTypesEditIdRoute
  '/reference-data/rule/edit/$id': typeof ReferenceDataRuleEditIdRoute
  '/record-management/records/imports/': typeof RecordManagementRecordsImportsIndexRoute
  '/record-management/records/relink/': typeof RecordManagementRecordsRelinkIndexRoute
  '/multibrand/stubs/instrument/edit/$id': typeof MultibrandStubsInstrumentEditIdRoute
}

export interface FileRoutesByTo {
  '/': typeof RouteRoute
  '/admin': typeof AdminRouteRouteWithChildren
  '/bloomberg': typeof BloombergRouteRouteWithChildren
  '/entitlements': typeof EntitlementsRouteRouteWithChildren
  '/multibrand': typeof MultibrandRouteRouteWithChildren
  '/record-design': typeof RecordDesignRouteRouteWithChildren
  '/record-management': typeof RecordManagementRouteRouteWithChildren
  '/reference-data': typeof ReferenceDataRouteRouteWithChildren
  '/sign-out': typeof SignOutRoute
  '/bloomberg/entitlements': typeof BloombergEntitlementsRoute
  '/bloomberg/pricing-sources': typeof BloombergPricingSourcesRoute
  '/bloomberg/processes': typeof BloombergProcessesRoute
  '/bloomberg/rulesets': typeof BloombergRulesetsRoute
  '/fids/list': typeof FidsListRoute
  '/login/callback': typeof LoginCallbackRoute
  '/reference-data/clearing-types': typeof ReferenceDataClearingTypesRoute
  '/reference-data/companies': typeof ReferenceDataCompaniesRoute
  '/reference-data/currencies': typeof ReferenceDataCurrenciesRoute
  '/reference-data/day-counts': typeof ReferenceDataDayCountsRoute
  '/reference-data/frequencies': typeof ReferenceDataFrequenciesRoute
  '/reference-data/locations': typeof ReferenceDataLocationsRoute
  '/reference-data/periods': typeof ReferenceDataPeriodsRoute
  '/reference-data/price-types': typeof ReferenceDataPriceTypesRoute
  '/reference-data/pricing-frequencies': typeof ReferenceDataPricingFrequenciesRoute
  '/reference-data/settlement-index': typeof ReferenceDataSettlementIndexRoute
  '/reference-data/settlement-methods': typeof ReferenceDataSettlementMethodsRoute
  '/reference-data/sources': typeof ReferenceDataSourcesRoute
  '/reference-data/standard-discountings': typeof ReferenceDataStandardDiscountingsRoute
  '/reference-data/subscriptions': typeof ReferenceDataSubscriptionsRoute
  '/admin/api-users': typeof AdminApiUsersLazyRoute
  '/admin/roles': typeof AdminRolesLazyRoute
  '/admin/user-roles': typeof AdminUserRolesLazyRoute
  '/entitlements/actor': typeof EntitlementsActorLazyRoute
  '/entitlements/distribution': typeof EntitlementsDistributionLazyRoute
  '/record-design/start': typeof RecordDesignStartLazyRouteWithChildren
  '/bloomberg/records-mappings/import': typeof BloombergRecordsMappingsImportRouteRouteWithChildren
  '/bloomberg/tickers/import': typeof BloombergTickersImportRouteRouteWithChildren
  '/extracta/reports/$id': typeof ExtractaReportsIdRoute
  '/multibrand/root-record-name-structure/create': typeof MultibrandRootRecordNameStructureCreateRoute
  '/record-design/project/$id': typeof RecordDesignProjectIdRoute
  '/record-management/records/create': typeof RecordManagementRecordsCreateRoute
  '/record-management/records/exports': typeof RecordManagementRecordsExportsRoute
  '/reference-data/rule/create': typeof ReferenceDataRuleCreateRoute
  '/entitlements/ruleset/create': typeof EntitlementsRulesetCreateLazyRoute
  '/fids/groups/create': typeof FidsGroupsCreateLazyRoute
  '/record-design/start/$slug': typeof RecordDesignStartSlugLazyRoute
  '/bloomberg/records-mappings': typeof BloombergRecordsMappingsIndexRoute
  '/bloomberg/tickers': typeof BloombergTickersIndexRoute
  '/extracta/create': typeof ExtractaCreateIndexRoute
  '/fids/groups': typeof FidsGroupsIndexRoute
  '/multibrand/attribute': typeof MultibrandAttributeIndexRoute
  '/multibrand/root-record-name-structure': typeof MultibrandRootRecordNameStructureIndexRoute
  '/multibrand/stubs': typeof MultibrandStubsIndexRoute
  '/record-management/commercial-packages': typeof RecordManagementCommercialPackagesIndexRoute
  '/record-management/records': typeof RecordManagementRecordsIndexRoute
  '/reference-data/asset-types': typeof ReferenceDataAssetTypesIndexRoute
  '/reference-data/rule': typeof ReferenceDataRuleIndexRoute
  '/entitlements/ruleset': typeof EntitlementsRulesetIndexLazyRoute
  '/extracta/reports': typeof ExtractaReportsIndexLazyRoute
  '/bloomberg/records-mappings/import/$taskId': typeof BloombergRecordsMappingsImportTaskIdRoute
  '/bloomberg/tickers/import/$taskId': typeof BloombergTickersImportTaskIdRoute
  '/entitlements/ruleset/edit/$id': typeof EntitlementsRulesetEditIdRoute
  '/extracta/reports/$id/clone': typeof ExtractaReportsIdCloneRoute
  '/fids/groups/edit/$id': typeof FidsGroupsEditIdRoute
  '/multibrand/root-record-name-structure/edit/$id': typeof MultibrandRootRecordNameStructureEditIdRoute
  '/multibrand/stubs/instrument/create': typeof MultibrandStubsInstrumentCreateRoute
  '/record-management/records/edit/$id': typeof RecordManagementRecordsEditIdRoute
  '/record-management/records/imports/$id': typeof RecordManagementRecordsImportsIdRoute
  '/record-management/records/relink/$id': typeof RecordManagementRecordsRelinkIdRoute
  '/record-management/records/unlink/$id': typeof RecordManagementRecordsUnlinkIdRoute
  '/reference-data/asset-types/create/$id': typeof ReferenceDataAssetTypesCreateIdRoute
  '/reference-data/asset-types/edit/$id': typeof ReferenceDataAssetTypesEditIdRoute
  '/reference-data/rule/edit/$id': typeof ReferenceDataRuleEditIdRoute
  '/record-management/records/imports': typeof RecordManagementRecordsImportsIndexRoute
  '/record-management/records/relink': typeof RecordManagementRecordsRelinkIndexRoute
  '/multibrand/stubs/instrument/edit/$id': typeof MultibrandStubsInstrumentEditIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof RouteRoute
  '/admin': typeof AdminRouteRouteWithChildren
  '/bloomberg': typeof BloombergRouteRouteWithChildren
  '/entitlements': typeof EntitlementsRouteRouteWithChildren
  '/multibrand': typeof MultibrandRouteRouteWithChildren
  '/record-design': typeof RecordDesignRouteRouteWithChildren
  '/record-management': typeof RecordManagementRouteRouteWithChildren
  '/reference-data': typeof ReferenceDataRouteRouteWithChildren
  '/sign-out': typeof SignOutRoute
  '/extracta/create': typeof ExtractaCreateRouteRouteWithChildren
  '/extracta/reports': typeof ExtractaReportsRouteRouteWithChildren
  '/record-management/commercial-packages': typeof RecordManagementCommercialPackagesRouteRouteWithChildren
  '/reference-data/rule': typeof ReferenceDataRuleRouteRouteWithChildren
  '/bloomberg/entitlements': typeof BloombergEntitlementsRoute
  '/bloomberg/pricing-sources': typeof BloombergPricingSourcesRoute
  '/bloomberg/processes': typeof BloombergProcessesRoute
  '/bloomberg/rulesets': typeof BloombergRulesetsRoute
  '/fids/list': typeof FidsListRoute
  '/login/callback': typeof LoginCallbackRoute
  '/reference-data/clearing-types': typeof ReferenceDataClearingTypesRoute
  '/reference-data/companies': typeof ReferenceDataCompaniesRoute
  '/reference-data/currencies': typeof ReferenceDataCurrenciesRoute
  '/reference-data/day-counts': typeof ReferenceDataDayCountsRoute
  '/reference-data/frequencies': typeof ReferenceDataFrequenciesRoute
  '/reference-data/locations': typeof ReferenceDataLocationsRoute
  '/reference-data/periods': typeof ReferenceDataPeriodsRoute
  '/reference-data/price-types': typeof ReferenceDataPriceTypesRoute
  '/reference-data/pricing-frequencies': typeof ReferenceDataPricingFrequenciesRoute
  '/reference-data/settlement-index': typeof ReferenceDataSettlementIndexRoute
  '/reference-data/settlement-methods': typeof ReferenceDataSettlementMethodsRoute
  '/reference-data/sources': typeof ReferenceDataSourcesRoute
  '/reference-data/standard-discountings': typeof ReferenceDataStandardDiscountingsRoute
  '/reference-data/subscriptions': typeof ReferenceDataSubscriptionsRoute
  '/admin/api-users': typeof AdminApiUsersLazyRoute
  '/admin/roles': typeof AdminRolesLazyRoute
  '/admin/user-roles': typeof AdminUserRolesLazyRoute
  '/entitlements/actor': typeof EntitlementsActorLazyRoute
  '/entitlements/distribution': typeof EntitlementsDistributionLazyRoute
  '/record-design/start': typeof RecordDesignStartLazyRouteWithChildren
  '/bloomberg/records-mappings/import': typeof BloombergRecordsMappingsImportRouteRouteWithChildren
  '/bloomberg/tickers/import': typeof BloombergTickersImportRouteRouteWithChildren
  '/record-management/records/imports': typeof RecordManagementRecordsImportsRouteRouteWithChildren
  '/record-management/records/relink': typeof RecordManagementRecordsRelinkRouteRouteWithChildren
  '/extracta/reports/$id': typeof ExtractaReportsIdRoute
  '/multibrand/root-record-name-structure/create': typeof MultibrandRootRecordNameStructureCreateRoute
  '/record-design/project/$id': typeof RecordDesignProjectIdRoute
  '/record-management/records/create': typeof RecordManagementRecordsCreateRoute
  '/record-management/records/exports': typeof RecordManagementRecordsExportsRoute
  '/reference-data/rule/create': typeof ReferenceDataRuleCreateRoute
  '/entitlements/ruleset/create': typeof EntitlementsRulesetCreateLazyRoute
  '/fids/groups/create': typeof FidsGroupsCreateLazyRoute
  '/record-design/start/$slug': typeof RecordDesignStartSlugLazyRoute
  '/bloomberg/records-mappings/': typeof BloombergRecordsMappingsIndexRoute
  '/bloomberg/tickers/': typeof BloombergTickersIndexRoute
  '/extracta/create/': typeof ExtractaCreateIndexRoute
  '/fids/groups/': typeof FidsGroupsIndexRoute
  '/multibrand/attribute/': typeof MultibrandAttributeIndexRoute
  '/multibrand/root-record-name-structure/': typeof MultibrandRootRecordNameStructureIndexRoute
  '/multibrand/stubs/': typeof MultibrandStubsIndexRoute
  '/record-management/commercial-packages/': typeof RecordManagementCommercialPackagesIndexRoute
  '/record-management/records/': typeof RecordManagementRecordsIndexRoute
  '/reference-data/asset-types/': typeof ReferenceDataAssetTypesIndexRoute
  '/reference-data/rule/': typeof ReferenceDataRuleIndexRoute
  '/entitlements/ruleset/': typeof EntitlementsRulesetIndexLazyRoute
  '/extracta/reports/': typeof ExtractaReportsIndexLazyRoute
  '/bloomberg/records-mappings/import/$taskId': typeof BloombergRecordsMappingsImportTaskIdRoute
  '/bloomberg/tickers/import/$taskId': typeof BloombergTickersImportTaskIdRoute
  '/entitlements/ruleset/edit/$id': typeof EntitlementsRulesetEditIdRoute
  '/extracta/reports/$id_/clone': typeof ExtractaReportsIdCloneRoute
  '/fids/groups/edit/$id': typeof FidsGroupsEditIdRoute
  '/multibrand/root-record-name-structure/edit/$id': typeof MultibrandRootRecordNameStructureEditIdRoute
  '/multibrand/stubs/instrument/create': typeof MultibrandStubsInstrumentCreateRoute
  '/record-management/records/edit/$id': typeof RecordManagementRecordsEditIdRoute
  '/record-management/records/imports/$id': typeof RecordManagementRecordsImportsIdRoute
  '/record-management/records/relink/$id': typeof RecordManagementRecordsRelinkIdRoute
  '/record-management/records/unlink/$id': typeof RecordManagementRecordsUnlinkIdRoute
  '/reference-data/asset-types/create/$id': typeof ReferenceDataAssetTypesCreateIdRoute
  '/reference-data/asset-types/edit/$id': typeof ReferenceDataAssetTypesEditIdRoute
  '/reference-data/rule/edit/$id': typeof ReferenceDataRuleEditIdRoute
  '/record-management/records/imports/': typeof RecordManagementRecordsImportsIndexRoute
  '/record-management/records/relink/': typeof RecordManagementRecordsRelinkIndexRoute
  '/multibrand/stubs/instrument/edit/$id': typeof MultibrandStubsInstrumentEditIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/admin'
    | '/bloomberg'
    | '/entitlements'
    | '/multibrand'
    | '/record-design'
    | '/record-management'
    | '/reference-data'
    | '/sign-out'
    | '/extracta/create'
    | '/extracta/reports'
    | '/record-management/commercial-packages'
    | '/reference-data/rule'
    | '/bloomberg/entitlements'
    | '/bloomberg/pricing-sources'
    | '/bloomberg/processes'
    | '/bloomberg/rulesets'
    | '/fids/list'
    | '/login/callback'
    | '/reference-data/clearing-types'
    | '/reference-data/companies'
    | '/reference-data/currencies'
    | '/reference-data/day-counts'
    | '/reference-data/frequencies'
    | '/reference-data/locations'
    | '/reference-data/periods'
    | '/reference-data/price-types'
    | '/reference-data/pricing-frequencies'
    | '/reference-data/settlement-index'
    | '/reference-data/settlement-methods'
    | '/reference-data/sources'
    | '/reference-data/standard-discountings'
    | '/reference-data/subscriptions'
    | '/admin/api-users'
    | '/admin/roles'
    | '/admin/user-roles'
    | '/entitlements/actor'
    | '/entitlements/distribution'
    | '/record-design/start'
    | '/bloomberg/records-mappings/import'
    | '/bloomberg/tickers/import'
    | '/record-management/records/imports'
    | '/record-management/records/relink'
    | '/extracta/reports/$id'
    | '/multibrand/root-record-name-structure/create'
    | '/record-design/project/$id'
    | '/record-management/records/create'
    | '/record-management/records/exports'
    | '/reference-data/rule/create'
    | '/entitlements/ruleset/create'
    | '/fids/groups/create'
    | '/record-design/start/$slug'
    | '/bloomberg/records-mappings'
    | '/bloomberg/tickers'
    | '/extracta/create/'
    | '/fids/groups'
    | '/multibrand/attribute'
    | '/multibrand/root-record-name-structure'
    | '/multibrand/stubs'
    | '/record-management/commercial-packages/'
    | '/record-management/records'
    | '/reference-data/asset-types'
    | '/reference-data/rule/'
    | '/entitlements/ruleset'
    | '/extracta/reports/'
    | '/bloomberg/records-mappings/import/$taskId'
    | '/bloomberg/tickers/import/$taskId'
    | '/entitlements/ruleset/edit/$id'
    | '/extracta/reports/$id/clone'
    | '/fids/groups/edit/$id'
    | '/multibrand/root-record-name-structure/edit/$id'
    | '/multibrand/stubs/instrument/create'
    | '/record-management/records/edit/$id'
    | '/record-management/records/imports/$id'
    | '/record-management/records/relink/$id'
    | '/record-management/records/unlink/$id'
    | '/reference-data/asset-types/create/$id'
    | '/reference-data/asset-types/edit/$id'
    | '/reference-data/rule/edit/$id'
    | '/record-management/records/imports/'
    | '/record-management/records/relink/'
    | '/multibrand/stubs/instrument/edit/$id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/admin'
    | '/bloomberg'
    | '/entitlements'
    | '/multibrand'
    | '/record-design'
    | '/record-management'
    | '/reference-data'
    | '/sign-out'
    | '/bloomberg/entitlements'
    | '/bloomberg/pricing-sources'
    | '/bloomberg/processes'
    | '/bloomberg/rulesets'
    | '/fids/list'
    | '/login/callback'
    | '/reference-data/clearing-types'
    | '/reference-data/companies'
    | '/reference-data/currencies'
    | '/reference-data/day-counts'
    | '/reference-data/frequencies'
    | '/reference-data/locations'
    | '/reference-data/periods'
    | '/reference-data/price-types'
    | '/reference-data/pricing-frequencies'
    | '/reference-data/settlement-index'
    | '/reference-data/settlement-methods'
    | '/reference-data/sources'
    | '/reference-data/standard-discountings'
    | '/reference-data/subscriptions'
    | '/admin/api-users'
    | '/admin/roles'
    | '/admin/user-roles'
    | '/entitlements/actor'
    | '/entitlements/distribution'
    | '/record-design/start'
    | '/bloomberg/records-mappings/import'
    | '/bloomberg/tickers/import'
    | '/extracta/reports/$id'
    | '/multibrand/root-record-name-structure/create'
    | '/record-design/project/$id'
    | '/record-management/records/create'
    | '/record-management/records/exports'
    | '/reference-data/rule/create'
    | '/entitlements/ruleset/create'
    | '/fids/groups/create'
    | '/record-design/start/$slug'
    | '/bloomberg/records-mappings'
    | '/bloomberg/tickers'
    | '/extracta/create'
    | '/fids/groups'
    | '/multibrand/attribute'
    | '/multibrand/root-record-name-structure'
    | '/multibrand/stubs'
    | '/record-management/commercial-packages'
    | '/record-management/records'
    | '/reference-data/asset-types'
    | '/reference-data/rule'
    | '/entitlements/ruleset'
    | '/extracta/reports'
    | '/bloomberg/records-mappings/import/$taskId'
    | '/bloomberg/tickers/import/$taskId'
    | '/entitlements/ruleset/edit/$id'
    | '/extracta/reports/$id/clone'
    | '/fids/groups/edit/$id'
    | '/multibrand/root-record-name-structure/edit/$id'
    | '/multibrand/stubs/instrument/create'
    | '/record-management/records/edit/$id'
    | '/record-management/records/imports/$id'
    | '/record-management/records/relink/$id'
    | '/record-management/records/unlink/$id'
    | '/reference-data/asset-types/create/$id'
    | '/reference-data/asset-types/edit/$id'
    | '/reference-data/rule/edit/$id'
    | '/record-management/records/imports'
    | '/record-management/records/relink'
    | '/multibrand/stubs/instrument/edit/$id'
  id:
    | '__root__'
    | '/'
    | '/admin'
    | '/bloomberg'
    | '/entitlements'
    | '/multibrand'
    | '/record-design'
    | '/record-management'
    | '/reference-data'
    | '/sign-out'
    | '/extracta/create'
    | '/extracta/reports'
    | '/record-management/commercial-packages'
    | '/reference-data/rule'
    | '/bloomberg/entitlements'
    | '/bloomberg/pricing-sources'
    | '/bloomberg/processes'
    | '/bloomberg/rulesets'
    | '/fids/list'
    | '/login/callback'
    | '/reference-data/clearing-types'
    | '/reference-data/companies'
    | '/reference-data/currencies'
    | '/reference-data/day-counts'
    | '/reference-data/frequencies'
    | '/reference-data/locations'
    | '/reference-data/periods'
    | '/reference-data/price-types'
    | '/reference-data/pricing-frequencies'
    | '/reference-data/settlement-index'
    | '/reference-data/settlement-methods'
    | '/reference-data/sources'
    | '/reference-data/standard-discountings'
    | '/reference-data/subscriptions'
    | '/admin/api-users'
    | '/admin/roles'
    | '/admin/user-roles'
    | '/entitlements/actor'
    | '/entitlements/distribution'
    | '/record-design/start'
    | '/bloomberg/records-mappings/import'
    | '/bloomberg/tickers/import'
    | '/record-management/records/imports'
    | '/record-management/records/relink'
    | '/extracta/reports/$id'
    | '/multibrand/root-record-name-structure/create'
    | '/record-design/project/$id'
    | '/record-management/records/create'
    | '/record-management/records/exports'
    | '/reference-data/rule/create'
    | '/entitlements/ruleset/create'
    | '/fids/groups/create'
    | '/record-design/start/$slug'
    | '/bloomberg/records-mappings/'
    | '/bloomberg/tickers/'
    | '/extracta/create/'
    | '/fids/groups/'
    | '/multibrand/attribute/'
    | '/multibrand/root-record-name-structure/'
    | '/multibrand/stubs/'
    | '/record-management/commercial-packages/'
    | '/record-management/records/'
    | '/reference-data/asset-types/'
    | '/reference-data/rule/'
    | '/entitlements/ruleset/'
    | '/extracta/reports/'
    | '/bloomberg/records-mappings/import/$taskId'
    | '/bloomberg/tickers/import/$taskId'
    | '/entitlements/ruleset/edit/$id'
    | '/extracta/reports/$id_/clone'
    | '/fids/groups/edit/$id'
    | '/multibrand/root-record-name-structure/edit/$id'
    | '/multibrand/stubs/instrument/create'
    | '/record-management/records/edit/$id'
    | '/record-management/records/imports/$id'
    | '/record-management/records/relink/$id'
    | '/record-management/records/unlink/$id'
    | '/reference-data/asset-types/create/$id'
    | '/reference-data/asset-types/edit/$id'
    | '/reference-data/rule/edit/$id'
    | '/record-management/records/imports/'
    | '/record-management/records/relink/'
    | '/multibrand/stubs/instrument/edit/$id'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  RouteRoute: typeof RouteRoute
  AdminRouteRoute: typeof AdminRouteRouteWithChildren
  BloombergRouteRoute: typeof BloombergRouteRouteWithChildren
  EntitlementsRouteRoute: typeof EntitlementsRouteRouteWithChildren
  MultibrandRouteRoute: typeof MultibrandRouteRouteWithChildren
  RecordDesignRouteRoute: typeof RecordDesignRouteRouteWithChildren
  RecordManagementRouteRoute: typeof RecordManagementRouteRouteWithChildren
  ReferenceDataRouteRoute: typeof ReferenceDataRouteRouteWithChildren
  SignOutRoute: typeof SignOutRoute
  ExtractaCreateRouteRoute: typeof ExtractaCreateRouteRouteWithChildren
  ExtractaReportsRouteRoute: typeof ExtractaReportsRouteRouteWithChildren
  FidsListRoute: typeof FidsListRoute
  LoginCallbackRoute: typeof LoginCallbackRoute
  FidsGroupsCreateLazyRoute: typeof FidsGroupsCreateLazyRoute
  FidsGroupsIndexRoute: typeof FidsGroupsIndexRoute
  FidsGroupsEditIdRoute: typeof FidsGroupsEditIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  RouteRoute: RouteRoute,
  AdminRouteRoute: AdminRouteRouteWithChildren,
  BloombergRouteRoute: BloombergRouteRouteWithChildren,
  EntitlementsRouteRoute: EntitlementsRouteRouteWithChildren,
  MultibrandRouteRoute: MultibrandRouteRouteWithChildren,
  RecordDesignRouteRoute: RecordDesignRouteRouteWithChildren,
  RecordManagementRouteRoute: RecordManagementRouteRouteWithChildren,
  ReferenceDataRouteRoute: ReferenceDataRouteRouteWithChildren,
  SignOutRoute: SignOutRoute,
  ExtractaCreateRouteRoute: ExtractaCreateRouteRouteWithChildren,
  ExtractaReportsRouteRoute: ExtractaReportsRouteRouteWithChildren,
  FidsListRoute: FidsListRoute,
  LoginCallbackRoute: LoginCallbackRoute,
  FidsGroupsCreateLazyRoute: FidsGroupsCreateLazyRoute,
  FidsGroupsIndexRoute: FidsGroupsIndexRoute,
  FidsGroupsEditIdRoute: FidsGroupsEditIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/admin",
        "/bloomberg",
        "/entitlements",
        "/multibrand",
        "/record-design",
        "/record-management",
        "/reference-data",
        "/sign-out",
        "/extracta/create",
        "/extracta/reports",
        "/fids/list",
        "/login/callback",
        "/fids/groups/create",
        "/fids/groups/",
        "/fids/groups/edit/$id"
      ]
    },
    "/": {
      "filePath": "route.tsx"
    },
    "/admin": {
      "filePath": "admin/route.tsx",
      "children": [
        "/admin/api-users",
        "/admin/roles",
        "/admin/user-roles"
      ]
    },
    "/bloomberg": {
      "filePath": "bloomberg/route.tsx",
      "children": [
        "/bloomberg/entitlements",
        "/bloomberg/pricing-sources",
        "/bloomberg/processes",
        "/bloomberg/rulesets",
        "/bloomberg/records-mappings/import",
        "/bloomberg/tickers/import",
        "/bloomberg/records-mappings/",
        "/bloomberg/tickers/"
      ]
    },
    "/entitlements": {
      "filePath": "entitlements/route.tsx",
      "children": [
        "/entitlements/actor",
        "/entitlements/distribution",
        "/entitlements/ruleset/create",
        "/entitlements/ruleset/",
        "/entitlements/ruleset/edit/$id"
      ]
    },
    "/multibrand": {
      "filePath": "multibrand/route.tsx",
      "children": [
        "/multibrand/root-record-name-structure/create",
        "/multibrand/attribute/",
        "/multibrand/root-record-name-structure/",
        "/multibrand/stubs/",
        "/multibrand/root-record-name-structure/edit/$id",
        "/multibrand/stubs/instrument/create",
        "/multibrand/stubs/instrument/edit/$id"
      ]
    },
    "/record-design": {
      "filePath": "record-design/route.tsx",
      "children": [
        "/record-design/start",
        "/record-design/project/$id"
      ]
    },
    "/record-management": {
      "filePath": "record-management/route.tsx",
      "children": [
        "/record-management/commercial-packages",
        "/record-management/records/imports",
        "/record-management/records/relink",
        "/record-management/records/create",
        "/record-management/records/exports",
        "/record-management/records/",
        "/record-management/records/edit/$id",
        "/record-management/records/unlink/$id"
      ]
    },
    "/reference-data": {
      "filePath": "reference-data/route.tsx",
      "children": [
        "/reference-data/rule",
        "/reference-data/clearing-types",
        "/reference-data/companies",
        "/reference-data/currencies",
        "/reference-data/day-counts",
        "/reference-data/frequencies",
        "/reference-data/locations",
        "/reference-data/periods",
        "/reference-data/price-types",
        "/reference-data/pricing-frequencies",
        "/reference-data/settlement-index",
        "/reference-data/settlement-methods",
        "/reference-data/sources",
        "/reference-data/standard-discountings",
        "/reference-data/subscriptions",
        "/reference-data/asset-types/",
        "/reference-data/asset-types/create/$id",
        "/reference-data/asset-types/edit/$id"
      ]
    },
    "/sign-out": {
      "filePath": "sign-out.tsx"
    },
    "/extracta/create": {
      "filePath": "extracta/create/route.tsx",
      "children": [
        "/extracta/create/"
      ]
    },
    "/extracta/reports": {
      "filePath": "extracta/reports/route.tsx",
      "children": [
        "/extracta/reports/$id",
        "/extracta/reports/",
        "/extracta/reports/$id_/clone"
      ]
    },
    "/record-management/commercial-packages": {
      "filePath": "record-management/commercial-packages/route.tsx",
      "parent": "/record-management",
      "children": [
        "/record-management/commercial-packages/"
      ]
    },
    "/reference-data/rule": {
      "filePath": "reference-data/rule/route.tsx",
      "parent": "/reference-data",
      "children": [
        "/reference-data/rule/create",
        "/reference-data/rule/",
        "/reference-data/rule/edit/$id"
      ]
    },
    "/bloomberg/entitlements": {
      "filePath": "bloomberg/entitlements.ts",
      "parent": "/bloomberg"
    },
    "/bloomberg/pricing-sources": {
      "filePath": "bloomberg/pricing-sources.ts",
      "parent": "/bloomberg"
    },
    "/bloomberg/processes": {
      "filePath": "bloomberg/processes.ts",
      "parent": "/bloomberg"
    },
    "/bloomberg/rulesets": {
      "filePath": "bloomberg/rulesets.ts",
      "parent": "/bloomberg"
    },
    "/fids/list": {
      "filePath": "fids/list.ts"
    },
    "/login/callback": {
      "filePath": "login.callback.tsx"
    },
    "/reference-data/clearing-types": {
      "filePath": "reference-data/clearing-types.ts",
      "parent": "/reference-data"
    },
    "/reference-data/companies": {
      "filePath": "reference-data/companies.ts",
      "parent": "/reference-data"
    },
    "/reference-data/currencies": {
      "filePath": "reference-data/currencies.ts",
      "parent": "/reference-data"
    },
    "/reference-data/day-counts": {
      "filePath": "reference-data/day-counts.ts",
      "parent": "/reference-data"
    },
    "/reference-data/frequencies": {
      "filePath": "reference-data/frequencies.ts",
      "parent": "/reference-data"
    },
    "/reference-data/locations": {
      "filePath": "reference-data/locations.ts",
      "parent": "/reference-data"
    },
    "/reference-data/periods": {
      "filePath": "reference-data/periods.ts",
      "parent": "/reference-data"
    },
    "/reference-data/price-types": {
      "filePath": "reference-data/price-types.ts",
      "parent": "/reference-data"
    },
    "/reference-data/pricing-frequencies": {
      "filePath": "reference-data/pricing-frequencies.ts",
      "parent": "/reference-data"
    },
    "/reference-data/settlement-index": {
      "filePath": "reference-data/settlement-index.ts",
      "parent": "/reference-data"
    },
    "/reference-data/settlement-methods": {
      "filePath": "reference-data/settlement-methods.ts",
      "parent": "/reference-data"
    },
    "/reference-data/sources": {
      "filePath": "reference-data/sources.ts",
      "parent": "/reference-data"
    },
    "/reference-data/standard-discountings": {
      "filePath": "reference-data/standard-discountings.ts",
      "parent": "/reference-data"
    },
    "/reference-data/subscriptions": {
      "filePath": "reference-data/subscriptions.ts",
      "parent": "/reference-data"
    },
    "/admin/api-users": {
      "filePath": "admin/api-users.lazy.ts",
      "parent": "/admin"
    },
    "/admin/roles": {
      "filePath": "admin/roles.lazy.ts",
      "parent": "/admin"
    },
    "/admin/user-roles": {
      "filePath": "admin/user-roles.lazy.ts",
      "parent": "/admin"
    },
    "/entitlements/actor": {
      "filePath": "entitlements/actor.lazy.ts",
      "parent": "/entitlements"
    },
    "/entitlements/distribution": {
      "filePath": "entitlements/distribution.lazy.ts",
      "parent": "/entitlements"
    },
    "/record-design/start": {
      "filePath": "record-design/start.lazy.tsx",
      "parent": "/record-design",
      "children": [
        "/record-design/start/$slug"
      ]
    },
    "/bloomberg/records-mappings/import": {
      "filePath": "bloomberg/records-mappings/import/route.tsx",
      "parent": "/bloomberg",
      "children": [
        "/bloomberg/records-mappings/import/$taskId"
      ]
    },
    "/bloomberg/tickers/import": {
      "filePath": "bloomberg/tickers/import/route.tsx",
      "parent": "/bloomberg",
      "children": [
        "/bloomberg/tickers/import/$taskId"
      ]
    },
    "/record-management/records/imports": {
      "filePath": "record-management/records/imports/route.tsx",
      "parent": "/record-management",
      "children": [
        "/record-management/records/imports/$id",
        "/record-management/records/imports/"
      ]
    },
    "/record-management/records/relink": {
      "filePath": "record-management/records/relink/route.tsx",
      "parent": "/record-management",
      "children": [
        "/record-management/records/relink/$id",
        "/record-management/records/relink/"
      ]
    },
    "/extracta/reports/$id": {
      "filePath": "extracta/reports/$id.tsx",
      "parent": "/extracta/reports"
    },
    "/multibrand/root-record-name-structure/create": {
      "filePath": "multibrand/root-record-name-structure/create.ts",
      "parent": "/multibrand"
    },
    "/record-design/project/$id": {
      "filePath": "record-design/project/$id.ts",
      "parent": "/record-design"
    },
    "/record-management/records/create": {
      "filePath": "record-management/records/create.ts",
      "parent": "/record-management"
    },
    "/record-management/records/exports": {
      "filePath": "record-management/records/exports.ts",
      "parent": "/record-management"
    },
    "/reference-data/rule/create": {
      "filePath": "reference-data/rule/create.ts",
      "parent": "/reference-data/rule"
    },
    "/entitlements/ruleset/create": {
      "filePath": "entitlements/ruleset/create.lazy.ts",
      "parent": "/entitlements"
    },
    "/fids/groups/create": {
      "filePath": "fids/groups/create.lazy.ts"
    },
    "/record-design/start/$slug": {
      "filePath": "record-design/start.$slug.lazy.tsx",
      "parent": "/record-design/start"
    },
    "/bloomberg/records-mappings/": {
      "filePath": "bloomberg/records-mappings/index.ts",
      "parent": "/bloomberg"
    },
    "/bloomberg/tickers/": {
      "filePath": "bloomberg/tickers/index.ts",
      "parent": "/bloomberg"
    },
    "/extracta/create/": {
      "filePath": "extracta/create/index.ts",
      "parent": "/extracta/create"
    },
    "/fids/groups/": {
      "filePath": "fids/groups/index.ts"
    },
    "/multibrand/attribute/": {
      "filePath": "multibrand/attribute/index.ts",
      "parent": "/multibrand"
    },
    "/multibrand/root-record-name-structure/": {
      "filePath": "multibrand/root-record-name-structure/index.ts",
      "parent": "/multibrand"
    },
    "/multibrand/stubs/": {
      "filePath": "multibrand/stubs/index.tsx",
      "parent": "/multibrand"
    },
    "/record-management/commercial-packages/": {
      "filePath": "record-management/commercial-packages/index.tsx",
      "parent": "/record-management/commercial-packages"
    },
    "/record-management/records/": {
      "filePath": "record-management/records/index.ts",
      "parent": "/record-management"
    },
    "/reference-data/asset-types/": {
      "filePath": "reference-data/asset-types/index.ts",
      "parent": "/reference-data"
    },
    "/reference-data/rule/": {
      "filePath": "reference-data/rule/index.ts",
      "parent": "/reference-data/rule"
    },
    "/entitlements/ruleset/": {
      "filePath": "entitlements/ruleset/index.lazy.ts",
      "parent": "/entitlements"
    },
    "/extracta/reports/": {
      "filePath": "extracta/reports/index.lazy.ts",
      "parent": "/extracta/reports"
    },
    "/bloomberg/records-mappings/import/$taskId": {
      "filePath": "bloomberg/records-mappings/import/$taskId.ts",
      "parent": "/bloomberg/records-mappings/import"
    },
    "/bloomberg/tickers/import/$taskId": {
      "filePath": "bloomberg/tickers/import/$taskId.ts",
      "parent": "/bloomberg/tickers/import"
    },
    "/entitlements/ruleset/edit/$id": {
      "filePath": "entitlements/ruleset/edit.$id.ts",
      "parent": "/entitlements"
    },
    "/extracta/reports/$id_/clone": {
      "filePath": "extracta/reports/$id_.clone.tsx",
      "parent": "/extracta/reports"
    },
    "/fids/groups/edit/$id": {
      "filePath": "fids/groups/edit.$id.ts"
    },
    "/multibrand/root-record-name-structure/edit/$id": {
      "filePath": "multibrand/root-record-name-structure/edit.$id.ts",
      "parent": "/multibrand"
    },
    "/multibrand/stubs/instrument/create": {
      "filePath": "multibrand/stubs/instrument/create.tsx",
      "parent": "/multibrand"
    },
    "/record-management/records/edit/$id": {
      "filePath": "record-management/records/edit.$id.ts",
      "parent": "/record-management"
    },
    "/record-management/records/imports/$id": {
      "filePath": "record-management/records/imports/$id.ts",
      "parent": "/record-management/records/imports"
    },
    "/record-management/records/relink/$id": {
      "filePath": "record-management/records/relink/$id.ts",
      "parent": "/record-management/records/relink"
    },
    "/record-management/records/unlink/$id": {
      "filePath": "record-management/records/unlink.$id.ts",
      "parent": "/record-management"
    },
    "/reference-data/asset-types/create/$id": {
      "filePath": "reference-data/asset-types/create.$id.ts",
      "parent": "/reference-data"
    },
    "/reference-data/asset-types/edit/$id": {
      "filePath": "reference-data/asset-types/edit.$id.ts",
      "parent": "/reference-data"
    },
    "/reference-data/rule/edit/$id": {
      "filePath": "reference-data/rule/edit.$id.ts",
      "parent": "/reference-data/rule"
    },
    "/record-management/records/imports/": {
      "filePath": "record-management/records/imports/index.ts",
      "parent": "/record-management/records/imports"
    },
    "/record-management/records/relink/": {
      "filePath": "record-management/records/relink/index.ts",
      "parent": "/record-management/records/relink"
    },
    "/multibrand/stubs/instrument/edit/$id": {
      "filePath": "multibrand/stubs/instrument/edit.$id.tsx",
      "parent": "/multibrand"
    }
  }
}
ROUTE_MANIFEST_END */
