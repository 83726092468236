import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'

export type UnlinkRecordButtonProps = {
  recordId: number
}

export function UnlinkRecordButton({ recordId }: UnlinkRecordButtonProps) {
  const isEditable = useEntitlement('records.manage')
  return (
    <ButtonLink
      to="/record-management/records/unlink/$id"
      params={{
        id: recordId,
      }}
      variant="contained"
      disabled={!recordId || !isEditable}
      color="primary"
    >
      Unlink Record
    </ButtonLink>
  )
}
