import { useCallback, useLayoutEffect, useRef, useState } from 'react'

import { useEvent } from '@lib/web'

export type FileUploaderOpts = {
  types: 'csv' | 'xlsx'
  onFile: (file: File, uploadFile: typeof _uploadFile) => Promise<void>
}

export function useFileUploader({ types, onFile }: FileUploaderOpts) {
  const [working, setWorking] = useState(false)

  const inputRef = useRef<HTMLInputElement>(undefined)

  const handleFile = useEvent(onFile)

  useLayoutEffect(() => {
    let input: HTMLInputElement | null = null

    function cycle() {
      input = document.createElement('input')
      input.type = 'file'
      input.style.display = 'none'
      document.body.appendChild(input)

      inputRef.current = input

      input.accept = `.${types}`

      input.onchange = async (e: any) => {
        const file = e.target?.files?.[0]

        if (file) {
          setWorking(true)

          try {
            await handleFile(file, _uploadFile)
          } finally {
            setWorking(false)

            // Recreate element
            input?.remove()
            cycle()
          }
        }
      }

      return input
    }

    cycle()

    return () => {
      input?.remove()
    }
  }, [handleFile, types])

  return {
    start: useCallback(() => {
      inputRef.current?.click()
    }, []),
    working,
  }
}

const _uploadFile = async (file: File, uploadUri: string) => {
  const uploadResult = await fetch(uploadUri, {
    method: 'PUT',
    body: file,
  })

  if (uploadResult.status < 200 || uploadResult.status >= 300) {
    throw uploadResult.statusText
  }
}
