import { ReactNode } from 'react'

import { Chip, Stack, Typography } from '@mui/material'

export function InfoText({
  icon,
  children,
}: {
  icon: ReactNode
  children: ReactNode
}) {
  return (
    <Stack spacing={1} direction="row">
      {icon}{' '}
      <Typography sx={{ strong: { opacity: 0.8 } }}>{children}</Typography>
    </Stack>
  )
}

export function LabelAndValueChips({
  label,
  chips,
}: {
  label: string
  chips?: (string | number)[] | string | number
}) {
  const list = (Array.isArray(chips) ? chips : [chips].filter(Boolean)) ?? []

  if (!list.length) {
    return null
  }

  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <Typography sx={{ opacity: 0.6 }}>{label}:</Typography>

      {list.map((chipContent, idx) => {
        return <Chip key={idx} size="small" label={chipContent} />
      })}
    </Stack>
  )
}
