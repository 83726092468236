import { FC } from 'react'

import { Skeleton } from '@mui/material'

import { trpc } from '@tk/frontend/api'
import { FilterTag } from '@tk/frontend/primitives'

import { FilterTagProps } from './types'

export const MarketGroupFilterTag: FC<FilterTagProps> = ({
  value,
  onDelete,
}) => {
  const { data, isFetched } = trpc.commercialPackages.getPackage.useQuery(
    { id: parseInt(value) },
    {
      select(data) {
        return {
          label: data.description,
          type: data.marketCategory!.type,
        }
      },
    }
  )

  return (
    <FilterTag label="Package" onDelete={onDelete}>
      {isFetched ? (
        data?.label
      ) : (
        <Skeleton width="5rem" height="2rem" animation="wave" />
      )}
    </FilterTag>
  )
}
