import { createFileRoute } from '@tanstack/react-router'

import { RouterWhen } from '@tk/frontend/primitives'

export const Route = createFileRoute('/reference-data/rule')({
  component: RouteComponent,
})

function RouteComponent() {
  return <RouterWhen can="reference-data-rules.read" />
}
