import {
  AccessTimeRounded,
  AccountCircleRounded,
  CopyAllRounded,
  DownloadRounded,
} from '@mui/icons-material'
import { Chip, Paper, Stack, Typography } from '@mui/material'
import { useParams } from '@tanstack/react-router'
import { DateTime } from 'luxon'

import { trpc } from '@tk/frontend/api'
import { DownloadButton } from '@tk/frontend/app/DataExtract/DownloadButton'
import { InfoByReportStatus } from '@tk/frontend/app/DataExtract/types'
import { ButtonLink } from '@tk/frontend/primitives'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { StatusTooltip } from './StatusTooltip'
import { InfoText, LabelAndValueChips } from './util-components'

export function DetailPage() {
  const { id } = useParams({ from: '/extracta/reports/$id' })

  const [data] = trpc.extracta.getReportById.useSuspenseQuery({
    id: id,
  })

  if (data.filters && data.filters.length > 1) {
    throw new Error(
      'ero or One filter is expected in a Report, but got ' +
        data.filters?.length
    )
  }

  const isFinished = data.status === 'FINISHED'

  const filters = data.filters?.[0].recordRequest

  const statusInfo = InfoByReportStatus[data.status]

  return (
    <PageContentLayout
      title={data.name}
      controls={
        <>
          <ButtonLink
            variant="contained"
            startIcon={<CopyAllRounded />}
            color="secondary"
            to="/extracta/reports/$id/clone"
            params={{
              id: id,
            }}
          >
            Clone Report
          </ButtonLink>

          <DownloadButton
            id={id}
            variant="contained"
            startIcon={<DownloadRounded />}
            disabled={!isFinished}
          />
        </>
      }
      childrenContainer={<></>}
    >
      <Paper
        sx={{
          bgcolor: 'white',
          padding: '1rem 2rem',
          width: '100%',
          maxWidth: '80rem',
        }}
      >
        <Stack spacing={6} alignItems="stretch">
          <Stack>
            <Typography variant="subtitle1">{data.description}</Typography>

            {/* Map to colours and nicer labels */}
            <Stack direction="row" spacing={1}>
              <Chip color="secondary" label={data.type} size="small" />

              <StatusTooltip report={data} when="always">
                <Chip
                  color={statusInfo.muiColor}
                  label={statusInfo.label}
                  size="small"
                />
              </StatusTooltip>
            </Stack>

            <Stack spacing={1}>
              <InfoText
                icon={<AccountCircleRounded width="8px" height="8px" />}
              >
                Created by <strong>{data.createdBy}</strong>
              </InfoText>

              <InfoText icon={<AccessTimeRounded width="8px" height="8px" />}>
                Created{' '}
                <strong>
                  {DateTime.fromJSDate(data.createdDate).toRelative()}
                </strong>
              </InfoText>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Typography variant="h4" fontWeight="bold">
              Configuration
            </Typography>

            <LabelAndValueChips label="Timezone" chips={data.timezone} />
            <LabelAndValueChips label="Snap Time" chips={data.snapTime} />

            <LabelAndValueChips
              label="Start Date"
              chips={data.startDate?.toLocaleString()}
            />

            <LabelAndValueChips
              label="End Date"
              chips={data.endDate?.toLocaleString()}
            />

            {/* TODO: map to day names */}
            <LabelAndValueChips
              label="Excluded Days"
              chips={data.excludedDays}
            />
          </Stack>

          <Stack spacing={2}>
            <Typography variant="h4" fontWeight="bold">
              Filters
            </Typography>

            {!filters && (
              <Typography sx={{ opacity: 0.7 }}>
                This may be an older Data Extract which did not store filters
                for later display, although filters were applied at the time of
                creation.
              </Typography>
            )}

            {filters && (
              <>
                <LabelAndValueChips label="Filter" chips={filters.filter} />

                {/* TODO: display source name</LabelAndValueChips> */}
                <LabelAndValueChips label="Source" chips={filters.sourceId} />

                {/* TODO: display package information */}
                <LabelAndValueChips
                  label="Commercial Package"
                  chips={filters.marketGroupId}
                />
              </>
            )}
          </Stack>

          {/* TODO: display records table */}
          <Stack spacing={2}>
            <Typography variant="h4" fontWeight="bold">
              Records
            </Typography>

            <Typography sx={{ opacity: 0.7 }}>
              We need to support this
            </Typography>
          </Stack>

          {/* TODO: display field groups */}
          <Stack spacing={2}>
            <Typography variant="h4" fontWeight="bold">
              Field Groups
            </Typography>

            <Typography sx={{ opacity: 0.7 }}>
              We need to support this
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </PageContentLayout>
  )
}
