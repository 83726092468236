import * as Icons from '@mui/icons-material'

import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'

interface RrnStructureEditButtonProps {
  id: number
}

export function RrnStructureEditButton({
  id,
}: Readonly<RrnStructureEditButtonProps>) {
  const canEdit = useEntitlement('reference-data.manage')

  return (
    <ButtonLink
      variant="text"
      startIcon={canEdit ? <Icons.Edit /> : <Icons.Visibility />}
      to="/multibrand/root-record-name-structure/edit/$id"
      params={{ id }}
      color="primary"
    >
      {canEdit ? 'Edit' : 'View'}
    </ButtonLink>
  )
}
