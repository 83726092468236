import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-premium'
import { createFileRoute } from '@tanstack/react-router'
import { isEmpty, omit } from 'lodash'
import { z } from 'zod'

import { FilterKey } from '@tk/frontend/app/Records/filters-config'
import { RecordsTablePage } from '@tk/frontend/app/Records/RecordsTablePage'
import { MultiSelectFilterId } from '@tk/frontend/primitives/datagrid/columns'

const DEFAULT_STATUS_VALUE = ['COLLECT', 'DO_NOT_COLLECT']
const DEFAULT_SCOPE_VALUE = ['EXTERNAL']

const searchParams = z
  .object({
    'filter': z.string().optional(),
    'grid-filters': z.string().optional(),
    'initial-asset-type': z.number().optional(),
    'initial-chain': z.number().optional(),
    'initial-currency': z.number().optional(),
    'initial-fid-group': z.number().optional(),
    'initial-marketgroup': z.number().optional(),
    'initial-touched-by-name': z.string().optional(),
    'initial-source': z.number().optional(),
    'initial-status-items': z.string().optional(),
    'initial-subscription': z.number().optional(),
    'initial-scope-items': z.string().optional(),
    'page': z.number().optional(),
    'size': z.number().optional(),
  })
  .optional()

export const Route = createFileRoute('/record-management/records/')({
  component: RecordsTablePage,
  validateSearch: searchParams.parse,
  search: {
    middlewares: [
      ({ search = {}, next }) => {
        const baseSearch = omit(search, ['page', 'size'])

        // If no params are present, set the default status and scope filters
        if (!baseSearch || isEmpty(baseSearch)) {
          search = {
            'initial-status-items': DEFAULT_STATUS_VALUE.join(','),
            'initial-scope-items': DEFAULT_SCOPE_VALUE.join(','),
          }
        }

        let gridFilters: GridFilterModel = { items: [] }

        if (search?.['grid-filters']) {
          try {
            gridFilters = JSON.parse(window.atob(search['grid-filters']))
          } catch {
            //Do nothing
          }
        }

        if (search['initial-status-items']) {
          const defaultStatusFilter: GridFilterItem = {
            id: -2,
            field: 'status' as FilterKey,
            operator: MultiSelectFilterId,
            value: DEFAULT_STATUS_VALUE,
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultStatusFilter.field
            ),
            defaultStatusFilter,
          ]
          search['initial-status-items'] = undefined
        }

        if (search['initial-scope-items']) {
          const defaultScopeFilter: GridFilterItem = {
            id: -3,
            field: 'scope' as FilterKey,
            operator: MultiSelectFilterId,
            value: DEFAULT_SCOPE_VALUE,
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultScopeFilter.field
            ),
            defaultScopeFilter,
          ]
          search['initial-scope-items'] = undefined
        }

        if (
          search['initial-marketgroup'] &&
          search['initial-marketgroup'] > 0
        ) {
          const defaultMarketGroupFilter: GridFilterItem = {
            id: -1,
            field: 'marketGroups' as FilterKey,
            operator: MultiSelectFilterId,
            value: [search['initial-marketgroup']],
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultMarketGroupFilter.field
            ),
            defaultMarketGroupFilter,
          ]
          search['initial-marketgroup'] = undefined
        }

        if (search['initial-asset-type'] && search['initial-asset-type'] > 0) {
          const defaultAssetTypeFilter: GridFilterItem = {
            id: -1,
            field: 'assetType.name' as FilterKey,
            operator: MultiSelectFilterId,
            value: [search['initial-asset-type']],
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultAssetTypeFilter.field
            ),
            defaultAssetTypeFilter,
          ]
          search['initial-asset-type'] = undefined
        }

        if (search['initial-chain'] && search['initial-chain'] > 0) {
          const defaultChainFilter: GridFilterItem = {
            id: -1,
            field: 'parentChains' as FilterKey,
            operator: MultiSelectFilterId,
            value: [search['initial-chain']],
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultChainFilter.field
            ),
            defaultChainFilter,
          ]
          search['initial-chain'] = undefined
        }

        if (search['initial-fid-group'] && search['initial-fid-group'] > 0) {
          const defaultFidGroupFilter: GridFilterItem = {
            id: -1,
            field: 'fidGroup.name' as FilterKey,
            operator: MultiSelectFilterId,
            value: [search['initial-fid-group']],
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultFidGroupFilter.field
            ),
            defaultFidGroupFilter,
          ]
          search['initial-fid-group'] = undefined
        }

        if (search['initial-touched-by-name']) {
          const defaultTouchedByNamesFilter: GridFilterItem = {
            id: -1,
            field: 'touchedByNames' as FilterKey,
            operator: MultiSelectFilterId,
            value: [search['initial-touched-by-name']],
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultTouchedByNamesFilter.field
            ),
            defaultTouchedByNamesFilter,
          ]
          search['initial-touched-by-name'] = undefined
        }

        if (
          search['initial-subscription'] &&
          search['initial-subscription'] > 0
        ) {
          const defaultSubscriptionFilter: GridFilterItem = {
            id: -1,
            field: 'subscription' as FilterKey,
            operator: MultiSelectFilterId,
            value: [search['initial-subscription']],
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultSubscriptionFilter.field
            ),
            defaultSubscriptionFilter,
          ]
          search['initial-subscription'] = undefined
        }

        if (search['initial-source'] && search['initial-source'] > 0) {
          const defaultInitialSourceFilter: GridFilterItem = {
            id: -1,
            field: 'source.name' as FilterKey,
            operator: MultiSelectFilterId,
            value: [search['initial-source']],
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultInitialSourceFilter.field
            ),
            defaultInitialSourceFilter,
          ]
          search['initial-source'] = undefined
        }

        if (search['initial-currency'] && search['initial-currency'] > 0) {
          const defaultInitialCurrencyFilter: GridFilterItem = {
            id: -1,
            field: 'currency1.name' as FilterKey,
            operator: MultiSelectFilterId,
            value: [search['initial-currency']],
          }

          gridFilters.items = [
            ...gridFilters.items.filter(
              (it) => it.field !== defaultInitialCurrencyFilter.field
            ),
            defaultInitialCurrencyFilter,
          ]
          search['initial-currency'] = undefined
        }

        if (gridFilters.items.length > 0) {
          search['grid-filters'] = window.btoa(JSON.stringify(gridFilters))
        }

        return next(search)
      },
    ],
  },
})
