import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import {
  DataGridPremium,
  GridRowClassNameParams,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { useModal } from '@parameta/nice-modal-react'
import { keepPreviousData } from '@tanstack/react-query'

import { useDebounce } from '@lib/web'
import { trpc } from '@tk/frontend/api'
import { FidGroupDto, TreeItem } from '@tk/frontend/app/Fids/types'
import { ButtonLink, When } from '@tk/frontend/primitives'
import {
  ActionButtonGroup,
  defaultProps,
  GridEnrichedColDef,
  useColumnVisibilityModel,
  useFilterQueryParams,
  usePaginationQueryParams,
} from '@tk/frontend/primitives/datagrid'
import {
  NameDateCell,
  SecondaryHeader,
  WrappedTextCell,
} from '@tk/frontend/primitives/datagrid/cells'
import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'
import {
  TREE_CHILD_CLASS,
  TREE_PARENT_CLASS,
  TreeDataGridStyles,
  useTreeGroupingProps,
} from '@tk/frontend/primitives/datagrid/tree'
import { PageContentLayout } from '@tk/frontend/primitives/layout'

import { FidGroupCloneModal } from './FidGroupCloneModal'

export function FidGroupsPage() {
  const apiRef = useGridApiRef()
  const { value: filter, setValue: setFilter } = useFilterQueryParams()

  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [filter],
    initialPageSize: 500,
  })
  const debouncedFilter = useDebounce(filter, 500)
  const groupsQuery = trpc.fids.groups.list.useQuery(
    {
      filter: debouncedFilter ?? '',
      page: pagination.page,
      size: pagination.pageSize,
    },
    { placeholderData: keepPreviousData }
  )

  const rows = transformFidGroupsToTreeItems(groupsQuery.data?.content ?? [])

  const groupingProps = useTreeGroupingProps(apiRef, {
    getTreeDataPath,
    getRowId,
    getRowClassName,
  })
  const fidGroupCloneModal = useModal(FidGroupCloneModal)

  const columns = useMemo<GridEnrichedColDef<TreeItem>[]>(() => {
    return createColumns<TreeItem>([
      {
        type: 'string',
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
      },
      {
        type: 'string',
        field: 'name',
        headerName: 'Name',
        width: 150,
        editable: false,
      },
      {
        type: 'string',
        field: 'scope',
        headerName: 'Scope',
        width: 150,
        editable: false,
        renderCell(params) {
          if (params.row._type === 'fid-map') {
            return <WrappedTextCell text={params.row.scope} />
          }
          return null
        },
      },
      {
        type: 'string',
        field: 'description',
        headerName: 'Description',
        flex: 1,
        editable: false,
        width: 100,
      },
      {
        type: 'string',
        field: 'fidMaps',
        headerName: 'Published FID',
        renderHeader: (params) => (
          <SecondaryHeader text={params.colDef.headerName!} />
        ),
        editable: false,
        width: 150,
        valueGetter(value, row) {
          if (row._type === 'fid-map') {
            return `${row.fid.externalId} (${row.fid.name})`
          }
          return null
        },
        renderCell(params) {
          if (params.row._type === 'fid-map') {
            return <WrappedTextCell text={params.value} />
          }
          return null
        },
      },
      {
        type: 'string',
        field: 'createdBy',
        headerName: 'Created',
        width: 150,
        editable: false,
        renderCell(params) {
          if (params.row._type === 'fid-group') {
            const { createdBy, createdDate } = params.row
            return <NameDateCell name={createdBy} date={createdDate} />
          }
          return null
        },
      },
      {
        type: 'string',
        field: 'modifiedBy',
        headerName: 'Modified',
        width: 150,
        editable: false,
        renderCell(params) {
          if (params.row._type === 'fid-group') {
            const { modifiedBy, modifiedDate } = params.row
            return <NameDateCell name={modifiedBy} date={modifiedDate} />
          }
          return null
        },
      },
      {
        type: 'actions',
        field: 'crud-actions',
        width: 300,
        align: 'right',
        getActions(params) {
          if (params.row._type === 'fid-group') {
            const row = params.row
            return [
              <ActionButtonGroup key="action-buttons">
                <When can="fids.manage" key={params.row.id}>
                  <Button
                    startIcon={<Icons.ContentCopy />}
                    color="primary"
                    onClick={() => {
                      fidGroupCloneModal.show({
                        data: params.row,
                      })
                    }}
                    data-testid="clone-fids-button"
                  >
                    Clone
                  </Button>
                  <ButtonLink
                    startIcon={<Icons.Edit />}
                    color="primary"
                    variant="text"
                    to="/fids/groups/edit/$id"
                    params={{ id: row.id }}
                    data-testid="edit-fids-button"
                  >
                    Edit
                  </ButtonLink>
                </When>

                <ButtonLink
                  color="primary"
                  startIcon={<Icons.TableRowsRounded />}
                  variant="text"
                  to="/record-management/records"
                  search={{ 'initial-fid-group': params.row.id }}
                >
                  Records
                </ButtonLink>
              </ActionButtonGroup>,
            ]
          }
          return []
        },
      },
    ]).map(setColumnDefaults)
  }, [fidGroupCloneModal])

  const columnVisibility = useColumnVisibilityModel<FidGroupDto>({ id: false })

  return (
    <PageContentLayout
      title="Field Groups"
      controls={
        <When can="fids.manage">
          <ButtonLink
            to="/fids/groups/create"
            startIcon={<Icons.Add />}
            variant="contained"
          >
            Create
          </ButtonLink>
        </When>
      }
      maxContentWidth={false}
    >
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <TextField
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label="Filter"
          variant="filled"
          inputProps={{
            role: 'search',
          }}
          fullWidth
        />

        <TreeDataGridStyles>
          <DataGridPremium
            {...defaultProps}
            //
            initialState={{ density: 'compact' }}
            //
            treeData
            {...groupingProps}
            //
            loading={groupsQuery.isFetching}
            rows={rows}
            columns={columns}
            //
            columnVisibilityModel={columnVisibility.model}
            onColumnVisibilityModelChange={columnVisibility.setModel}
            //
            paginationMode="server"
            pagination
            pageSizeOptions={[100, 500, 5000]}
            paginationModel={pagination}
            onPaginationModelChange={onPaginationChange}
            rowCount={groupsQuery.data?.totalElements ?? 0}
          />
        </TreeDataGridStyles>
      </Box>
    </PageContentLayout>
  )
}

function transformFidGroupsToTreeItems(fidGroups: FidGroupDto[]): TreeItem[] {
  const result: TreeItem[] = []

  for (const fidGroup of fidGroups) {
    result.push({
      _type: 'fid-group',
      ...fidGroup,
      id: fidGroup.id,
    })

    result.push(
      ...fidGroup.fidMaps.map<TreeItem>((fidMap) => {
        return {
          _type: 'fid-map',
          ...fidMap,
          parent: fidGroup,
        }
      })
    )
  }
  return result
}

function getTreeDataPath(row: TreeItem): string[] {
  if (row._type === 'fid-map') {
    return [String(row.parent.id), row.name]
  } else {
    return [String(row.id)]
  }
}

function getRowId(row: TreeItem) {
  if (row._type === 'fid-map') {
    return `${row._type}/${row.parent.id}/${row.name}`
  } else {
    return `${row._type}/${row.id}`
  }
}

const getRowClassName = (params: GridRowClassNameParams<TreeItem>) =>
  params.row._type === 'fid-group' ? TREE_PARENT_CLASS : TREE_CHILD_CLASS
