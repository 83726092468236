import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import { useModal } from '@parameta/nice-modal-react'
import { ToOptions } from '@tanstack/react-router'

import { RecordDto } from '@lib/pgs'
import { trpc } from '@tk/frontend/api'
import { RecordAssetTypeSelectHelperText } from '@tk/frontend/app/Records/common/crud'
import { RecordAssetTypeSelect } from '@tk/frontend/app/Records/common/crud/RecordAssetTypeSelect'
import { LinkRecordsModal } from '@tk/frontend/app/Records/LinkRecords/LinkRecordsModal'
import { UnlinkRecordButton } from '@tk/frontend/app/Records/LinkRecords/UnlinkRecordButton'
import {
  ButtonLink,
  getFormComponents,
  useEntitlement,
} from '@tk/frontend/primitives'

export type LinkedDataSectionProps = {
  record: RecordDto
}

const Form = getFormComponents<never>()

export function LinkedDataSection({ record }: LinkedDataSectionProps) {
  const linkedRecords = useMemo(() => record.linkedRecords ?? [], [record])
  const editUris = useMemo(
    () =>
      linkedRecords.map<ToOptions>((r) => ({
        to: '/record-management/records/edit/$id',
        params: { id: r.id.toString() },
      })),
    [linkedRecords]
  )
  const editable = useEntitlement('records.manage')

  const listUris = useMemo(
    () =>
      linkedRecords.map<ToOptions>((linkedRecords) => ({
        to: '/record-management/records',
        search: { filter: record.name },
      })),
    [linkedRecords, record.name]
  )

  const modal = useModal(LinkRecordsModal)
  const utils = trpc.useContext()

  const handleOpenModal = () => {
    if (!editable) {
      return
    }
    modal.show({
      record,
      onFinished: () => {
        utils.records.invalidate()
      },
    })
  }

  return (
    <Form.Section title="Linked Data">
      <RecordAssetTypeSelect />
      <RecordAssetTypeSelectHelperText />
      <Stack direction="row">
        <Button
          variant="contained"
          sx={{ marginLeft: '1rem', width: '8rem' }}
          onClick={handleOpenModal}
          disabled={!editable}
        >
          Link to Record
        </Button>
        {linkedRecords.length > 0 && (
          <UnlinkRecordButton recordId={record.id} />
        )}
      </Stack>

      <Box display="flex" flexDirection="column" alignItems="center">
        {linkedRecords.length === 0 && <strong>No linked records!</strong>}

        {linkedRecords.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableBody>
                {linkedRecords.map((r, i) => (
                  <TableRow key={r.id}>
                    <TableCell>
                      <strong>{r.name}</strong>
                      <div>{r.description}</div>
                    </TableCell>

                    <TableCell>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                      >
                        <ButtonLink
                          {...editUris[i]}
                          target="_blank"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          endIcon={<Icons.OpenInNew />}
                        >
                          Edit
                        </ButtonLink>

                        <ButtonLink
                          {...listUris[i]}
                          target="_blank"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          endIcon={<Icons.OpenInNew />}
                        >
                          List
                        </ButtonLink>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Form.Section>
  )
}
