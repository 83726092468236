import { useMemo } from 'react'

import * as Icons from '@mui/icons-material'
import { Box, ButtonGroup } from '@mui/material'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { keepPreviousData } from '@tanstack/react-query'
import { DateTime } from 'luxon'

import { ExtractaReportDto } from '@lib/extracta'
import { Inputs, trpc } from '@tk/frontend/api'
import { InfoByReportStatus } from '@tk/frontend/app/DataExtract/types'
import { ButtonLink, When } from '@tk/frontend/primitives'
import { usePaginationQueryParams } from '@tk/frontend/primitives/datagrid'
import {
  createColumns,
  setColumnDefaults,
} from '@tk/frontend/primitives/datagrid/columns'

import { DownloadButton } from './DownloadButton'
import { StatusTooltip } from './StatusTooltip'

export const ExtractaReportsTable = (props: Inputs['extracta']['list']) => {
  const [pagination, onPaginationChange] = usePaginationQueryParams({
    resetPageFor: [
      props?.startDate?.valueOf(),
      props?.endDate?.valueOf(),
      props?.name,
      props?.createdBy,
      props?.types && props.types[0],
    ],
    initialPageSize: 100,
  })

  const reports = trpc.extracta.list.useQuery(
    {
      ...props,
      sort: 'createdDate',
      direction: 'desc',
      page: pagination.page,
      size: pagination.pageSize,
    },
    {
      refetchInterval: 5000,
      placeholderData: keepPreviousData,
    }
  )

  const columns: GridColDef[] = useMemo(
    () =>
      createColumns<ExtractaReportDto>([
        {
          field: 'id',
          headerName: 'ID',
          type: 'number',
        },
        {
          field: 'statusMessage',
          headerName: 'Status Message',
          type: 'string',
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
          type: 'string',
        },
        {
          field: 'description',
          headerName: 'Description',
          width: 225,
          type: 'string',
        },
        {
          field: 'createdBy',
          headerName: 'Created By',
          width: 200,
          type: 'string',
        },
        {
          field: 'createdDate',
          headerName: 'Created Date',
          width: 150,
          type: 'string',
          renderCell(params) {
            return <Box>{DateTime.fromJSDate(params.value).toRelative()}</Box>
          },
        },
        {
          field: 'type',
          headerName: 'Type',
          width: 150,
          type: 'string',
        },
        {
          field: 'timezone',
          headerName: 'Timezone',
          width: 150,
          type: 'string',
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 200,
          type: 'string',
          renderCell(params) {
            const status = params.row.status

            const { label, colour, Icon } = InfoByReportStatus[status]
            const isFinished = params.row.status === 'FINISHED'

            return (
              <StatusTooltip report={params.row}>
                <Box
                  data-testid={`status-for-${params.row.name}`}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: colour,
                  }}
                >
                  <Icon />

                  {label}

                  {isFinished && <DownloadButton id={params.row.id} />}
                </Box>
              </StatusTooltip>
            )
          },
        },
        {
          type: 'actions',
          field: 'actions',
          headerName: '',
          display: 'flex',
          flex: 1,
          minWidth: 200,
          align: 'left',
          renderCell(params) {
            return (
              <When flag="unimplemented">
                <ButtonGroup>
                  <ButtonLink
                    variant="text"
                    startIcon={<Icons.DescriptionRounded />}
                    to="/extracta/reports/$id"
                    params={{ id: params.row.id }}
                    color="secondary"
                  >
                    View Details
                  </ButtonLink>

                  {params.row.filters?.[0] && (
                    <CloneButton reportId={params.row.id} />
                  )}
                </ButtonGroup>
              </When>
            )
          },
        },
      ]).map(setColumnDefaults),
    []
  )

  return (
    <DataGridPremium
      initialState={{
        columns: {
          columnVisibilityModel: {
            statusMessage: false,
            id: false,
          },
        },
      }}
      rows={reports.data?.content ?? []}
      loading={reports.isPending}
      columns={columns}
      pageSizeOptions={[100]}
      pagination
      paginationMode="server"
      paginationModel={pagination}
      onPaginationModelChange={onPaginationChange}
      rowCount={reports.data?.totalElements ?? 0}
    />
  )
}

const CloneButton = ({ reportId }: { reportId: number }) => {
  return (
    <ButtonLink
      startIcon={<Icons.CopyAllRounded />}
      variant="text"
      to="/extracta/reports/$id/clone"
      params={{
        id: reportId,
      }}
      color="secondary"
    >
      Clone
    </ButtonLink>
  )
}
