import * as Icons from '@mui/icons-material'

import { ButtonLink, useEntitlement } from '@tk/frontend/primitives'

export type EditRecordButtonProps = {
  selectedId: null | number | string
}

export function EditRecordButton({ selectedId }: EditRecordButtonProps) {
  const isEditMode = useEntitlement('records.manage')
  let id: number

  if (typeof selectedId === 'number') {
    id = selectedId
  } else if (typeof selectedId === 'string') {
    id = parseInt(selectedId)
  } else {
    return
  }

  return (
    <ButtonLink
      to="/record-management/records/edit/$id"
      params={{ id }}
      variant="contained"
      disabled={!selectedId}
      startIcon={isEditMode ? <Icons.Edit /> : <Icons.Preview />}
      color="primary"
    >
      {isEditMode ? 'Edit' : 'View'}
    </ButtonLink>
  )
}
