import { useMemo } from 'react'

import type { Inputs } from '@tk/frontend/api'

import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium'

import { GetRecordsPageData, RecordRequest } from '@lib/pgs'

import { FILTERS_CONFIG } from './filters-config'

export type RecordsListParams = Inputs['records']['list']

export function createRecordQueryParams(params: RecordRequest): {
  'filter': string
  'filterDescription': string
  'grid-filters': string | undefined
}
export function createRecordQueryParams(params: GetRecordsPageData): {
  'filter': string
  'filterDescription': string
  'grid-filters': string | undefined
}
export function createRecordQueryParams(
  params: RecordRequest | GetRecordsPageData
): {
  'filter': string
  'filterDescription': string
  'grid-filters': string | undefined
} {
  const queryKeyToFilterKey = Object.entries(FILTERS_CONFIG).reduce(
    (acc, [filterKey, config]) => {
      const dummyQuery: Record<string, unknown> = {}
      config.setApiQueryProperty(dummyQuery, ['dummy'])
      const queryKey = Object.keys(dummyQuery)[0]
      if (queryKey) {
        acc[queryKey] = filterKey
      }
      return acc
    },
    {} as Record<string, string>
  )

  const filterModel: GridFilterModel = {
    items: Object.entries(params)
      .filter(([key]) => queryKeyToFilterKey[key])
      .map(([key, value]) => ({
        field: queryKeyToFilterKey[key],
        value: value,
        operator: 'is',
      })),
    logicOperator: GridLogicOperator.And,
  }

  const encodedFilterModel =
    !filterModel || filterModel.items.length === 0
      ? undefined
      : window.btoa(JSON.stringify(filterModel))

  return {
    'filter': params.filter || '',
    'filterDescription': params.filterDescription || '',
    'grid-filters': encodedFilterModel,
  }
}

export function useCreateRecordQueryParams(params: RecordRequest): {
  'filter': string
  'filterDescription': string
  'grid-filters': string | undefined
}
export function useCreateRecordQueryParams(params: GetRecordsPageData): {
  'filter': string
  'filterDescription': string
  'grid-filters': string | undefined
}
export function useCreateRecordQueryParams(
  params: RecordRequest | GetRecordsPageData
): {
  'filter': string
  'filterDescription': string
  'grid-filters': string | undefined
} {
  return useMemo(() => {
    return createRecordQueryParams(params)
  }, [params])
}
