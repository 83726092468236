import { keepPreviousData } from '@tanstack/react-query'

import { Outputs, trpc } from '@tk/frontend/api'

import { useRecordsQueryParams } from './useRecordsQueryParams'

type Response = Outputs['records']['list']

const DEFAULT: Response = Object.freeze({
  content: [],
  direction: 'asc',
  page: 0,
  totalElements: 0,
  size: 0,
  totalPages: 0,
  sort: 'name',
})

export function useRecordsList() {
  const params = useRecordsQueryParams()

  const listQuery = trpc.records.list.useQuery(params.query, {
    placeholderData: keepPreviousData,
  })

  return [listQuery.data ?? DEFAULT, listQuery] as const
}
