import { Link, ToOptions } from '@tanstack/react-router'

import { MenuItem, MenuItemProps } from './menu'

export type MenuItemLinkProps = MenuItemProps & ToOptions

/**
 * A react component that renders a menu item with react-router-dom link component for easy navigation of menu items.
 * @param props The MenuItem props added with the `to` prop.
 * @returns The modified MenuItem component.
 */
export function MenuItemLink(props: MenuItemLinkProps) {
  return <MenuItem component={Link} {...props} />
}
