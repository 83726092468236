import { useId, useMemo, useState } from 'react'

import { Autocomplete, Chip, Skeleton, TextField } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid-premium'
import { sortBy } from 'lodash'

import { useDebounce } from '@lib/web'

export interface ServersideGridFilterOption {
  label: string
  value: number | string
  group?: string
}

export interface ServersideGridFilterProps extends GridFilterInputValueProps {
  group?: boolean
  useFilteredList(filter: string | undefined): {
    data?: ServersideGridFilterOption[]
  }
  useSelectedOption(
    selectedValues: number[] | string[] | undefined
  ): ServersideGridFilterOption[]
}

// Adapted from the standard GridFitlerMultiSelect
export function ServersideGridFilter(
  props: Readonly<ServersideGridFilterProps>
) {
  const id = useId()

  const { apiRef, applyValue, item, focusElementRef } = props
  const [inputValue, setInputValue] = useState('')

  const debouncedInputValue = useDebounce(inputValue, 500)

  const filteredRecordOptionsQuery = props.useFilteredList(debouncedInputValue)

  const selectedOptionsData = props.useSelectedOption(item.value)

  const options = useMemo(() => {
    let result = [...(filteredRecordOptionsQuery.data ?? [])]

    const knownIds = new Set(result.map((option) => option.value))
    for (const selectedOption of selectedOptionsData) {
      if (!knownIds.has(selectedOption.value)) {
        result.push(selectedOption)
        knownIds.add(selectedOption.value)
      }
    }

    for (const value of item.value ?? []) {
      if (!knownIds.has(value)) {
        result.push({ label: '', value })
      }
    }

    if (props.group) {
      result = sortBy(result, (option) => [option.group ?? '', option.label])
    }

    return result
  }, [
    filteredRecordOptionsQuery.data,
    item.value,
    props.group,
    selectedOptionsData,
  ])

  const selectedOptions = useMemo(
    () => options.filter((option) => (item.value ?? []).includes(option.value)),
    [options, item.value]
  )

  return (
    <Autocomplete
      id={id}
      multiple
      options={options ?? []}
      groupBy={
        props.group ? (option) => option.group ?? 'Ungrouped' : undefined
      }
      inputValue={inputValue}
      onInputChange={(event, inputValue, reason) => {
        if (reason === 'reset') {
          // We ignore resets because useQuery loading causes the value reference to change while typing
          // We re-implement the reset based on on-change
          return
        }

        setInputValue(inputValue)
      }}
      value={selectedOptions}
      onChange={(event, selectedOptions) => {
        applyValue({
          ...item,
          value: selectedOptions.map(
            (option: ServersideGridFilterOption) => option.value
          ),
        })

        // Clear the free-text filter on select
        setInputValue('')
      }}
      getOptionLabel={(option) => option.label}
      renderTags={(selectedOptions, getTagProps) => {
        return selectedOptions.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            // If the option label is empty, we're still waiting on the data
            label={option.label || <Skeleton width={60} />}
            variant="outlined"
            size="small"
          />
        ))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          label={apiRef.current.getLocaleText('filterPanelInputLabel')}
          InputLabelProps={{ shrink: true }}
          placeholder={apiRef.current.getLocaleText(
            'filterPanelInputPlaceholder'
          )}
          sx={{ marginTop: '0.2rem' }}
          inputRef={focusElementRef}
        />
      )}
      slotProps={{
        popper: {
          style: { width: 'auto', maxWidth: '30rem' },
          placement: 'bottom-start',
        },
      }}
    />
  )
}
