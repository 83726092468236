import { createFileRoute, Navigate } from '@tanstack/react-router'

import { trpc } from '@tk/frontend/api'
import { useCreateRecordQueryParams } from '@tk/frontend/app/Records/createRecordsQueryParams'
import { parseParamAsNumber } from '@tk/frontend/primitives/routing'

export const Route = createFileRoute('/extracta/reports/$id_/clone')({
  component: RouteComponent,
  params: parseParamAsNumber('id'),
})

function RouteComponent() {
  const { id } = Route.useParams()

  const [report] = trpc.extracta.getReportById.useSuspenseQuery({
    id,
  })
  const recordRequest = report.filters?.[0]?.recordRequest
  if (!recordRequest) {
    throw new Error('Report is not possible to clone')
  }

  const queryParams = useCreateRecordQueryParams(recordRequest)

  return <Navigate to="/extracta/create" replace search={queryParams} />
}
